import router from '../router';
import WpHttpClient from '@/classes/Wp';
import HttpClient from '@/classes/HttpClient';
import Product from '@/classes/Product';
import { wrap } from '@/helpers';

export async function fetchContent (destinationSlug = null) {
  let destination = router.currentRoute.value.params.slug;
  
  if (destinationSlug) {
    destination = destinationSlug;
  }
  
  return await WpHttpClient.fetchContent(destination);
}

/**
 * Fetch the meta fields from a given WP page
 * @param {string} path
 * @param {object} query
 * @returns {object}
 */
export async function fetchWpMetaData ({ path, query }) {
	const client = new WpHttpClient({ path, query });
	const resp = await client.get();
	
	if (!(Array.isArray(resp.data) && resp.data.length && resp.data[0].meta)) {
		throw new Error('Missing/invalid data property in response');
	}

	return resp.data[0].meta;
}

/**
 * Fetches the security token needed to send a promo code redemption request to Laravel. This guards
 * against script-based brute force attempts to validate randomly-generated codes.
 * @returns {string}
 */
export async function fetchToken () {
	const client = new HttpClient({ path: '/laravel/rest/promo_token_C11A6954', verify: false });
	const resp = await client.get();

	if (!(resp.status === 200 && resp.data && resp.data.token)) {
		throw new Error('Unable to retrieve promo token value');
	}

	return resp.data.token;
}

/**
 * Fetches page content for a given WP endpoint
 * @param {object} query 
 * @param {object} state 
 * @param {string} dataKey
 * @param {string} loadingKey
 * @param {string} errorKey
 * @param {boolean} setReady
 */
export async function fetchWpPageData(query, state, { dataKey = 'data', loadingKey = 'loading', errorKey = 'error', setReady = true }) {
	state[loadingKey] = true;
    state[errorKey] = false;
	state.ready = false;

    try {
		const client = new WpHttpClient({ path: '/pages', query });
		const { data } = await client.get();

		if (!(data && Array.isArray(data))) {
			throw new Error('Missing/invalid data property in response');
		}

		state[dataKey] = data[0];

		if (setReady) {
			state.ready = true;
		}
    } catch (e) {
		if (e.message !== "canceled") {
			state[errorKey] = true;
		} else {
			state[dataKey] = {};
		}
    } finally {
      	state[loadingKey] = false;
		router.initCSSLazyLoader();
    }
}

/**
 * Attempt to parse the Stringified JSON object.
 * 
 * Returns null if an error is encountered or if the parameter is invalid. Logs error into console if one is encountered
 * 
 * @param {String} jsonString
 * @returns {Object|Null} Json | null
 */
export function parseJson (jsonString, bypass = false) {
	let res = bypass ? jsonString : null;

	if (typeof jsonString !== "string") {
		return res;
	}

	try {
		res = JSON.parse(jsonString);
	} catch (e) {
		if (!bypass) {
			console.error(e);
		}
	}

	return res;
}

/**
 * Makes a GET request to a dummy CMS endpoint in order to retrieve the cache key from the response headers
 * and trigger a cache bust if necessary.
 */
export async function fetchCacheKey() {
	const client = new WpHttpClient({ namespace: '/antares/v1', path: '/check-cache', global: true});
	await client.get();
}

/**
 * Sets the query params.
 * @param {number} p
 * @param {string} q
 * @param {object} state
 */
export function getQueryParams({ p, q }, state) {
	if (p) {
		try {
			state.page = parseInt(p);
		} catch (e) {
			console.log(e);
		}
	}
  
	if (q) {
		q.split(',').forEach(v => {
			if (state.categories.some(c => c.slug === v)) {
				// Avoid pushing invalid categories into the active filters
				state.active_filters.push(v);
			}
		});
	}
}

/**
 * Toggles a filter
 * @param {string} filter
 * @param {boolean} clear
 * @param {function} cb
 * @param {object} state
 */
export function toggleFilter({ filter, clear = false, cb = null }, state) {
	if (state.loading) {
		return;
	}

	if (clear && Object.prototype.hasOwnProperty.call(state, 'active_filters')) {
		state.active_filters = [];
	}

    if (state.active_filters.includes(filter)) {
      	state.active_filters.splice(state.active_filters.indexOf(filter), 1);
    } else {
      	state.active_filters.push(filter);
    }

	if (cb && typeof cb === 'function') {
		cb();
	}
}

/**
 * Clears all filters
 * @param {object} state 
 * @param {function} cb 
 */
export function clearFilters(conditions = [], cb = null, state) {
	if (state.active_filters.length > 0 && !conditions.some(v => v)) {
		state.active_filters = [];
		
		if (cb && typeof cb === 'function') {
			cb();
		}
	}
}

/**
 * Looks for any DOM elements with the class name 'link', then links them according to their corresponding
 * 'link__<some_url_here>' class
 * @returns {void}
 */
export function createLinksFromWpLinkClasses() {
	const els = document.getElementsByClassName('link');

	if (!(els && els.length)) {
		return;
	}

	[...els].forEach(el => {
		if (!el.classList.contains('link')) {
			return;
		}
		
		const linkClasses = [...el.classList].filter(v => v.includes('link__'));

		if (linkClasses.length < 1) {
			return;
		}

		const linkParts = linkClasses[0].split('__');

		if (linkParts.length <= 1) {
			return;
		}

		const isExternal = linkParts[1].includes('http://') && !linkParts[1].includes(process.env?.VUE_APP_DOMAIN);

		if (isExternal) {
			let a = document.createElement('a');

			a.setAttribute('href', linkParts[1]);
			a.setAttribute('target', '_blank');
			wrap(el, a);
		} else if (router.resolve(linkParts[1]).name !== 'PageNotFound') {
			el.style.cursor = 'pointer';
			el.addEventListener('click', () => router.push({ path: linkParts[1] }));
		}
	});
}

/**
 * Performs network request to pull Product Card data from WP.
 * Parses FS_paths into FS Data objects. Product Cards should be received in the same order they are passed in.
 * 
 * @param {String[]} products List of product slugs to fetch
 * @param {Object} state Component state
 * @param {*} param2 Object keys for state
 * @param {Boolean} exact Optional toggle for exact results. Default false. When false, will return related cards designated by Marketing, usually subscriptions the product is bundled in
 * @returns modified State containing request results
 */
export async function parseProductCards(products, state, { dataKey = 'data', loadingKey = 'loading', errorKey = 'error', setReady = true }, exact = false) {
	if (!(products && Array.isArray(products) && products.length)) {
		return null;
	}

	state[loadingKey] = true;

	const payload = {
		namespace: '/antares/v1',
		path: '/product-cards'
	};
	const hasSlugs = products.length;
	let res = null;

	if (hasSlugs) {
		payload.query = { 
			s: products.toString(),
			exact: exact ? '1' : '0'
		}
	}

	try {
		const client = new WpHttpClient(payload);		
		const { status, data } = await client.get();

		if (status !== 200) {
			throw new Error(status);
		}

		if (!(data && Array.isArray(data.cards))) {
			throw new Error('Missing/invalid data property in response');
		}

		res = data;
	} catch (e) {
		if (e.message !== "canceled") {
			console.error(e);
		}

      	state[errorKey] = true;
		state[loadingKey] = false;
		return;
	}

	res.cards = res.cards.map(card => {
		const included_in = card.meta.included_in || false;

		try {
			card = JSON.parse(card.meta.product_card);
		} catch (e) {
			console.error(`Unable to parse JSON for product card at slug ${card.post_name}: ${e.message}` );
			return null;
		}

		const { annual, monthly, default: defaultSku } = card.fs_products;

		try {
			card.fs_products.annual = new Product(annual);
		} catch (e) {
			card.fs_products.annual = null;
		}

		try {
			card.fs_products.monthly = new Product(monthly);
		} catch (e) {
			card.fs_products.monthly = null;
		}

		try {
			card.fs_products.default = new Product(defaultSku);
		} catch (e) {
			card.fs_products.default = null;
		}

		card.included_in = included_in;

		return card;
	}).filter(card => {
		if (!card) {
			return false;
		}

		const { annual, monthly, default: defaultSku } = card.fs_products;

		return (annual || monthly || defaultSku);
	});

	state[dataKey] = res;

	if (setReady) {
		state.ready = true;
	}

	state[loadingKey] = false;
}

/**
 * Adjust the provided promise to return a result object matching the schema outlined in PromiseSettledResult
 * @param {Promise} promise 
 * @returns {Promise} altered promise
 */
export async function processPromise (promise) {
	let res = {};

	try {
		res.value = await promise;
		res.status = "fulfilled";
	} catch (e) {
		res.reason = e.message || "An unknown error has occurred."
		res.status = "rejected";
	}
	
	return res;
}

/**
 * Polyfilled Promise.allSettled() function to maximize browser compatibility
 * @param {Promise[]} promises Array of Promises to execute.
 * @returns {Promise<any>} Single Promise
 */
export function allSettledPolyfilled (promises) {
	if (typeof Promise.allSettled === "function") {
		return Promise.allSettled(promises);
	} else {
		// Alternative for Browser Compatibility; iterates through array of Promises, augments output to follow schema of PromiseSettledResult
		return new Promise((resolve) => {
			let finishedPromises = 0;
			let res = new Array(promises.length);

			promises.forEach((promise, index) => {
				promise.then((promiseRes) => {
					res[index] = {
						status: "fulfilled",
						value: promiseRes
					}
				}).catch((error) => {
					res[index] = {
						status: "rejected",
						reason: error.message? error.message : "An unknown error has occurred"
					}
				}).finally(() => {
					finishedPromises++;
				});
			});

			// Check if all have been resolved in 10ms intervals
			let interval = setInterval(() => {
				if (finishedPromises >= promises.length) {
					clearInterval(interval);
					resolve (res);
				}
			}, 10);
		});
	}
}

/**
 * Wrapper function to handle loading & error flags for provided Promises. Watches all provided Promises simultaneously & resolves when all Promises have been settled.
 * @param {promise|promise[]} promises Promise(s) to be watched
 * @param {object} state state object for the page/component
 * @param {object} keys set of loading & error keys for the state object; should match the type of content being retrieved (I.E. tutorials_loading & tutorials_error). Defaults to "loading" and "error"
 * @param {function} Cb Callback function to be executed just before the end of the Try block; will be passed the results of the Promises as an Array of PromiseSettledResult
 * @param {function} errorCb Callback function to be executed in the event of an error; will be passed an Error input
 * @param {function} finalCb Callback function to be executed after both blocks, just before the loading flag is lowered
 * @returns {any[]} Results array for Promise(s) passed in, preserving order
 */
export async function contentLoader (promises, state, {loadingKey = "loading", errorKey = "error"}, Cb = () => {}, errorCb = () => {}, finalCb = () => {}) {
	let results = [];

	if (!(promises && state) || (Array.isArray(promises) && !promises.length)) {
		return results;
	}

	if (!Array.isArray(promises)) {
		const promise = promises;
		promises = [];
		promises.push(promise);
	}

	try {
		state[loadingKey] = true;
		state[errorKey] = false;

		if (promises.length === 1) {
			results[0] = await processPromise(promises[0]);

			if (results[0].status === "rejected") {
				throw new Error(results[0].reason);
			}
		} else {
			results = await allSettledPolyfilled(promises);
		}

		Cb(results);
	} catch (e) { 
		if (e.message !== "canceled") {
			console.log(e);

			errorCb(e);
			state[errorKey] = true;
		}
	} finally {
		finalCb();
		state[loadingKey] = false;
		router.initCSSLazyLoader();
	}

	return results;
}