import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["disabled"];
import { reactive } from 'vue';
import newsletter from '@/composables/newsletter.js';
import { validateEmail } from '@/helpers';
export default {
  __name: 'Newsletter',
  props: {
    darkmode: {
      type: Boolean,
      required: false,
      default: false
    },
    align: {
      type: String,
      default: 'start',
      validator(v) {
        return ['start', 'center', 'end'].includes(v.toLowerCase());
      }
    }
  },
  setup(__props) {
    const form = reactive({
      email: '',
      fax: false
    });
    const state = reactive({
      loading: false
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createBlock(_component_el_form, {
        modelValue: form,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => form = $event)
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: _normalizeClass(["Newsletter", {
            darkmode: __props.darkmode
          }]),
          style: _normalizeStyle({
            'align-items': __props.align
          })
        }, [_cache[5] || (_cache[5] = _createElementVNode("h5", null, "Newsletter", -1)), _createVNode(_component_el_form_item, {
          class: "email-input"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            placeholder: "artist@email.com",
            modelValue: form.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.email = $event),
            disabled: state.loading,
            onFocus: _unref(newsletter).handleEmailFieldFocusEvent
          }, null, 8, ["modelValue", "disabled", "onFocus"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          style: {
            "display": "none"
          },
          label: "Contact me by fax"
        }, {
          default: _withCtx(() => [_withDirectives(_createElementVNode("input", {
            type: "checkbox",
            name: "fax",
            label: "Contact me by fax",
            disabled: state.loading,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form.fax = $event)
          }, null, 8, _hoisted_1), [[_vModelCheckbox, form.fax]])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            class: "submit-btn",
            onClick: _cache[2] || (_cache[2] = $event => _unref(newsletter).handleSubmit(form, state, {})),
            disabled: state.loading || !_unref(validateEmail)(form.email)
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("Sign up")])),
            _: 1
          }, 8, ["disabled"])]),
          _: 1
        })], 6)]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
};