import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition } from "vue";
const _hoisted_1 = ["onTouchstart"];
const _hoisted_2 = ["onMouseenter"];
const _hoisted_3 = ["href"];
const _hoisted_4 = ["onMouseenter"];
const _hoisted_5 = {
  key: 2
};
const _hoisted_6 = ["onMouseenter"];
const _hoisted_7 = {
  class: "scroll-wrapper"
};
const _hoisted_8 = ["data-key"];
import { ref, reactive, computed, onMounted, onBeforeUnmount, nextTick, watch } from 'vue';
import { refThrottled } from "@vueuse/core";
import { getSize, registerResize, removeResize } from '@/composables/windowWatcher';
import ProductIcon from '@/components/ProductComponents/ProductIcon.vue';
import { ArrowLeftBold, ArrowRightBold } from '@element-plus/icons-vue';
import NavMenu from "./NavMenu.json";
const itemWidth = 120; // submenu entry width; defined here to sync scroll handler to always scroll 3 items

export default {
  __name: 'NavBarMenu',
  setup(__props) {
    const navBarMenu = ref(null);
    const hasEL = ref(false);
    const currMenu = ref(-1);
    const menuEntryElems = ref({});
    const menuEntrySizes = reactive({});
    const subMenus = ref([]);
    const subMenuSizes = reactive({});
    const subMenuScrolls = ref([]);
    const currMenuThrottled = refThrottled(currMenu, 100);
    const windowSize = getSize();
    const copy = JSON.parse(JSON.stringify(NavMenu));
    const menuItems = ref(copy.filter(item => {
      if (item.submenu) {
        item.submenu = item.submenu.filter(subitem => !subitem.hideFromNavBar);
      }
      return !item.hideFromNavBar;
    }));

    // Computed

    const currMenuElem = computed(() => {
      if (!subMenus.value) {
        return null;
      }
      const items = Object.values(subMenus.value);
      if (!(items && items.length)) {
        return null;
      }
      return items.find(elem => elem.getAttribute("data-key") === currMenu.value);
    });

    /**
     * Calculate Menu Offsets for margin calculation
     * Offsets are in relation to center of screen width
     * 
     * max (0px, half of container - (half content width +/- offset from center) +/- overflow of opposing side, min 0 )
     * 
     * Margin calculation will attempt to place the center of the submenu content on the same horizontal value of the center point of its associated main menu entry.
     * If this would cause a portion of the submenu to overflow out of screen, this will attempt to adjust the margin on the opposed side to keep content on screen.
     * If neither margin can be adjusted to accomodate the submenu size, a horizontal scrollable submenu will be present.
     */
    const subMenuOffsetsComp = computed(() => {
      const offsets = [];
      if (!(menuEntryElems.value && Object.values(menuEntryElems.value).length)) {
        return offsets;
      }
      for (let [key] of Object.entries(menuEntryElems.value)) {
        const rect = menuEntrySizes[key] || {
          left: 0,
          width: 0
        };
        offsets[key] = windowSize.width / 2 - (rect.left + rect.width / 2);
      }
      return offsets;
    });
    const showLeftArrow = computed(() => {
      if (currMenu.value < 0) {
        return false;
      }
      const dimensions = subMenuSizes[currMenu.value];
      if (!dimensions || dimensions.width === 0 || dimensions.scrollWidth <= dimensions.width) {
        return false;
      }
      return subMenuScrolls.value[currMenu.value] > 0;
    });
    const showRightArrow = computed(() => {
      if (currMenu.value < 0) {
        return false;
      }
      const dimensions = subMenuSizes[currMenu.value];
      if (!dimensions || dimensions.width === 0 || dimensions.scrollWidth <= dimensions.width) {
        return false;
      }
      return subMenuScrolls.value[currMenu.value] < dimensions.scrollWidth - dimensions.width;
    });

    // Methods

    /**
     * Build out the Destination object for vue-router's router-link component
     * 
     * @param {Object} item - main menu object entry
     * @param {Object} subitem - sub menu object entry
     * @returns {RouteLocationRaw} destination
     */
    const buildDestinationObj = (item, subitem) => {
      if (subitem.externalLink) {
        // Skip; submenu item points externally
        return null;
      }
      if (subitem.destination) {
        return subitem.destination;
      }
      if (!item.destination) {
        return null;
      }
      return {
        ...item.destination,
        params: {
          slug: subitem.slug
        }
      };
    };

    /**
     * Clean the specified submenu.
     * Remove event listener & reset scroll values
     * 
     * @param {HTMLElement} elem 
     */
    const resetRow = elem => {
      if (!elem) {
        return;
      }
      const index = elem.getAttribute("data-key");
      elem.removeEventListener("scroll", onScroll);
      subMenuScrolls.value[index] = 0;
      setTimeout(() => {
        elem.scrollTo(0, 0);
      }, 300); // Timer should match animation time
    };

    /**
     * Scrollhandler function for EventListener.
     * Update
     * 
     * @param {Object} event 
     */
    const onScroll = event => {
      subMenuScrolls.value[currMenu.value] = event.target.scrollLeft;
    };

    /**
     * Open Submenu corresponding to provided Key
     * 
     * @param {String} i 
     */
    const handleDropdown = i => {
      if (currMenu.value !== -1 && subMenus.value[i] && subMenus.value[i] === currMenuElem.value) {
        return;
      }
      const prevMenuElem = currMenuElem.value;
      subMenuScrolls.value[i] = 0;
      currMenu.value = i;
      if (currMenuElem.value) {
        currMenuElem.value.addEventListener("scroll", onScroll);
      }
      if (prevMenuElem) {
        resetRow(prevMenuElem);
      }
    };

    /**
     * Event handler for clearing submenu dropdown when user touches section outside of this component.
     * 
     * Allows for submenu dropdown to be closed on touch screen devices.
     * 
     * @param {TouchEvent} e 
     */
    const handleTouchOut = e => {
      if (!navBarMenu.value?.$el.contains(e.target) && currMenu.value !== -1) {
        clearDropdown();
        document.removeEventListener('touchstart', handleTouchOut);
        hasEL.value = false;
      }
    };

    /**
     * Handles a touchstart event depending on the current state.
     * 
     * If the index has an associated submenu, open the submenu on first touch.
     * If the submenu is already opened, simulate a click on the target element.
     * 
     * If no submenu exists for the index, simply simulate a click on the target element.
     * 
     * @param {TouchEvent} e 
     * @param {String} index
     */
    const handleTouchStart = (e, index) => {
      if (e.touches.length > 1) {
        return;
      }

      // No submenu exists for the new index or submenu is already opened
      if (!subMenus.value[index] || index === currMenu.value) {
        e.target?.click();
        clearDropdown();
        return;
      }

      // Insert a global event listener if one is not mounted
      if (!hasEL.value) {
        hasEL.value = true;
        document.addEventListener('touchstart', handleTouchOut, {
          passive: true
        });
      }
      handleDropdown(index);
    };

    /**
     * Open new Submenu
     * 
     * @param {Number} i 
     */
    const onDropdown = ({
      e,
      i
    }) => {
      switch (e.type) {
        case "touchstart":
          if (e.cancelable) {
            e.stopImmediatePropagation();
            e.preventDefault();
          }
          handleTouchStart(e, i);
          break;
        case "mouseenter":
          handleDropdown(i);
          break;
      }
    };

    /**
     * Close the current active submenu
     */
    const clearDropdown = () => {
      const prevMenuElem = currMenuElem.value;
      currMenu.value = -1;
      resetRow(prevMenuElem);
    };

    /**
     * Programatically scroll the current submenu instance
     * 
     * @param {Number} val value to horizontally scroll by
     */
    const scrollSubmenu = val => {
      if (currMenuElem.value) {
        currMenuElem.value.scrollBy(val, 0);
      }
    };

    /**
     * Handles clicking on a submenu item. If submenu item's parent object contains a property 'track', then
     * then an event object is 'pushed' into the GA dataLayer object.
     * @param {object} item
     * @param {object} subMenu
     */
    const handleSubmenuClick = (item, subMenu) => {
      if (subMenu.track) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'view_item',
          ecommerce: {
            items: [{
              item_name: item.name
            }]
          }
        });
      }
    };

    // Hooks

    watch(windowSize, () => {
      nextTick(() => {
        for (let [key, el] of Object.entries(menuEntryElems.value)) {
          if (!el) {
            continue;
          }
          const rect = el.getBoundingClientRect();
          const data = {
            left: rect.left,
            width: rect.width
          };

          // Browser Compat to ensure we get a width
          if (typeof data.width !== "number") {
            data.width = el.clientWidth;
          }
          menuEntrySizes[key] = data;
        }
      });
    });
    onMounted(() => {
      nextTick(() => {
        for (let [key, el] of Object.entries(menuEntryElems.value)) {
          if (!el) {
            console.warn(`Empty element encountered`);
            continue;
          }
          const rect = el.getBoundingClientRect();
          const data = {
            left: rect.left,
            width: rect.width
          };

          // Browser Compat to ensure we get a width
          if (typeof data.width !== "number") {
            data.width = el.clientWidth;
          }
          menuEntrySizes[key] = data;
        }
        for (let entry of Object.values(subMenus.value)) {
          if (!entry) {
            console.warn(`Empty element encountered`);
            continue;
          }
          let index = entry.getAttribute('data-key');
          subMenuSizes[index] = registerResize(entry, ["width", "scrollWidth"]);
        }
      });
    });
    onBeforeUnmount(() => {
      for (let entry of subMenus.value) {
        removeResize(entry);
      }
      if (hasEL.value) {
        document.removeEventListener('touchstart', handleTouchOut);
      }
    });
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_row = _resolveComponent("el-row");
      return _openBlock(), _createBlock(_component_el_row, {
        justify: "center",
        style: {
          "flex-wrap": "nowrap",
          "height": "100%"
        },
        onMouseleave: clearDropdown,
        ref_key: "navBarMenu",
        ref: navBarMenu
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItems.value, (item, i) => {
          return _openBlock(), _createElementBlock("div", {
            key: i
          }, [_createElementVNode("div", {
            class: _normalizeClass(["fill-container flex-col flex-centered menu-item", {
              active: _unref(currMenuThrottled) === `submenu_${i}`
            }]),
            ref_for: true,
            ref: el => menuEntryElems.value[`submenu_${i}`] = el,
            onTouchstart: e => onDropdown({
              e,
              i: `submenu_${i}`
            })
          }, [item.destination ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: item.destination
          }, {
            default: _withCtx(() => [_createElementVNode("h3", {
              class: "menu-label",
              onMouseenter: e => onDropdown({
                e,
                i: `submenu_${i}`
              })
            }, _toDisplayString(item.name), 41, _hoisted_2)]),
            _: 2
          }, 1032, ["to"])) : item.externalLink ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            href: item.externalLink,
            target: "_blank"
          }, [_createElementVNode("h3", {
            class: "menu-label",
            onMouseenter: e => onDropdown({
              e,
              i: `submenu_${i}`
            })
          }, _toDisplayString(item.name), 41, _hoisted_4)], 8, _hoisted_3)) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("h3", {
            class: "menu-label",
            onMouseenter: e => onDropdown({
              e,
              i: `submenu_${i}`
            })
          }, _toDisplayString(item.name), 41, _hoisted_6)]))], 42, _hoisted_1), item.submenu && item.submenu.length > 0 ? (_openBlock(), _createBlock(_Transition, {
            key: 0,
            appear: "",
            name: "submenu"
          }, {
            default: _withCtx(() => [_withDirectives(_createVNode(_component_el_row, {
              class: "submenu"
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", {
                class: _normalizeClass(["flex-row scroll-container", {
                  active: _unref(currMenuThrottled) === `submenu_${i}`
                }]),
                "data-key": `submenu_${i}`,
                ref_for: true,
                ref: el => subMenus.value[`submenu_${i}`] = el
              }, [_createElementVNode("div", {
                class: "content",
                style: _normalizeStyle({
                  'margin-left': `max(0px, calc(50% - ${itemWidth * item.submenu.length / 2 + (subMenuOffsetsComp.value['submenu_' + i] || 0)}px + min(0px, calc(50% - ${itemWidth * item.submenu.length / 2 - (subMenuOffsetsComp.value['submenu_' + i] || 0)}px))))`,
                  'margin-right': `max(0px, calc(50% - ${itemWidth * item.submenu.length / 2 - (subMenuOffsetsComp.value['submenu_' + i] || 0)}px - min(opx, calc(50% - ${itemWidth * item.submenu.length / 2 + (subMenuOffsetsComp.value['submenu_' + i] || 0)}px)))`
                })
              }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.submenu, (subitem, j) => {
                return _openBlock(), _createBlock(ProductIcon, {
                  key: j,
                  class: "fullheight",
                  router_link: buildDestinationObj(item, subitem),
                  external_link: subitem.externalLink,
                  style: _normalizeStyle([{
                    "display": "inline-block"
                  }, `flex: 0 0 ${itemWidth}px; width: ${itemWidth}px`]),
                  name: subitem.navMenuShorthand || subitem.name,
                  icon_link: subitem.icon_link,
                  icon_letter: subitem.icon,
                  active: "",
                  menuItem: "",
                  onClick: () => {
                    handleSubmenuClick(subitem, item);
                    clearDropdown();
                  }
                }, null, 8, ["router_link", "external_link", "style", "name", "icon_link", "icon_letter", "onClick"]);
              }), 128))], 4)], 10, _hoisted_8), _createVNode(_Transition, {
                appear: "",
                name: "fast-fade-in-place"
              }, {
                default: _withCtx(() => [_withDirectives(_createElementVNode("div", {
                  class: "arrow",
                  style: {
                    "left": "0px"
                  },
                  onClick: _cache[0] || (_cache[0] = () => scrollSubmenu(-1 * (itemWidth * 3)))
                }, [_createVNode(_component_el_icon, {
                  size: 32
                }, {
                  default: _withCtx(() => [_createVNode(_unref(ArrowLeftBold))]),
                  _: 1
                })], 512), [[_vShow, showLeftArrow.value]])]),
                _: 1
              }), _createVNode(_Transition, {
                apepar: "",
                name: "fast-fade-in-place"
              }, {
                default: _withCtx(() => [_withDirectives(_createElementVNode("div", {
                  class: "arrow",
                  style: {
                    "right": "0px"
                  },
                  onClick: _cache[1] || (_cache[1] = () => scrollSubmenu(itemWidth * 3))
                }, [_createVNode(_component_el_icon, {
                  size: 32
                }, {
                  default: _withCtx(() => [_createVNode(_unref(ArrowRightBold))]),
                  _: 1
                })], 512), [[_vShow, showRightArrow.value]])]),
                _: 1
              })])]),
              _: 2
            }, 1536), [[_vShow, _unref(currMenuThrottled) === `submenu_${i}`]])]),
            _: 2
          }, 1024)) : _createCommentVNode("", true)]);
        }), 128))]),
        _: 1
      }, 512);
    };
  }
};