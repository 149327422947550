import "core-js/modules/es.array.push.js";
import { useCssVars as _useCssVars } from 'vue';
import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, Fragment as _Fragment, unref as _unref, renderList as _renderList } from "vue";
const _hoisted_1 = {
  class: "card",
  "data-testid": "productCard"
};
const _hoisted_2 = {
  key: 0,
  class: "top-subtext"
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  class: "title std-padding_line-sm",
  notranslate: ""
};
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = {
  key: 3
};
const _hoisted_7 = {
  class: "price",
  "data-testid": "productPrice"
};
const _hoisted_8 = {
  key: 0
};
const _hoisted_9 = {
  style: {
    "text-decoration": "line-through",
    "margin-left": "5px"
  }
};
const _hoisted_10 = {
  key: 0
};
const _hoisted_11 = {
  key: 0,
  class: "price",
  "data-testid": "productPrice"
};
const _hoisted_12 = {
  key: 1,
  class: "price",
  "data-testid": "productPrice"
};
const _hoisted_13 = {
  key: 2
};
const _hoisted_14 = {
  key: 3
};
const _hoisted_15 = {
  class: "subtext"
};
const _hoisted_16 = {
  class: "inclusion-list std-padding_line-sm flex-col"
};
const _hoisted_17 = ["innerHTML"];
const _hoisted_18 = {
  class: "inclusion-list std-padding_line-sm flex-col"
};
const _hoisted_19 = ["innerHTML"];
const _hoisted_20 = {
  key: 0,
  class: "std-padding_line-sm"
};
const _hoisted_21 = {
  key: 1,
  class: "std-padding_line-sm"
};
const _hoisted_22 = ["innerHTML"];
import { ref, computed, watch } from "vue";
import { Right } from '@element-plus/icons-vue';
import { currentBP } from "@/composables/windowWatcher";
import WpElButton from "@/components/WpComponents/WpElButton.vue";
import ToggleSwitch from "@/components/ToggleSwitch.vue";
export default {
  __name: 'ProductOptionCard',
  props: {
    includes: Boolean,
    productDetails: {
      type: Object,
      required: true
    },
    dealBanner: String,
    reset: Boolean,
    maxHeight: {
      type: Boolean,
      required: false,
      default: true
    },
    enableLearnMoreDropdown: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(__props) {
    _useCssVars(_ctx => ({
      "926ddbb4": heightStyleProp.value
    }));
    const props = __props;
    const showAdditionalCardContent = ref(false);
    const subIntervalShown = ref(true);

    // Computed Properties

    /**
     * Returns the normal, non-sale product price. If the product is a subscription, returns the price for the subscription term that corresponds
     * to the value of subIntervalShown. Otherwise, returns the price for the default product.
     * @returns {string|null}
     */
    const normalPrice = computed(() => {
      const {
        type,
        fs_products,
        useNormalRate
      } = props.productDetails;
      if (!fs_products) {
        return null;
      }
      const {
        annual,
        monthly,
        default: defaultProduct
      } = fs_products;
      if (type === 'subscription') {
        let annualPrice = annual?.currencyFormattedBasePrice || null;
        if (!useNormalRate) {
          annualPrice = annual?.currencyFormattedAnnualSubscriptionMonthlyBaseRate || null;
        }
        const monthlyPrice = monthly?.currencyFormattedBasePrice || null;
        return subIntervalShown.value ? annualPrice : monthlyPrice;
      } else if (defaultProduct?.isSubscription) {
        // when it's still a sub but it's specified as a perpetual in WP
        if (defaultProduct.isAnnualSubscription) {
          return defaultProduct.currencyFormattedAnnualSubscriptionMonthlyBaseRate;
        } else {
          return defaultProduct.currencyFormattedFinalPrice;
        }
      }
      return defaultProduct?.currencyFormattedBasePrice || null;
    });

    /**
     * Returns the sale price. If the product is a subscription, returns the sale price for the subscription term that corresponds
     * to the value of subIntervalShown. Otherwise, returns the sale price for the default product.
     * Returns null if product is not on sale.
     * @returns {string|null}
     */
    const salePrice = computed(() => {
      const {
        type,
        fs_products,
        useNormalRate
      } = props.productDetails;
      if (!fs_products) {
        return null;
      }
      const {
        annual,
        monthly,
        default: defaultProduct
      } = fs_products;
      if (type === 'subscription') {
        let annualPrice = annual?.productIsDiscounted ? annual.currencyFormattedFinalPrice : null;
        if (!useNormalRate) {
          annualPrice = annual?.productIsDiscounted ? annual.currencyFormattedAnnualSubscriptionMonthlyFinalRate : null;
        }
        const monthlyPrice = monthly?.productIsDiscounted ? monthly.currencyFormattedFinalPrice : null;
        return subIntervalShown.value ? annualPrice : monthlyPrice;
      } else if (defaultProduct?.isSubscription) {
        // when it's still a sub but it's specified as a perpetual in WP
        if (defaultProduct.isAnnualSubscription) {
          return defaultProduct.productIsDiscounted ? defaultProduct.currencyFormattedAnnualSubscriptionMonthlyFinalRate : null;
        } else {
          return defaultProduct.productIsDiscounted ? defaultProduct.currencyFormattedFinalPrice : null;
        }
      }
      return defaultProduct.productIsDiscounted ? defaultProduct.currencyFormattedFinalPrice : null;
    });

    /**
     * Returns the additional product content, shown below the product card CTA.
     * @returns {object}
     */
    const content = computed(() => {
      const {
        type,
        content
      } = props.productDetails;
      if (type === 'subscription') {
        return subIntervalShown.value ? content.annual : content.monthly;
      }
      return content.default;
    });

    /**
     * Returns a boolean indicating whether the product to which this card corresponds is a subscription.
     * @returns {boolean}
     */
    const isSub = computed(() => {
      return props.productDetails.type === "subscription" || props.productDetails.fs_products.default?.isSubscription;
    });

    /**
     * Returns a string indicating the subscription's interval value, represented as /yr for yearly, /mo for monthly, or empty string
     * if the product to which this card corresponds is not a subscription.
     * @returns {string}
     */
    const interval = computed(() => {
      if (!isSub.value) {
        return "";
      }
      const {
        useNormalRate,
        type,
        fs_products
      } = props.productDetails;
      if (!useNormalRate) {
        return "/mo";
      }
      if (type === "subscription") {
        return subIntervalShown.value ? "/yr" : "/mo";
      }
      return fs_products.default?.isAnnualSubscription ? "/yr" : "/mo";
    });

    /**
     * Returns the price subtext string depending on the product type (subscription or perpetual) and the subscription term.
     * @returns {string}
     */
    const priceSubtext = computed(() => {
      const {
        type,
        price_subtext
      } = props.productDetails;
      if (type !== "subscription") {
        return price_subtext?.default || "ONE TIME PAYMENT";
      }
      if (subIntervalShown.value) {
        return price_subtext?.annual || "ANNUAL SUBSCRIPTION";
      } else {
        return price_subtext?.monthly || "MONTHLY SUBSCRIPTION";
      }
    });

    /**
     * TODO: DOCUMENTATION NEEDED
     * @returns {object}
     */
    const CTAHandler = computed(() => {
      const {
        type,
        CTA_Overrides,
        fs_products
      } = props.productDetails;
      if (type === "upgrade") {
        return CTA_Overrides?.default || {
          action: 'handle_upgrade',
          data: {
            path: fs_products.default?.path,
            followup: true
          }
        };
      }
      if (type !== 'subscription') {
        return CTA_Overrides?.default || {
          action: 'add_to_cart',
          data: fs_products.default?.path
        };
      }
      const subscriptionType = subIntervalShown.value ? 'annual' : 'monthly';
      return CTA_Overrides?.[subscriptionType] || {
        action: 'add_to_cart',
        data: fs_products[subscriptionType]?.path
      };
    });

    /**
     * TODO: DOCUMENTATION NEEDED
     * @returns {object}
     */
    const priceConditional = computed(() => {
      const {
        fs_products,
        price_conditionals,
        type
      } = props.productDetails;
      let conditional = price_conditionals?.default;
      if (!conditional && fs_products.default?.isSubscription && fs_products.default.isAnnualSubscription) {
        conditional = `Charged Annually at ${fs_products.default.currencyFormattedFinalPrice}/yr. Plan can be modified at any time.`;
      }

      // Card is the subscription itself; should have both intervals.
      if (type !== 'subscription') {
        return conditional;
      }
      const intervalType = subIntervalShown.value ? 'annual' : 'monthly';
      conditional = price_conditionals?.[intervalType] || (intervalType === 'annual' ? `Charged Annually at ${fs_products.annual.currencyFormattedFinalPrice}/yr. Plan can be modified at any time.` : conditional);
      return conditional;
    });

    /**
     * Returns the product's terms and conditions.
     * @returns {string|null}
     */
    const TnC = computed(() => {
      return props.productDetails.TnC;
    });

    /**
     * TODO: DOCUMENTATION NEEDED
     * @returns {string|null}
     */
    const Notes = computed(() => {
      return props.productDetails?.Notes;
    });

    /**
     * TODO: DOCUMENTATION NEEDED
     * @returns {string}
     */
    const primaryCTAText = computed(() => {
      const {
        type,
        primaryCTAText,
        fs_products
      } = props.productDetails;
      let text = "";
      switch (type) {
        case "subscription":
          text = (subIntervalShown.value ? primaryCTAText?.annual : primaryCTAText?.monthly) || "Subscribe Now";
          break;
        case "trial":
          text = primaryCTAText?.default || "Get a FREE Trial";
          break;
        case "upgrade":
          text = primaryCTAText?.default || "Upgrade Now";
          break;
        case "perpetual":
        default:
          text = primaryCTAText?.default || fs_products?.default?.isSubscription ? "Subscribe Now" : "Buy Now";
      }
      return text;
    });

    /**
     * TODO: DOCUMENTATION NEEDED
     * @returns {array}
     */
    const trialAuxCTAs = computed(() => {
      if (props.productDetails.CTA_Overrides?.trial) {
        return props.productDetails.CTA_Overrides.trial;
      }
      let ctas = [];
      if (props.productDetails.fs_products.monthly) {
        ctas.push({
          "text": `<span notranslate>${props.productDetails.fs_products.monthly.currencyFormattedFinalPrice}/mo</span>`,
          "action": "add_to_cart",
          "data": props.productDetails.fs_products.monthly.path
        });
      }
      if (props.productDetails.fs_products.annual) {
        ctas.push({
          "text": `<span notranslate>${props.productDetails.fs_products.annual.currencyFormattedFinalPrice}/yr</span>`,
          "action": "add_to_cart",
          "data": props.productDetails.fs_products.annual.path
        });
      }
      return ctas;
    });
    const heightStyleProp = computed(() => {
      return props.maxHeight ? '100%' : 'unset';
    });

    // Watchers

    watch(() => props.reset, newVal => {
      if (newVal) {
        subIntervalShown.value = true;
      }
    });
    return (_ctx, _cache) => {
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_ArrowButton = _resolveComponent("ArrowButton");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_container = _resolveComponent("el-container");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        class: "flex-col flex-center deal-banner",
        style: _normalizeStyle({
          'visibility': __props.dealBanner ? 'visible' : 'hidden'
        })
      }, _toDisplayString((__props.dealBanner ? __props.dealBanner : "Best Deal").toUpperCase()), 5), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_container, {
        direction: "vertical",
        class: "fill-container card-inner",
        key: "details",
        "data-testid": `product_${__props.productDetails.title}_card`
      }, {
        default: _withCtx(() => [_createVNode(_component_el_row, null, {
          default: _withCtx(() => [_createVNode(_component_el_col, null, {
            default: _withCtx(() => [_createVNode(_component_el_row, {
              align: "middle",
              justify: "center",
              class: "std-padding_line-md"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_col, null, {
                default: _withCtx(() => [__props.includes ? (_openBlock(), _createElementBlock("span", _hoisted_2, "AS PART OF")) : (_openBlock(), _createElementBlock("br", _hoisted_3)), _createElementVNode("h2", _hoisted_4, _toDisplayString(__props.productDetails.title), 1), __props.productDetails.subtitle ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  innerHTML: __props.productDetails.subtitle,
                  style: {
                    "font-size": "16px"
                  }
                }, null, 8, _hoisted_5)) : (_openBlock(), _createElementBlock("br", _hoisted_6))]),
                _: 1
              })]),
              _: 1
            }), __props.productDetails.type !== 'trial' ? (_openBlock(), _createElementBlock(_Fragment, {
              key: 0
            }, [_createVNode(_component_el_row, {
              class: "std-padding_line-lg"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_col, null, {
                default: _withCtx(() => [normalPrice.value && !salePrice.value ? (_openBlock(), _createBlock(_component_el_row, {
                  key: 0,
                  align: "middle",
                  justify: "center",
                  class: "price-row std-padding_line-sm",
                  style: {
                    "font-weight": "600"
                  }
                }, {
                  default: _withCtx(() => [_createElementVNode("h2", _hoisted_7, [_createTextVNode(_toDisplayString(normalPrice.value), 1), isSub.value ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(interval.value), 1)) : _createCommentVNode("", true)])]),
                  _: 1
                })) : normalPrice.value && salePrice.value && !isSub.value ? (_openBlock(), _createBlock(_component_el_row, {
                  key: 1,
                  align: "middle",
                  justify: "center",
                  style: {
                    "color": "var(--el-color-danger)",
                    "font-weight": "600"
                  }
                }, {
                  default: _withCtx(() => [_cache[1] || (_cache[1] = _createTextVNode(" Was ")), _createElementVNode("span", _hoisted_9, [_createTextVNode(_toDisplayString(normalPrice.value), 1), isSub.value ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(interval.value), 1)) : _createCommentVNode("", true)])]),
                  _: 1
                })) : _createCommentVNode("", true), salePrice.value ? (_openBlock(), _createBlock(_component_el_row, {
                  key: 2,
                  align: "bottom",
                  justify: "center",
                  class: "price-row std-padding_line-sm",
                  notranslate: ""
                }, {
                  default: _withCtx(() => [!isSub.value ? (_openBlock(), _createElementBlock("h2", _hoisted_11, [_cache[2] || (_cache[2] = _createElementVNode("span", null, "Now ", -1)), _createTextVNode(_toDisplayString(salePrice.value), 1)])) : (_openBlock(), _createElementBlock("h2", _hoisted_12, _toDisplayString(salePrice.value), 1)), isSub.value ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(interval.value), 1)) : _createCommentVNode("", true), priceConditional.value ? (_openBlock(), _createElementBlock("span", _hoisted_14, "*")) : _createCommentVNode("", true)]),
                  _: 1
                })) : _createCommentVNode("", true), _createVNode(_component_el_row, {
                  align: "middle",
                  justify: "center"
                }, {
                  default: _withCtx(() => [_createElementVNode("span", _hoisted_15, _toDisplayString(priceSubtext.value), 1)]),
                  _: 1
                })]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_row, {
              align: "middle",
              justify: "center",
              class: "std-padding_line-xl",
              style: _normalizeStyle({
                'visibility': __props.productDetails.type === 'subscription' ? 'visible' : 'hidden'
              })
            }, {
              default: _withCtx(() => [_createVNode(ToggleSwitch, {
                state: subIntervalShown.value,
                onToggled: _cache[0] || (_cache[0] = val => subIntervalShown.value = val),
                "data-testid": "toggleSwitch"
              }, {
                off: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode(" Monthly ")])),
                on: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode(" Annual ")])),
                _: 1
              }, 8, ["state"])]),
              _: 1
            }, 8, ["style"])], 64)) : (_openBlock(), _createBlock(_component_el_row, {
              key: 1,
              class: "std-padding_line-lg"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_col, null, {
                default: _withCtx(() => [_createVNode(_component_el_row, {
                  align: "bottom",
                  justify: "center",
                  class: "price-row std-padding_line-sm",
                  notranslate: ""
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("h2", {
                    class: "price"
                  }, [_createElementVNode("span", null, "Free 14-Day* Trial")], -1)])),
                  _: 1
                })]),
                _: 1
              })]),
              _: 1
            }))]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_row, {
          align: "middle",
          justify: "center"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            style: {
              "max-width": "75%"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_el_row, {
              align: "middle",
              justify: "center",
              class: "std-padding_line-md"
            }, {
              default: _withCtx(() => [_createVNode(WpElButton, {
                action: CTAHandler.value.action,
                value: CTAHandler.value.data,
                type: "primary",
                "custom-class": _unref(currentBP) != 'xs' ? 'extra-large' : '',
                size: "large",
                style: {
                  "width": "100%"
                },
                "data-testid": "ctaButton"
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(primaryCTAText.value), 1)]),
                _: 1
              }, 8, ["action", "value", "custom-class"])]),
              _: 1
            }), __props.productDetails.type === 'trial' && trialAuxCTAs.value.length ? (_openBlock(), _createBlock(_component_el_row, {
              key: 0,
              align: "middle",
              justify: "center"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_col, null, {
                default: _withCtx(() => [_createElementVNode("div", null, " Or Subscribe to " + _toDisplayString(__props.productDetails.title), 1), _createVNode(_component_el_row, {
                  justify: "center",
                  align: "middle",
                  gutter: 10,
                  style: {
                    "padding-bottom": "10px"
                  }
                }, {
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(trialAuxCTAs.value, (btn, index) => {
                    return _openBlock(), _createBlock(_component_el_col, {
                      sm: 24 / trialAuxCTAs.value.length,
                      style: {
                        "padding-top": "10px"
                      },
                      key: index
                    }, {
                      default: _withCtx(() => [_createVNode(WpElButton, {
                        action: btn.action,
                        value: btn.data,
                        type: "primary",
                        "custom-class": `black-base ${_unref(currentBP) != 'xs' ? 'extra-large' : ''}`,
                        size: "large",
                        style: {
                          "width": "100%"
                        },
                        innerHTML: btn.text,
                        "data-testid": `cta_${btn.data}_button`
                      }, null, 8, ["action", "value", "custom-class", "innerHTML", "data-testid"])]),
                      _: 2
                    }, 1032, ["sm"]);
                  }), 128))]),
                  _: 1
                })]),
                _: 1
              })]),
              _: 1
            })) : _createCommentVNode("", true)]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_row, {
          style: {
            "flex": "1 0 auto"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            class: "flex-col",
            style: {
              "justify-content": "space-between"
            }
          }, {
            default: _withCtx(() => [__props.enableLearnMoreDropdown ? (_openBlock(), _createBlock(_component_el_row, {
              key: 0,
              justify: "center"
            }, {
              default: _withCtx(() => [_createElementVNode("div", null, [_createVNode(_component_ArrowButton, {
                disabled: false,
                dark: false,
                clickHandler: () => showAdditionalCardContent.value = !showAdditionalCardContent.value,
                textFontSize: "22px",
                iconFontSize: "25px",
                rotateIconOnClick: true
              }, {
                text: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode(" Learn More ")])),
                _: 1
              }, 8, ["clickHandler"])])]),
              _: 1
            })) : _createCommentVNode("", true), showAdditionalCardContent.value && __props.enableLearnMoreDropdown ? (_openBlock(), _createBlock(_component_el_row, {
              key: 1,
              align: "middle",
              justify: "center"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_col, {
                class: "included"
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(content.value, (details, heading) => {
                  return _openBlock(), _createElementBlock("div", {
                    key: heading,
                    class: "std-padding_line-lg"
                  }, [_createVNode(_component_el_row, {
                    align: "middle",
                    justify: "center",
                    class: "std-padding_line-md"
                  }, {
                    default: _withCtx(() => [_createTextVNode(_toDisplayString(heading) + ": ", 1)]),
                    _: 2
                  }, 1024), _createVNode(_component_el_row, {
                    align: "middle",
                    justify: "center"
                  }, {
                    default: _withCtx(() => [_createElementVNode("div", _hoisted_16, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(details, (detail, index) => {
                      return _openBlock(), _createBlock(_component_el_row, {
                        key: index,
                        style: {
                          "flex-wrap": "nowrap",
                          "line-height": "1.5em"
                        }
                      }, {
                        default: _withCtx(() => [_createVNode(_component_el_icon, {
                          style: {
                            "margin-right": "10px",
                            "height": "1.5em",
                            "color": "var(--el-color-primary)"
                          }
                        }, {
                          default: _withCtx(() => [_createVNode(_unref(Right))]),
                          _: 1
                        }), _createElementVNode("span", {
                          innerHTML: detail
                        }, null, 8, _hoisted_17)]),
                        _: 2
                      }, 1024);
                    }), 128))])]),
                    _: 2
                  }, 1024)]);
                }), 128))]),
                _: 1
              })]),
              _: 1
            })) : !__props.enableLearnMoreDropdown ? (_openBlock(), _createBlock(_component_el_row, {
              key: 2,
              align: "middle",
              justify: "center"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_col, {
                class: "included"
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(content.value, (details, heading) => {
                  return _openBlock(), _createElementBlock("div", {
                    key: heading,
                    class: "std-padding_line-lg"
                  }, [_createVNode(_component_el_row, {
                    align: "middle",
                    justify: "center",
                    class: "std-padding_line-md"
                  }, {
                    default: _withCtx(() => [_createTextVNode(_toDisplayString(heading) + ": ", 1)]),
                    _: 2
                  }, 1024), _createVNode(_component_el_row, {
                    align: "middle",
                    justify: "center"
                  }, {
                    default: _withCtx(() => [_createElementVNode("div", _hoisted_18, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(details, (detail, index) => {
                      return _openBlock(), _createBlock(_component_el_row, {
                        key: index,
                        style: {
                          "flex-wrap": "nowrap",
                          "line-height": "1.5em"
                        }
                      }, {
                        default: _withCtx(() => [_createVNode(_component_el_icon, {
                          style: {
                            "margin-right": "10px",
                            "height": "1.5em",
                            "color": "var(--el-color-primary)"
                          }
                        }, {
                          default: _withCtx(() => [_createVNode(_unref(Right))]),
                          _: 1
                        }), _createElementVNode("span", {
                          innerHTML: detail
                        }, null, 8, _hoisted_19)]),
                        _: 2
                      }, 1024);
                    }), 128))])]),
                    _: 2
                  }, 1024)]);
                }), 128))]),
                _: 1
              })]),
              _: 1
            })) : _createCommentVNode("", true), _createVNode(_component_el_row, {
              align: "middle",
              justify: "center"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_col, {
                style: {
                  "max-width": "75%"
                }
              }, {
                default: _withCtx(() => [_createVNode(_component_el_row, {
                  justify: "center",
                  class: "terms-and-conditions"
                }, {
                  default: _withCtx(() => [priceConditional.value ? (_openBlock(), _createElementBlock("div", _hoisted_20, " * " + _toDisplayString(priceConditional.value), 1)) : _createCommentVNode("", true), TnC.value ? (_openBlock(), _createElementBlock("div", _hoisted_21, " TERMS AND CONDITIONS: " + _toDisplayString(TnC.value), 1)) : _createCommentVNode("", true), Notes.value ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    innerHTML: Notes.value
                  }, null, 8, _hoisted_22)) : _createCommentVNode("", true)]),
                  _: 1
                })]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["data-testid"])])], 64);
    };
  }
};