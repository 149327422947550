import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, unref as _unref, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["disabled"];
import { reactive } from "vue";
import newsletter from '@/composables/newsletter.js';
import { validateEmail } from "@/helpers";
export default {
  __name: 'WpNewsletterForm',
  props: {
    submitOnce: Boolean
  },
  setup(__props) {
    const props = __props;
    const form = reactive({
      email: "",
      fax: false
    });
    const state = reactive({
      loading: false,
      submitted: false,
      onList: false
    });
    const submitNewsletter = async () => {
      const res = await newsletter.handleSubmit(form, state, {}, props.submitOnce);
      if (res === 400) {
        state.onList = true;
      }
    };
    return (_ctx, _cache) => {
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_col = _resolveComponent("el-col");
      return _openBlock(), _createBlock(_component_el_col, {
        class: "flex-col"
      }, {
        default: _withCtx(() => [__props.submitOnce && (state.submitted || state.onList) ? (_openBlock(), _createBlock(_component_el_row, {
          key: 0,
          align: "middle",
          justify: "center",
          class: "std-padding_line-sm"
        }, {
          default: _withCtx(() => [state.onList ? _renderSlot(_ctx.$slots, "onList", {
            key: 0
          }, () => [_cache[4] || (_cache[4] = _createTextVNode(" It looks like you're already receiving the Auto-Tune newsletter. "))]) : _renderSlot(_ctx.$slots, "submitted", {
            key: 1
          }, () => [_cache[5] || (_cache[5] = _createTextVNode(" You're on the list. Please check your inbox to verify your email. "))])]),
          _: 3
        })) : (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [_createVNode(_component_el_row, {
          align: "middle",
          justify: "center",
          class: "std-padding_line-sm"
        }, {
          default: _withCtx(() => [_renderSlot(_ctx.$slots, "form", {}, () => [_cache[6] || (_cache[6] = _createTextVNode(" Join our Newsletter "))])]),
          _: 3
        }), _createVNode(_component_el_row, {
          align: "middle",
          justify: "center",
          class: "std-padding_line-sm"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form, {
            modelValue: form,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => form = $event),
            style: {
              "max-width": "400px",
              "flex": "0 0 100%"
            },
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              style: {
                "margin-bottom": "9px"
              }
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                placeholder: "Your Email Address",
                modelValue: form.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.email = $event),
                disabled: state.loading,
                "input-style": {
                  'text-align': 'center'
                },
                onFocus: _unref(newsletter).handleEmailFieldFocusEvent
              }, null, 8, ["modelValue", "disabled", "onFocus"])]),
              _: 1
            }), _createVNode(_component_el_form_item, {
              style: {
                "display": "none"
              },
              label: "Contact me by fax"
            }, {
              default: _withCtx(() => [_withDirectives(_createElementVNode("input", {
                type: "checkbox",
                name: "fax",
                label: "Contact me by fax",
                disabled: state.loading,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form.fax = $event)
              }, null, 8, _hoisted_1), [[_vModelCheckbox, form.fax]])]),
              _: 1
            }), _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [_createVNode(_component_el_button, {
                style: {
                  "width": "100%"
                },
                type: "primary",
                onClick: submitNewsletter,
                disabled: state.loading || !_unref(validateEmail)(form.email)
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode(" Sign Up ")])),
                _: 1
              }, 8, ["disabled"])]),
              _: 1
            })]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        })], 64))]),
        _: 3
      });
    };
  }
};