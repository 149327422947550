import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache) {
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_alert = _resolveComponent("el-alert");
  return _openBlock(), _createBlock(_component_el_alert, {
    type: "error",
    center: false,
    "show-icon": "",
    effect: "dark",
    closable: false,
    style: {
      "text-align": "left",
      "font-weight": "bold",
      "color": "#FFFFFF",
      "max-width": "50%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 24,
      style: {
        "margin-left": "12px"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_row, {
        align: "middle",
        justify: "start",
        style: {
          "color": "#ffffff",
          "font-weight": "bold"
        },
        class: "std-padding_line-sm"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode(" Error ")])),
        _: 1
      }), _createVNode(_component_el_row, {
        align: "middle",
        justify: "start",
        style: {
          "padding-bottom": "12px",
          "color": "#ffffff",
          "font-weight": "normal",
          "line-height": "1.3em"
        }
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 3
      })]),
      _: 3
    })]),
    _: 3
  });
}