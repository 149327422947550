import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "video-container aspect-ratio-16-9"
};
const _hoisted_2 = ["src", "type"];
const _hoisted_3 = ["src", "type"];
import { ref, reactive } from 'vue';
import { CloseBold } from '@element-plus/icons-vue';
import * as modalOps from "@/composables/modal.js";
import { parseVideoFormat } from '@/helpers';
export default {
  __name: 'VideoModal',
  setup(__props, {
    expose: __expose
  }) {
    const player = ref(null);
    const state = reactive({
      videoDialogVisible: false,
      videoDialogUrls: [],
      videoDialogTitle: null,
      videoReload: false
    });

    /**
     * Open the video modal with the provided video object containing its title and url.
     * @param {object} { title: video title, url: video url }
     */
    const handleVideoDialogOpen = ({
      title,
      url
    }) => {
      state.videoReload = true; // Force Vue to reload iframe to make YT pull up new HQ Thumbnail
      state.videoDialogTitle = title;
      state.videoDialogUrls = url;
      modalOps.registerModal('video');
      setTimeout(() => {
        state.videoReload = false;
        state.videoDialogVisible = true;
      }, 200);
    };

    /**
     * Close the video modal.
     * 
     * Resets the modal's internal state for the next use.
     */
    const handleVideoDialogClose = () => {
      player.value.pause();
      state.videoDialogVisible = false;
      modalOps.unregisterModal('video');
      setTimeout(() => {
        state.videoDialogTitle = null;
        state.videoDialogUrls = [];
      }, 200);
    };
    __expose({
      'openVideoModal': handleVideoDialogOpen,
      'closeVideoModal': handleVideoDialogClose
    });
    return (_ctx, _cache) => {
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createBlock(_component_el_dialog, {
        class: "antares-dialog video-dialog",
        showClose: false,
        modelValue: state.videoDialogVisible,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => state.videoDialogVisible = $event),
        "destroy-on-close": "",
        "before-close": handleVideoDialogClose
      }, {
        default: _withCtx(() => [_createVNode(_component_el_row, {
          type: "flex",
          align: "middle",
          justify: "center",
          style: {
            "padding-top": "30px"
          }
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [!state.videoReload ? (_openBlock(), _createElementBlock("video", {
            key: 0,
            onloadstart: "this.volume=0.5",
            class: "fill-container",
            preload: "metadata",
            ref_key: "player",
            ref: player,
            playsinline: "",
            autoplay: "",
            controls: ""
          }, [typeof state.videoDialogUrls === 'string' ? (_openBlock(), _createElementBlock("source", {
            key: 0,
            src: state.videoDialogUrls,
            type: _unref(parseVideoFormat)(state.videoDialogUrls)
          }, null, 8, _hoisted_2)) : (_openBlock(true), _createElementBlock(_Fragment, {
            key: 1
          }, _renderList(state.videoDialogUrls, (url, index) => {
            return _openBlock(), _createElementBlock("source", {
              key: index,
              src: url,
              type: _unref(parseVideoFormat)(url)
            }, null, 8, _hoisted_3);
          }), 128)), _cache[2] || (_cache[2] = _createTextVNode(" Your browser does not support the video tag. "))], 512)) : _createCommentVNode("", true)])]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          class: "close-button",
          circle: "",
          plain: "",
          icon: _unref(CloseBold),
          onClick: _cache[0] || (_cache[0] = $event => handleVideoDialogClose())
        }, null, 8, ["icon"])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
};