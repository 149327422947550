import { useCssVars as _useCssVars, unref as _unref } from 'vue';
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed } from 'vue';
export default {
  __name: 'Banner',
  props: {
    height: {
      type: String,
      required: false,
      default: 'none',
      validator(v) {
        return ['full', 'three-quarters', 'half', 'quarter', 'none'].includes(v.toLowerCase());
      }
    },
    align: {
      type: String,
      required: false,
      default: 'top',
      validator(v) {
        return ['top', 'middle', 'bottom'].includes(v.toLowerCase());
      }
    },
    justify: {
      type: String,
      required: false,
      default: 'start',
      validator(v) {
        return ['start', 'end', 'center', 'space-around', 'space-between', 'space-evenly'].includes(v.toLowerCase());
      }
    },
    direction: {
      type: String,
      required: false,
      default: 'horizontal',
      validator(v) {
        return ['horizontal', 'vertical'].includes(v.toLowerCase());
      }
    }
  },
  setup(__props) {
    _useCssVars(_ctx => ({
      "c5ce1a36": justifyType.value,
      "c2a840cc": alignType.value
    }));
    const props = __props;

    // Computed properties

    const heightClass = computed(() => {
      switch (props.height.toLowerCase()) {
        case 'full':
          return 'viewport-min-height-minus-navbar';
        case 'three-quarters':
          return 'viewport-min-height-minus-navbar__three-quarters';
        case 'half':
          return 'viewport-min-height-minus-navbar__half';
        case 'quarter':
          return 'viewport-min-height-minus-navbar__quarter';
        default:
          return null;
      }
    });
    const justifyType = computed(() => {
      switch (props.justify.toLowerCase()) {
        case 'start':
          return 'flex-start';
        case 'end':
          return 'flex-end';
        default:
          return props.justify;
      }
    });
    const alignType = computed(() => {
      switch (props.align.toLowerCase()) {
        case 'top':
          return 'flex-start';
        case 'middle':
          return 'center';
        case 'bottom':
          return 'flex-end';
      }
      return null;
    });
    return (_ctx, _cache) => {
      const _component_el_container = _resolveComponent("el-container");
      return _openBlock(), _createBlock(_component_el_container, {
        class: _normalizeClass(["default-banner", heightClass.value]),
        direction: __props.direction.toLowerCase()
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 3
      }, 8, ["direction", "class"]);
    };
  }
};