import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue";
import { h, reactive, getCurrentInstance, computed, onMounted, shallowRef, markRaw, defineAsyncComponent, watch, mergeProps } from "vue";
import WaveSpinner from "@/components/WaveSpinner";
import ErrorAlert from '@/components/Error/ErrorAlert.vue';
export default {
  __name: 'RuntimeTemplate',
  props: {
    template: {
      type: String,
      required: true
    },
    parent: Object,
    templateProps: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['rendered'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    let dynamicComp = shallowRef(null);
    const state = reactive({
      loading: true,
      error: false,
      WpTags: [],
      auxComponents: {}
    });
    const template = computed(() => {
      return props.template;
    });
    const parent = computed(() => {
      return props.parent || getCurrentInstance().parent;
    });
    const parentComponents = computed(() => {
      const res = {};
      const parentScope = parent.value?.setupState;
      if (parentScope) {
        Object.getOwnPropertyNames(parentScope).forEach(key => {
          if (parentScope[key]?.render && parentScope[key] !== parent.value && key !== "RuntimeTemplate") {
            res[key] = Object.getOwnPropertyDescriptor(parentScope, key);
          }
        });
      }
      return res;
    });
    const dirComponents = require.context('@/components/WpComponents', true, /Wp\w+\.vue/);
    const WpComponents = [];

    // Define async Components to lazy load components
    dirComponents.keys().forEach(key => {
      let filename = key.split("/");
      filename = filename[filename.length - 1].replace(".vue", "");
      if (!WpComponents[filename]) {
        WpComponents[filename] = markRaw(defineAsyncComponent(async () => {
          return dirComponents(key);
        }));
      } else {
        console.warn(`${filename} aleady registered; skipping`);
      }
    });
    const parentData = computed(() => {
      const res = {};
      const parentScope = parent.value?.setupState;
      if (parentScope) {
        const componentKeys = Object.getOwnPropertyNames(parentComponents.value);
        Object.getOwnPropertyNames(parentScope).forEach(key => {
          if (key !== "RuntimeTemplate" && !componentKeys.includes(key)) {
            res[key] = parentScope[key];
          }
        });
      }
      return res;
    });
    const finalProps = computed(() => {
      return mergeProps(parent.value.props, parentData.value, props.templateProps);
    });
    const updateRuntimeTemplate = async () => {
      state.loading = true;
      state.WpTags = [];
      state.auxComponents = {};

      // Cannot use +/- look-behind as it is not supported in Safari (https://caniuse.com/js-regexp-lookbehind); will need to remove "<" prefix 
      const regexPattern = /<wp-[a-zA-Z]+(?:-[a-zA-Z]+)*/g; // search for wp- tagged elements; i.e wp-faq-container, wp-el-button.

      if (!template.value) {
        console.error("Empty template was passed in. Please double check source is valid");
        state.error = true;
        state.loading = false;
        return;
      }
      const matches = template.value.match(regexPattern);
      if (matches && matches.length) {
        state.WpTags = matches.filter((value, index, array) => {
          return array.indexOf(value) === index;
        });
        state.WpTags = state.WpTags.map(elem => elem.slice(1));
      }
      const globalComponents = Object.getOwnPropertyNames(parent.value?.appContext.components);
      const parentCompKeys = Object.keys(parentComponents.value);
      for (let index in state.WpTags) {
        let tag = state.WpTags[index];

        // Skip if component is in global scope
        if (globalComponents.includes(tag) || typeof tag !== 'string') {
          continue;
        }

        // Convert kebab-case tag into PascalCase

        tag = tag.charAt(0).toUpperCase() + tag.slice(1);
        tag = tag.replaceAll(/-[a-zA-Z]/g, match => {
          return match.toUpperCase().replace("-", "");
        });

        // Skip if component already exists in inherited parent context
        if (parentCompKeys.includes(tag)) {
          continue;
        }
        state.auxComponents[tag] = WpComponents[tag];
        if (!state.auxComponents[tag]) {
          console.warn(`Element "${tag}" does not exist`);
          state.auxComponents[tag] = h("div");
        }
      }
      const dynamic = {
        template: template.value || "<div/>",
        props: Object.keys(finalProps.value),
        computed: {},
        components: {
          ...state.auxComponents,
          ...parentComponents.value
        },
        provide: {},
        setup() {
          onMounted(() => {
            emit('rendered');
          });
        }
      };
      dynamicComp.value = h(dynamic, {
        ...finalProps.value
      });
      state.loading = false;
    };
    onMounted(async () => {
      updateRuntimeTemplate();
      await import("../../styles/blog-post.scss");
    });
    watch(template, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        updateRuntimeTemplate();
      }
    });
    return (_ctx, _cache) => {
      const _component_el_row = _resolveComponent("el-row");
      return state.loading ? (_openBlock(), _createBlock(_component_el_row, {
        key: 0,
        align: "middle",
        justify: "center",
        class: "viewport-height-minus-navbar"
      }, {
        default: _withCtx(() => [_createVNode(_unref(WaveSpinner))]),
        _: 1
      })) : state.error || !_unref(dynamicComp) ? (_openBlock(), _createBlock(_component_el_row, {
        key: 1,
        align: "middle",
        justify: "center",
        class: "viewport-height-minus-navbar"
      }, {
        default: _withCtx(() => [_createVNode(ErrorAlert, null, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode(" There was an error retrieving the page. Please try again later. "), _createElementVNode("br", null, null, -1), _createTextVNode(" We apologize for the inconvenience. ")])),
          _: 1
        })]),
        _: 1
      })) : (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(dynamicComp)), {
        key: 2
      }));
    };
  }
};