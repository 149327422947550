import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, unref as _unref } from "vue";
const _hoisted_1 = {
  class: "std-padding_line-sm"
};
const _hoisted_2 = {
  notranslate: ""
};
const _hoisted_3 = {
  class: "std-padding_line-sm"
};
const _hoisted_4 = ["innerHTML"];
const _hoisted_5 = ["innerHTML"];
import { computed, provide } from "vue";
import { useStore } from "vuex";
import { ElDialog } from 'element-plus';
import { getFsDataObj } from "../helpers";
import WpElButton from './WpComponents/WpElButton.vue';
export default {
  __name: 'UpgradeDialog',
  setup(__props) {
    provide('callback', () => dialogVisible.value = false);
    const store = useStore();
    const fsData = computed(() => store.getters.getFsData);
    const upgradeDialog = computed(() => store.getters.getCartDialog);
    const dialogVisible = computed({
      get() {
        return upgradeDialog.value.visible;
      },
      set() {
        store.commit("unsetCartDialog");
      }
    });

    /**
     * Returns the ATU subscription object
     * @returns {object}
     */
    const sub = computed(() => {
      const {
        data
      } = upgradeDialog.value;
      const atu = getFsDataObj(fsData.value, "auto-tune-unlimited-monthly");
      if (!(data.path && Array.isArray(atu?.included_products) && atu.included_products.includes(upgradeDialog.value.data.path))) {
        return null;
      }
      return atu;
    });

    /**
     * Trims the term from the subscription name
     * @param {string} name 
     * @returns {string}
     */
    const formatSubName = name => {
      return name.replace("Annual", "").replace("Monthly", "").trim();
    };
    return (_ctx, _cache) => {
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_router_link = _resolveComponent("router-link");
      return _openBlock(), _createBlock(_unref(ElDialog), {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => dialogVisible.value = $event),
        class: "upgrade-dialog"
      }, {
        default: _withCtx(() => [upgradeDialog.value.type === 'unqualified' ? (_openBlock(), _createBlock(_component_el_col, {
          key: 0
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            class: "std-padding_line-lg"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_col, null, {
              default: _withCtx(() => [_cache[4] || (_cache[4] = _createElementVNode("div", {
                class: "std-padding_line-sm"
              }, [_createElementVNode("p", null, " Our records indicate you do not own a qualifying product. ")], -1)), _createElementVNode("div", _hoisted_1, [_createElementVNode("p", null, [_createElementVNode("b", _hoisted_2, _toDisplayString(upgradeDialog.value.data.name), 1), _cache[3] || (_cache[3] = _createTextVNode(" is available as a Perpetual license ")), sub.value ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 0
              }, [_createTextVNode("or as part of the " + _toDisplayString(formatSubName(sub.value.name)) + " subscription", 1)], 64)) : _createCommentVNode("", true)])]), _cache[5] || (_cache[5] = _createElementVNode("div", null, [_createElementVNode("p", null, [_createTextVNode(" Please select an option below. If you believe this to be an error, please contact support "), _createElementVNode("a", {
                style: {
                  "color": "var(--el-color-primary-dark-2)"
                },
                href: "https://antaresaudiotechnologies.my.site.com/s/contactsupport",
                target: "_blank"
              }, "here.")])], -1))]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_row, {
            class: "std-padding_line-sm"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              class: "flex-col flex-centered std-padding_line-md",
              md: sub.value ? 12 : 24
            }, {
              default: _withCtx(() => [_createVNode(WpElButton, {
                type: "primary",
                size: "large",
                action: "add_to_cart",
                value: upgradeDialog.value.data.path
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(upgradeDialog.value.data.name), 1)]),
                _: 1
              }, 8, ["value"]), _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)), _createVNode(_component_router_link, {
                to: upgradeDialog.value.data.url,
                onClick: _cache[0] || (_cache[0] = () => dialogVisible.value = false)
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [_createElementVNode("b", null, "Learn More", -1)])),
                _: 1
              }, 8, ["to"])]),
              _: 1
            }, 8, ["md"]), sub.value ? (_openBlock(), _createBlock(_component_el_col, {
              key: 0,
              class: "flex-col flex-centered std-padding_line-md",
              md: 12
            }, {
              default: _withCtx(() => [_createVNode(WpElButton, {
                type: "primary",
                size: "large",
                action: "add_to_cart",
                value: sub.value.path
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(formatSubName(sub.value.name)), 1)]),
                _: 1
              }, 8, ["value"]), _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)), _createVNode(_component_router_link, {
                to: sub.value.url,
                onClick: _cache[1] || (_cache[1] = () => dialogVisible.value = false)
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [_createElementVNode("b", null, "Learn More", -1)])),
                _: 1
              }, 8, ["to"])]),
              _: 1
            })) : _createCommentVNode("", true)]),
            _: 1
          })]),
          _: 1
        })) : upgradeDialog.value.type === 'owned' ? (_openBlock(), _createBlock(_component_el_col, {
          key: 1
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            class: "std-padding_line-sm"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_col, null, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createElementVNode("p", null, [_cache[10] || (_cache[10] = _createTextVNode(" Our records indicate that you already own a Perpetual license to ")), _createElementVNode("span", {
                innerHTML: upgradeDialog.value.data?.name ? `<b notranslate>${upgradeDialog.value.data.name}</b>` : 'our Product'
              }, null, 8, _hoisted_4), _cache[11] || (_cache[11] = _createTextVNode(". "))])]), _createElementVNode("div", null, [_createElementVNode("p", null, [_cache[13] || (_cache[13] = _createTextVNode(" If you would like to update to the latest version of ")), _createElementVNode("span", {
                innerHTML: upgradeDialog.value.data?.name ? `<b notranslate>${upgradeDialog.value.data.name}</b>` : 'this Product'
              }, null, 8, _hoisted_5), _cache[14] || (_cache[14] = _createTextVNode(", please download and install ")), _createVNode(_component_router_link, {
                to: "/software-download"
              }, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [_createElementVNode("b", {
                  style: {
                    "color": "var(--el-color-primary-dark-2)"
                  }
                }, "Auto-Tune Central", -1)])),
                _: 1
              }), _cache[15] || (_cache[15] = _createTextVNode(". "))])])]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_row, {
            class: "flex-centered"
          }, {
            default: _withCtx(() => [_createVNode(WpElButton, {
              type: "primary",
              action: "go_to",
              value: "/software-download"
            }, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [_createTextVNode("Download Auto-Tune Central")])),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
};