import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "user-menu"
};
import { inject } from 'vue';
import { useRoute } from 'vue-router';
import { ShoppingCart, ShoppingCartFull } from '@element-plus/icons-vue';
import UserMenu from '@/components/UserMenu.vue';
import { useFsLoading } from '@/classes/FastSpring';
export default {
  __name: 'NavBarOptions',
  setup(__props) {
    const user = inject('user');
    const cart = inject('cart');
    const openAuthModal = inject('openAuthModal', () => {});
    const route = useRoute();
    const fsLoading = useFsLoading();
    const cartToggle = () => {
      if (!fsLoading.value) {
        cart.toggleVisibility();
      }
    };
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_badge = _resolveComponent("el-badge");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_unref(user) && _unref(user).id ? (_openBlock(), _createBlock(UserMenu, {
        key: 0
      })) : (_openBlock(), _createBlock(_component_el_row, {
        key: 1,
        align: "middle",
        justify: "center"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          type: "primary",
          plain: "",
          class: "gray-base",
          onClick: _cache[0] || (_cache[0] = $event => _unref(openAuthModal)()),
          "data-testid": "signInButton"
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("Sign In")])),
          _: 1
        })]),
        _: 1
      }))]), _unref(route).name !== 'Checkout' ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        onClick: _cache[1] || (_cache[1] = () => cartToggle()),
        style: _normalizeStyle({
          cursor: _unref(fsLoading) ? 'default' : 'pointer'
        }),
        "data-testid": "showCartButton"
      }, [_unref(cart).totalItems && typeof _unref(cart).totalItems === 'number' && _unref(cart).totalItems > 0 ? (_openBlock(), _createBlock(_component_el_badge, {
        key: 0,
        type: "primary",
        value: _unref(cart).totalItems
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          disabled: _unref(fsLoading),
          loading: _unref(fsLoading),
          class: "gray-base",
          size: "large",
          type: "primary",
          plain: "",
          icon: _unref(ShoppingCartFull),
          circle: ""
        }, null, 8, ["disabled", "loading", "icon"])]),
        _: 1
      }, 8, ["value"])) : (_openBlock(), _createBlock(_component_el_button, {
        key: 1,
        disabled: _unref(fsLoading),
        loading: _unref(fsLoading),
        class: "gray-base",
        size: "large",
        type: "primary",
        plain: "",
        icon: _unref(ShoppingCart),
        circle: ""
      }, null, 8, ["disabled", "loading", "icon"]))], 4)) : _createCommentVNode("", true)], 64);
    };
  }
};