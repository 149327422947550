import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  "data-testid": "productName"
};
const _hoisted_2 = {
  style: {
    fontSize: 'var(--el-font-size-extra-small)'
  },
  class: "std-padding_line-sm",
  "data-testid": "productType"
};
const _hoisted_3 = {
  style: {
    fontSize: 'var(--el-font-size-extra-small)'
  },
  class: "std-padding_line-sm",
  "data-testid": "checkoutInfo"
};
const _hoisted_4 = {
  "data-testid": "narrowProductName"
};
const _hoisted_5 = {
  style: {
    fontSize: 'var(--el-font-size-extra-small)'
  },
  "data-testid": "narrowProductType"
};
const _hoisted_6 = {
  class: "flex-col",
  style: {
    "padding-right": "12px",
    "font-size": "var(--el-font-size-small)"
  }
};
const _hoisted_7 = {
  key: 0,
  notranslate: "",
  "data-testid": "productPrice"
};
const _hoisted_8 = {
  class: "flex-col"
};
import { ref, computed, onMounted, nextTick } from 'vue';
import { formatToCurrency } from '@/helpers';
import { Delete, Picture } from "@element-plus/icons-vue";
import Product from '../classes/Product';
export default {
  __name: 'CartProduct',
  props: {
    cartProduct: {
      type: Object,
      required: true
    },
    isUpgrade: {
      'type': Boolean,
      'required': false,
      'default': false
    }
  },
  emits: ['setQuantity', 'rmUpgrade'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    let showWarning = ref(false);

    // Computed

    /**
     * Returns a formatted string displaying the product's price or rate depending on whether it is a perpetual license or
     * a subscription.
     * @returns {string}
     */
    let price = computed(() => {
      const {
        currencyFormattedFinalPrice,
        isTrial,
        isSubscription
      } = props.cartProduct;
      if (!currencyFormattedFinalPrice) {
        return null;
      }
      if (isTrial) {
        return null;
      }
      if (!isSubscription) {
        return currencyFormattedFinalPrice;
      }
      return `${currencyFormattedFinalPrice}/${subIntervalUnit.value}`;
    });

    /**
     * Returns the subscription interval unit, if applicable.
     * @returns {string|null}
     */
    const subIntervalUnit = computed(() => {
      const {
        cartProduct
      } = props;
      if (!cartProduct.sub_interval_unit) {
        if (cartProduct.path.includes('annual')) {
          return 'year';
        }
        if (cartProduct.path.includes('monthly')) {
          return 'month';
        }
        return null;
      }
      switch (cartProduct.sub_interval_unit) {
        case 'd':
          return 'day';
        case 'w':
          return 'week';
        case 'm':
          return 'month';
        case 'y':
          return 'year';
        default:
          return null;
      }
    });

    /**
     * Returns a boolean indicating whether this is a wpro freebie
     * @returns {boolean}
     */
    const isWproFreebie = computed(() => {
      return props.cartProduct.is_freebie && props.cartProduct.path.includes('-mo-wpro');
    });

    /**
     * Returns the product's license type, i.e. "Subscription" or "Perpetual License".
     * @returns {string}
     */
    const productType = computed(() => {
      const {
        cartProduct
      } = props;
      if (!cartProduct.type) {
        return null;
      }
      let type = '';
      if (cartProduct.isSubscription) {
        type = 'Subscription';
        if (cartProduct.sub_interval_unit === 'y') {
          type = `Annual ${type}`;
        } else if (cartProduct.sub_interval_unit === 'm') {
          type = `Monthly ${type}`;
        }
      } else if (cartProduct.isPerpetual) {
        type = 'Perpetual License';
      }
      return type;
    });

    /**
     * Returns the checkout info text. If the product sku is not a wpro subscription sku, defaults to returning data.checkout_info or null.
     * @returns {string|null}
     */
    const checkoutInfo = computed(() => {
      const {
        cartProduct
      } = props;
      if (!isWproFreebie.value) {
        return cartProduct.checkout_info || null;
      }
      const term = cartProduct.path[cartProduct.path.indexOf('-mo-wpro') - 1];
      if (isNaN(term)) {
        return cartProduct.checkout_info || null; // bail if term cannot be parsed from sku
      }
      let rolloverSku = null;
      try {
        rolloverSku = new Product('auto-tune-unlimited-monthly-rollover');
      } catch (e) {
        return null;
      }
      const price = `${formatToCurrency(rolloverSku.productFinalPrice)}/month`;
      const monthText = term > 1 ? 'months' : 'month';
      return `Our gift to you: ${term} ${monthText} FREE to explore all 23 plug-ins! Renews at ${price}, after ${term} ${monthText}. Cancel any time. Click 'Remove' to decline your special offer.`;
    });

    // Methods

    /**
     * Handles changing the product quantity. If the quantity change is from 1 to 0, a warning
     * is displayed to the user allowing them to remove the item completely or keep it with a quantity
     * of one.
     * @param {number} qty
     * @param {boolean} ackWarning
     */
    const handleQtyChange = (qty, ackWarning = false) => {
      if (qty === 0 && !ackWarning) {
        showWarning.value = true;
        return;
      } else {
        showWarning.value = false;
        nextTick(() => disableNumberInput());
      }
      emit('setQuantity', {
        qty,
        path: props.cartProduct.path
      });
    };

    /**
     * Prevents the user from manually typing in a quantity
     */
    const disableNumberInput = () => {
      var qtySelector = document.getElementById(`qtyInput__${props.cartProduct.path}`);
      var input = null;
      if (qtySelector) {
        input = qtySelector.querySelectorAll('input');
      }
      if (input && input[0]) {
        input[0].setAttribute('disabled', 'true');
      }
    };
    onMounted(() => {
      disableNumberInput();
    });
    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_input_number = _resolveComponent("el-input-number");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_button_group = _resolveComponent("el-button-group");
      return _openBlock(), _createBlock(_component_el_row, {
        justify: "space-between",
        align: "middle",
        class: "product fullheight",
        "data-testid": "cartProduct",
        style: {
          "margin": "0"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 16,
          class: "thumbnail hidden-sm-and-down"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            align: "middle",
            justify: "start"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 8,
              style: {
                "max-width": "140px",
                "padding-right": "10px"
              }
            }, {
              default: _withCtx(() => [_createVNode(_component_el_image, {
                src: __props.cartProduct.thumbnail,
                fit: "cover"
              }, {
                error: _withCtx(() => [_createVNode(_component_el_icon, {
                  size: 30
                }, {
                  default: _withCtx(() => [_createVNode(_unref(Picture))]),
                  _: 1
                })]),
                _: 1
              }, 8, ["src"])]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 16
            }, {
              default: _withCtx(() => [_createVNode(_component_el_row, {
                align: "middle",
                justify: "start",
                notranslate: ""
              }, {
                default: _withCtx(() => [_createElementVNode("h4", _hoisted_1, _toDisplayString(__props.cartProduct.name), 1)]),
                _: 1
              }), productType.value ? (_openBlock(), _createBlock(_component_el_row, {
                key: 0,
                align: "middle",
                justify: "start"
              }, {
                default: _withCtx(() => [_createElementVNode("p", _hoisted_2, _toDisplayString(productType.value), 1)]),
                _: 1
              })) : _createCommentVNode("", true), checkoutInfo.value ? (_openBlock(), _createBlock(_component_el_row, {
                key: 1,
                align: "middle",
                justify: "start"
              }, {
                default: _withCtx(() => [_createElementVNode("p", _hoisted_3, _toDisplayString(checkoutInfo.value), 1)]),
                _: 1
              })) : _createCommentVNode("", true)]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_col, {
          xs: {
            span: 12
          },
          sm: {
            span: 16
          },
          md: {
            span: 0
          },
          lg: {
            span: 0
          },
          xl: {
            span: 0
          },
          style: {
            "padding": "0"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            align: "middle",
            justify: "start",
            notranslate: ""
          }, {
            default: _withCtx(() => [_createElementVNode("h3", _hoisted_4, _toDisplayString(__props.cartProduct.name), 1)]),
            _: 1
          }), productType.value ? (_openBlock(), _createBlock(_component_el_row, {
            key: 0,
            align: "middle",
            justify: "start"
          }, {
            default: _withCtx(() => [_createElementVNode("p", _hoisted_5, _toDisplayString(productType.value), 1)]),
            _: 1
          })) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_col, {
          xs: {
            span: 12
          },
          sm: {
            span: 8
          },
          md: {
            span: 8
          },
          lg: {
            span: 8
          },
          xl: {
            span: 8
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            class: "fullheight",
            align: "middle",
            justify: "end"
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_unref(price) ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(price)), 1)) : _createCommentVNode("", true), !__props.cartProduct.wp_only && (__props.isUpgrade || __props.cartProduct.prm || !__props.cartProduct.isPerpetual) ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: "flex-row flex-end remove-item",
              onClick: _cache[0] || (_cache[0] = () => __props.isUpgrade ? emit('rmUpgrade', __props.cartProduct) : handleQtyChange(0, true)),
              "data-testid": "removeButton"
            }, " Remove ")) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_8, [!__props.isUpgrade && !__props.cartProduct.prm && !__props.cartProduct.isSubscription && !__props.cartProduct.isTrial ? (_openBlock(), _createElementBlock(_Fragment, {
              key: 0
            }, [!_unref(showWarning) ? (_openBlock(), _createBlock(_component_el_input_number, {
              key: 0,
              id: `qtyInput__${__props.cartProduct.path}`,
              min: 0,
              size: "small",
              modelValue: __props.cartProduct.quantity,
              class: "quantity",
              onChange: _cache[1] || (_cache[1] = q => handleQtyChange(q)),
              "data-testid": "productQuantity"
            }, null, 8, ["id", "modelValue"])) : (_openBlock(), _createBlock(_component_el_button_group, {
              key: 1
            }, {
              default: _withCtx(() => [_createVNode(_component_el_button, {
                size: "small",
                type: "danger",
                icon: _unref(Delete),
                "data-testid": "deleteButton",
                onClick: _cache[2] || (_cache[2] = () => handleQtyChange(0, true))
              }, null, 8, ["icon"]), _createVNode(_component_el_button, {
                size: "small",
                type: "primary",
                "data-testid": "keepButton",
                onClick: _cache[3] || (_cache[3] = () => handleQtyChange(1))
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("Keep")])),
                _: 1
              })]),
              _: 1
            }))], 64)) : _createCommentVNode("", true)])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      });
    };
  }
};