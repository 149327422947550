const cloneDeep = require("lodash/cloneDeep.js");
const source = require("./source.js"); // Source Routes Object in English; excluding PathNotFound
const langs = require("../../langs.json"); // Enabled Language Sub Dirs
const helpers = require("../helpers.js");

/**
 * Dictionary to translate Source routes into appropriate language, within certain restraints.
 * Translations are stored & accessed by the phrase and the target language in their ISO 639-1 with potential ISO 3166 code in the format <ISO 639-1>[-<ISO 3166>]
 * 
 * Dynamic slugs are handled via slugs.json retrieved in helper file
 */
const dictionary = require("./dictionary.json");

/**
 * Translate the path according to the appropriate language.
 * 
 * @param {String} source 
 * @param {String} lang 
 * @returns String
 */
const translatePath = (source, lang) => {
    if (!source) return source;
    if (source === "/") return `/${lang}`;

    const lookup = (source, lang) => {
        const entry = dictionary[source];

        return entry ? entry[lang] || source : source;
    }

    if (!source.includes("/")) {
        return lookup(source, lang);
    }

    const parts = source.split("/");

    return parts.map((part) => {
        return lookup(part, lang);
    }).join("/");
}

/**
 * Adjust the Name and Path of the node and its children for the current language sub directory.
 * 
 * Returns early on "en" as Source is already in English
 * 
 * @param {Object} routeNode 
 * @param {String} lang 
 * @returns 
 */
const adjustRouteNode = (routeNode, lang = "en") => {
    const res = { ...routeNode };

    if (lang === "en") return res; // No adjustments needed; Source should be in English

    if (res.name) res.name = `${res.name}-lang_${lang}`;

    if (routeNode.name === "Root") res.meta = { ...res.meta, lang };

    res.path = translatePath(res.path, lang);

    if (res.meta?.slugKey) {
        const langSlugs = helpers.getLangSlugs(res.meta.slugKey, lang);
        
        res.meta.enDictionary = helpers.makeEnDictionary(res.meta.slugKey, lang);

        if (res.meta.sitemap?.slugs?.includes("")) {
            langSlugs.unshift("");
        }

        res.meta.sitemap.slugs = langSlugs;
    }

    if (res?.children) {
        res.children = adjustRoutes(res.children, lang);
    }

    return res;
}

/**
 * Adjust the Source (sub)tree for a new language subdirectory
 * 
 * @param {Array} routes 
 * @param {String} lang 
 * @returns Array
 */
const adjustRoutes = (routes, lang = "en") => {
    return routes.map(route => adjustRouteNode(route, lang));
}

// Map and flatten the languages array for export as final generated routing object
module.exports = [
    ...langs.map((lang) => adjustRoutes(cloneDeep(source), lang)).flat()
]