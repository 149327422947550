import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  class: "flex-col ug-col",
  style: {
    "width": "80%"
  }
};
const _hoisted_2 = {
  class: "flex-row flex-centered",
  style: {
    "width": "100%"
  }
};
const _hoisted_3 = {
  class: "flex-col"
};
const _hoisted_4 = {
  class: "flex-row",
  style: {
    "padding-bottom": "12px"
  }
};
const _hoisted_5 = {
  class: "image-slot"
};
const _hoisted_6 = {
  class: "flex-row flex-centered",
  style: {
    "font-weight": "500",
    "font-size": "18px",
    "color": "#05bbb8",
    "padding-bottom": "12px"
  },
  notranslate: ""
};
const _hoisted_7 = {
  class: "flex-col",
  style: {
    "min-width": "100px",
    "width": "15%"
  }
};
const _hoisted_8 = {
  class: "flex-row flex-centered",
  style: {
    "font-weight": "bold",
    "font-size": "42px"
  }
};
const _hoisted_9 = {
  class: "flex-col",
  style: {
    "align-items": "center",
    "justify-content": "center"
  }
};
const _hoisted_10 = {
  class: "flex-row",
  style: {
    "padding-bottom": "12px"
  }
};
const _hoisted_11 = {
  class: "image-slot"
};
const _hoisted_12 = {
  class: "flex-row flex-centered",
  style: {
    "font-weight": "500",
    "font-size": "18px",
    "color": "#05bbb8",
    "padding-bottom": "12px"
  }
};
const _hoisted_13 = {
  productname: "",
  style: {
    "padding": "0 6px"
  }
};
const _hoisted_14 = {
  key: 0,
  class: "flex-row flex-centered",
  style: {
    "font-size": "16px",
    "color": "#05bbb8",
    "height": "30px"
  }
};
const _hoisted_15 = ["href"];
const _hoisted_16 = {
  class: "flex-col ug-col",
  style: {
    "width": "20%"
  }
};
const _hoisted_17 = {
  class: "flex-row"
};
const _hoisted_18 = {
  class: "flex-col flex-centered",
  style: {
    "padding-right": "20px"
  },
  notranslate: ""
};
const _hoisted_19 = {
  style: {
    "margin": "0"
  }
};
const _hoisted_20 = {
  class: "flex-col"
};
const _hoisted_21 = {
  key: 0,
  class: "flex-row",
  style: {
    "align-items": "center",
    "justify-content": "flex-end"
  }
};
const _hoisted_22 = {
  key: 1,
  class: "flex-row",
  style: {
    "align-items": "center",
    "justify-content": "flex-end"
  }
};
const _hoisted_23 = {
  key: 2,
  class: "flex-row",
  style: {
    "align-items": "center",
    "justify-content": "flex-end"
  }
};
const _hoisted_24 = {
  class: "flex-col fullwidth ug-col-m"
};
const _hoisted_25 = {
  class: "flex-row fullwidth flex-centered",
  style: {
    "padding-bottom": "20px"
  }
};
const _hoisted_26 = {
  class: "image-slot"
};
const _hoisted_27 = {
  class: "flex-row fullwidth flex-centered",
  style: {
    "padding-bottom": "15px"
  }
};
const _hoisted_28 = {
  style: {
    "margin": "0"
  }
};
const _hoisted_29 = {
  productname: ""
};
const _hoisted_30 = {
  class: "flex-row fullwidth flex-centered",
  style: {
    "padding-bottom": "20px"
  }
};
const _hoisted_31 = {
  class: "flex-row fullwidth flex-centered",
  style: {
    "padding-bottom": "5px"
  }
};
const _hoisted_32 = {
  style: {
    "margin": "0"
  }
};
const _hoisted_33 = {
  productname: ""
};
const _hoisted_34 = {
  key: 0,
  class: "flex-row fullwidth flex-centered",
  style: {
    "font-size": "16px",
    "color": "#05bbb8",
    "height": "30px",
    "padding-bottom": "20px"
  }
};
const _hoisted_35 = ["href"];
const _hoisted_36 = {
  class: "flex-row fullwidth flex-centered",
  style: {
    "padding-bottom": "16px"
  },
  notranslate: ""
};
const _hoisted_37 = {
  style: {
    "margin": "0"
  }
};
const _hoisted_38 = {
  key: 1,
  class: "flex-row fullwidth flex-centered ug-btn-m"
};
const _hoisted_39 = {
  key: 2,
  class: "flex-row fullwidth flex-centered ug-btn-m"
};
const _hoisted_40 = {
  key: 3,
  class: "flex-row fullwidth flex-centered ug-btn-m"
};
const _hoisted_41 = {
  class: "flex-row fullwidth flex-between ug-dialog-footer-m"
};
const _hoisted_42 = {
  class: "dialog-footer"
};
import { ref, computed, watch, inject } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { PictureFilled, Right } from '@element-plus/icons-vue';
import Product from "@/classes/Product";
export default {
  __name: 'UpgradeModal',
  setup(__props) {
    const store = useStore();
    const router = useRouter();
    const cart = inject('cart');
    const visible = ref(false);

    // Methods

    /**
     * Handles swapping one upgrade for another
     * @param {object} source
     * @param {string} upgradePath
     */
    const handleUpgradeSwap = (source, upgradePath) => {
      for (let v in source.available_upgrades) {
        // Remove all other upgrades available for source product
        let availableUpgradePath = source.available_upgrades[v].ug_fs_path;
        if (!cart.isUpgradeInCart(availableUpgradePath)) {
          continue;
        }
        const cartProduct = cart.upgrades.find(upgrade => upgrade.path === availableUpgradePath);
        if (cartProduct) {
          cart.removeUpgrade(cartProduct);
        }
      }
      cart.addUpgrade(source, upgradePath); // Add new upgrade
    };

    /**
     * Returns the image url for a given product pid
     * @param {number} pid
     */
    const getUpgradeProductImg = pid => {
      return 'https://antares.sfo2.cdn.digitaloceanspaces.com/product_guis/product_gui_ids/' + pid + '.png';
    };

    /**
     * Handles clicking the "Checkout" button. Routes the user to checkout then closes the modal.
     */
    const handleCheckoutBtnClick = () => {
      router.push({
        name: 'Checkout'
      });
      handleClose();
    };

    /**
     * Closes the modal
     */
    const handleClose = () => {
      visible.value = false;
      setTimeout(() => store.commit('setUpgradeModalVisibilityState'), 500);
    };

    // Computed

    /**
     * Check whether pr_purchaseid_crossgraded_to and pr_purchaseid_upgraded_to are not null. If they are both not null, then omit this result.
     */
    const upgradeList = computed(() => {
      if (!data.value) {
        return [];
      }
      let upgrades = data.value?.available_upgrades;
      if (Array.isArray(upgrades)) {
        upgrades = upgrades.map(u => {
          try {
            u.fsData = new Product(u.ug_fs_path);
          } catch (e) {
            u.fsData = null;
          }
          if (u.fsData) {
            u.ug_price = u.fsData.currencyFormattedFinalPrice;
          }
          return u;
        });
      }
      return upgrades;
    });
    const data = computed(() => store.getters.getUpgradeModalVisibilityState);
    const freebieModalVisibilityState = computed(() => store.getters.getFreebieModalVisibilityState);

    // Watchers

    watch(data, () => {
      if (data.value) {
        visible.value = true;
      } else {
        visible.value = false;
      }
    });
    watch(freebieModalVisibilityState, () => {
      if (!freebieModalVisibilityState.value) {
        handleClose();
      }
    });
    return (_ctx, _cache) => {
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_tag = _resolveComponent("el-tag");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createBlock(_component_el_dialog, {
        class: "antares-dialog ug-dialog",
        "model-value": visible.value,
        width: "900px",
        "show-close": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_42, [_createVNode(_component_el_button, {
          plain: "",
          onClick: handleClose
        }, {
          default: _withCtx(() => _cache[15] || (_cache[15] = [_createTextVNode(" Close ")])),
          _: 1
        }), _unref(cart).totalItems > 0 ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          type: "primary",
          onClick: handleCheckoutBtnClick
        }, {
          default: _withCtx(() => _cache[16] || (_cache[16] = [_createTextVNode(" Checkout ")])),
          _: 1
        })) : _createCommentVNode("", true)])]),
        default: _withCtx(() => [upgradeList.value ? (_openBlock(), _createBlock(_component_el_row, {
          key: 0,
          align: "middle",
          justify: "start",
          style: {
            "font-size": "24px",
            "height": "30px"
          }
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(`${upgradeList.value.length || ''} Available Upgrade${upgradeList.value.length === 1 ? '' : 's'}`), 1)]),
          _: 1
        })) : _createCommentVNode("", true), _createVNode(_component_el_row, {
          align: "middle",
          justify: "center",
          style: {
            "padding": "36px 0"
          },
          class: "ug-dialog-list-wrapper"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 24
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(upgradeList.value, (p, i) => {
              return _openBlock(), _createElementBlock("div", {
                class: "flex-row",
                key: `update-${i}`,
                style: _normalizeStyle({
                  'padding-top': '24px',
                  'padding-bottom': '24px',
                  'border-top': i > 0 ? '1px solid #383838' : 'none',
                  'align-items': 'center',
                  'justify-content': 'space-between'
                })
              }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_image, {
                src: getUpgradeProductImg(p.ug_source_pid),
                style: {
                  "width": "200px"
                }
              }, {
                placeholder: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("div", {
                  class: "image-slot"
                }, [_createTextVNode(" Loading"), _createElementVNode("span", {
                  class: "dot"
                }, "...")], -1)])),
                error: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [_createVNode(_unref(PictureFilled))]),
                  _: 1
                })])]),
                _: 2
              }, 1032, ["src"])]), _createElementVNode("div", _hoisted_6, _toDisplayString(p.source_product_name), 1), _cache[1] || (_cache[1] = _createElementVNode("div", {
                class: "flex-row flex-centered",
                style: {
                  "height": "30px"
                }
              }, null, -1))]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_el_icon, null, {
                default: _withCtx(() => [_createVNode(_unref(Right))]),
                _: 1
              })])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createVNode(_component_el_image, {
                src: getUpgradeProductImg(p.ug_upgrade_pid),
                style: {
                  "width": "150px"
                }
              }, {
                placeholder: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("div", {
                  class: "image-slot"
                }, [_createTextVNode(" Loading"), _createElementVNode("span", {
                  class: "dot"
                }, "...")], -1)])),
                error: _withCtx(() => [_createElementVNode("div", _hoisted_11, [_createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [_createVNode(_unref(PictureFilled))]),
                  _: 1
                })])]),
                _: 2
              }, 1032, ["src"])]), _createElementVNode("div", _hoisted_12, [_createElementVNode("var", _hoisted_13, _toDisplayString(p.destination_product_name), 1)]), p.fsData && p.fsData.url ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("a", {
                href: p.fsData.url,
                target: "_blank"
              }, "More Info", 8, _hoisted_15)])) : _createCommentVNode("", true)])])]), _createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, [_createElementVNode("h3", _hoisted_19, _toDisplayString(p.ug_price), 1)]), _createElementVNode("div", _hoisted_20, [!_unref(cart).upgradesInCart[data.value.pr_authentication_string] ? (_openBlock(), _createElementBlock("div", _hoisted_21, [_createVNode(_component_el_button, {
                type: "primary",
                plain: "",
                size: "small",
                onClick: $event => _unref(cart).addUpgrade(data.value, p.ug_fs_path)
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode(" Add ")])),
                _: 2
              }, 1032, ["onClick"])])) : _unref(cart).upgradesInCart[data.value.pr_authentication_string] !== p.ug_fs_path ? (_openBlock(), _createElementBlock("div", _hoisted_22, [_createVNode(_component_el_button, {
                type: "primary",
                plain: "",
                size: "small",
                onClick: $event => handleUpgradeSwap(data.value, p.ug_fs_path)
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode(" Swap ")])),
                _: 2
              }, 1032, ["onClick"])])) : _unref(cart).upgradesInCart[data.value.pr_authentication_string] === p.ug_fs_path ? (_openBlock(), _createElementBlock("div", _hoisted_23, [_createVNode(_component_el_tag, {
                type: "success"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("Added")])),
                _: 1
              })])) : _createCommentVNode("", true)])])]), _createElementVNode("div", _hoisted_24, [_createElementVNode("div", _hoisted_25, [_createVNode(_component_el_image, {
                src: getUpgradeProductImg(p.ug_source_pid),
                style: {
                  "width": "200px"
                }
              }, {
                placeholder: _withCtx(() => _cache[6] || (_cache[6] = [_createElementVNode("div", {
                  class: "image-slot"
                }, [_createTextVNode(" Loading"), _createElementVNode("span", {
                  class: "dot"
                }, "...")], -1)])),
                error: _withCtx(() => [_createElementVNode("div", _hoisted_26, [_createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [_createVNode(_unref(PictureFilled))]),
                  _: 1
                })])]),
                _: 2
              }, 1032, ["src"])]), _createElementVNode("div", _hoisted_27, [_createElementVNode("h3", _hoisted_28, [_createElementVNode("var", _hoisted_29, _toDisplayString(p.source_product_name), 1)])]), _cache[12] || (_cache[12] = _createElementVNode("div", {
                class: "flex-row fullwidth flex-centered",
                style: {
                  "font-weight": "bold",
                  "font-size": "42px",
                  "padding-bottom": "15px"
                }
              }, [_createElementVNode("i", {
                class: "el-icon-bottom"
              })], -1)), _createElementVNode("div", _hoisted_30, [_createVNode(_component_el_image, {
                src: getUpgradeProductImg(p.ug_upgrade_pid),
                style: {
                  "width": "200px"
                }
              }, {
                placeholder: _withCtx(() => _cache[7] || (_cache[7] = [_createElementVNode("div", {
                  class: "image-slot"
                }, [_createTextVNode(" Loading"), _createElementVNode("span", {
                  class: "dot"
                }, "...")], -1)])),
                error: _withCtx(() => _cache[8] || (_cache[8] = [_createElementVNode("div", {
                  class: "image-slot"
                }, [_createElementVNode("i", {
                  class: "el-icon-picture-outline"
                })], -1)])),
                _: 2
              }, 1032, ["src"])]), _createElementVNode("div", _hoisted_31, [_createElementVNode("h3", _hoisted_32, [_createElementVNode("var", _hoisted_33, _toDisplayString(p.destination_product_name), 1)])]), p.fsData && p.fsData.url ? (_openBlock(), _createElementBlock("div", _hoisted_34, [_createElementVNode("a", {
                href: p.fsData.url,
                target: "_blank"
              }, "More Info", 8, _hoisted_35)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_36, [_createElementVNode("h3", _hoisted_37, _toDisplayString(p.ug_price), 1)]), !_unref(cart).upgradesInCart[data.value.pr_authentication_string] ? (_openBlock(), _createElementBlock("div", _hoisted_38, [_createVNode(_component_el_button, {
                style: {
                  "width": "80%"
                },
                type: "primary",
                plain: "",
                size: "small",
                onClick: $event => _unref(cart).addUpgrade(data.value, p.ug_fs_path)
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode(" Add ")])),
                _: 2
              }, 1032, ["onClick"])])) : _unref(cart).upgradesInCart[data.value.pr_authentication_string] !== p.ug_fs_path ? (_openBlock(), _createElementBlock("div", _hoisted_39, [_createVNode(_component_el_button, {
                style: {
                  "width": "80%"
                },
                type: "primary",
                plain: "",
                size: "small",
                onClick: $event => handleUpgradeSwap(data.value, p.ug_fs_path)
              }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode(" Swap ")])),
                _: 2
              }, 1032, ["onClick"])])) : _unref(cart).upgradesInCart[data.value.pr_authentication_string] === p.ug_fs_path ? (_openBlock(), _createElementBlock("div", _hoisted_40, [_createVNode(_component_el_tag, {
                type: "success",
                style: {
                  "width": "80%",
                  "text-align": "center"
                }
              }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [_createTextVNode("Added")])),
                _: 1
              })])) : _createCommentVNode("", true)])], 4);
            }), 128))]),
            _: 1
          })]),
          _: 1
        }), _createElementVNode("div", _hoisted_41, [_createVNode(_component_el_button, {
          plain: "",
          onClick: handleClose
        }, {
          default: _withCtx(() => _cache[13] || (_cache[13] = [_createTextVNode(" Close ")])),
          _: 1
        }), _unref(cart).totalItems > 0 ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          type: "primary",
          onClick: handleCheckoutBtnClick
        }, {
          default: _withCtx(() => _cache[14] || (_cache[14] = [_createTextVNode(" Checkout ")])),
          _: 1
        })) : _createCommentVNode("", true)])]),
        _: 1
      }, 8, ["model-value"]);
    };
  }
};