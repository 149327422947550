const helpers = require('../helpers.js');

/**
 * Source route tree for the app.
 * Doubles as the English route tree.
 * 
 * Used as part of the route generation process for all other supported language directories.
 * All edits here should be carried over into the other language subdirectories.
 */

/**
 * Route Regex Documentation
 * 
 * Note: vue-cli-sitemap does not like blob (*) regex patterns and will ignore them. Appears to not like repeated params either; (:id(\\d+)+) -> 123/456 -> [ 123, 456 ]
 * 
 * https://router.vuejs.org/guide/essentials/route-matching-syntax.html#custom-regexp-in-params
 */

module.exports = [
    {
        path: '/',
        name: "Root",
        component: () => import(/* webpackChunkName: "GenericRouterView" */ '@/layouts/GenericRouterView.vue'),
        meta: {
          appLoaded: true
        },
        children: [
          {
            path: '',
            name: 'Home',
            component: () => import(/* webpackChunkName: "Homepage" */ '@/views/Homepage.vue'),
            meta: {
              fromCms: true,
              hideFooter: false,
              blockMiniBanner: false
            }
          },
          {
            path: 'shopping-cart',
            redirect: 'checkout',
            meta: {
              blockFootball: true,
              blockMiniBanner: true,
              sitemap: {
                  ignoreRoute: true
              }
            }
          },
          {
            path: 'products',
            component: () => import(/* webpackChunkName: "GenericRouterView" */ '@/layouts/GenericRouterView.vue'),
            children: [
              {
                path: '',
                name: 'Products',
                component: () => import(/* webpackChunkName: "ProductPage" */ '@/views/Products/ProductPage.vue'),
                meta: {
                  fromCms: true,
                  delayLazyLoader: true
                }
              },
              {
                path: 'auto-tune/:slug?',
                name: 'Auto-Tune',
                meta: {
                  baseSlug: 'auto-tune',
                  fromCms: true,
                  slugKey: "auto-tune",
                  sitemap: {
                    slugs: ['', ...helpers.getLangSlugs('auto-tune')]
                  }
                },
                children: [
                  {
                    path: '',
                    name: 'AutoTuneRoot',
                    component: () => import(/* webpackChunkName: "AtProducts" */ '@/views/Products/AtProducts.vue')
                  },
                  {
                    path: ':subnav_slug?',
                    name: 'AutoTuneSubnav',
                    meta: {
                      sitemap: {
                        ignoreRoute: true
                      },
                    },
                    component: () => import(/* webpackChunkName: "AtProducts" */ '@/views/Products/AtProducts.vue')
                  }
                ],
                component: () => import(/* webpackChunkName: "AtProducts" */ '@/views/Products/AtProducts.vue'),
              },
              {
                path: 'vocal-effects/:slug?',
                name: 'VocalEffects',
                meta: {
                  baseSlug: 'vocal-effects',
                  fromCms: true,
                  slugKey: "vocal-effects",
                  sitemap: {
                    slugs: ['', ...helpers.getLangSlugs('vocal-effects')]
                  }
                },
                children: [
                  {
                    path: '',
                    name: 'VocalEffectsRoot',
                    component: () => import(/* webpackChunkName: "AtProducts" */ '@/views/Products/AtProducts.vue')
                  },
                  {
                    path: ':subnav_slug?',
                    name: 'VocalEffectsSubnav',
                    meta: {
                      sitemap: {
                        ignoreRoute: true
                      },
                    },
                    component: () => import(/* webpackChunkName: "AtProducts" */ '@/views/Products/AtProducts.vue')
                  }
                ],
                component: () => import(/* webpackChunkName: "AtProducts" */ '@/views/Products/AtProducts.vue'),
              },
              {
                path: 'subscriptions/:slug?',
                name: 'Subscriptions',
                meta: {
                  baseSlug: 'subscriptions',
                  fromCms: true,
                  slugKey: "subscriptions",
                  sitemap: {
                    slugs: ['', ...helpers.getLangSlugs('subscriptions')]
                  }
                },
                children: [
                  {
                    path: '',
                    name: 'SubscriptionsRoot',
                    component: () => import(/* webpackChunkName: "AtProducts" */ '@/views/Products/AtProducts.vue')
                  },
                  {
                    path: ':subnav_slug?',
                    name: 'SubscriptionsSubnav',
                    meta: {
                      sitemap: {
                        ignoreRoute: true
                      },
                    },
                    component: () => import(/* webpackChunkName: "AtProducts" */ '@/views/Products/AtProducts.vue')
                  }
                ],
                component: () => import(/* webpackChunkName: "AtProducts" */ '@/views/Products/AtProducts.vue'),
              },
              {
                path: 'compare',
                name: 'Compare',
                component: () => import(/* webpackChunkName: "Compare" */ '@/views/Products/Compare.vue')
              }
            ]
          },
          {
            path: 'my-account',
            name: 'MyAccount',
            component: () => import(/* webpackChunkName: "GenericRouterView" */ '@/layouts/GenericRouterView.vue'),
            meta: {
              blockFootball: true,
              blockMiniBanner: true,
              hideFooter: true,
              sitemap: {
                ignoreRoute: true
              }
            },
            children: [
              {
                path: '',
                name: 'Overview',
                component: () => import(/* webpackChunkName: "Overview" */ '@/views/UserProducts/UserProductList.vue'),
                meta: { requiresAuth: true }
              },
              {
                path: 'subscriptions/:id',
                name: 'SubscriptionDetail',
                component: () => import(/* webpackChunkName: "SubscriptionDetail" */ '@/views/SubscriptionDetail.vue'),
                meta: { requiresAuth: true }
              },
              {
                path: 'orders',
                name: 'Orders',
                component: () => import(/* webpackChunkName: "Orders" */ '@/views/Orders.vue'),
                meta: { requiresAuth: true }
              },
              {
                path: 'user-upgrades',
                name: 'UserUpgrades',
                component: () => import(/* webpackChunkName: "UserUpgrades" */ '@/views/UserUpgrades.vue'),
                meta: { requiresAuth: true }
              },
              {
                path: 'profile',
                name: 'Profile',
                component: () => import(/* webpackChunkName: "Profile" */ '@/views/Profile.vue'),
                meta: { requiresAuth: true }
              }
            ]
          },
          {
            path: 'checkout',
            name: 'Checkout',
            component: () => import(/* webpackChunkName: "Checkout" */ '@/views/Checkout'),
            meta: {
              blockFootball: true,
              blockMiniBanner: true,
              hideFooter: true,
            }
          },
          {
            path: 'setup',
            name: 'AccountSetup',
            component: () => import(/* webpackChunkName: "AccountSetup" */ '@/views/AccountSetup'),
            meta: {
              hideFooter: true,
              blockFootball: true,
              blockMiniBanner: true,
              sitemap: {
                ignoreRoute: true
              }
            }
          },
          {
            path: 'password-reset',
            name: 'PasswordReset',
            component: () => import(/* webpackChunkName: "PasswordReset" */ '@/views/PasswordReset'),
            meta: {
              blockFootball: true,
              blockMiniBanner: true,
              sitemap: {
                  ignoreRoute: true
              }
            }
          },
          {
            path: 'communications',
            name: 'CommunicationPreferences',
            component: () => import(/* webpackChunkName: "CommunicationPreferences" */ '@/views/CommunicationPreferences'),
            meta: {
              blockFootball: true,
              blockMiniBanner: true,
              sitemap: {
                  ignoreRoute: true
              }
            }
          },
          {
            path: 'learning-center',
            name: 'LearningCenter',
            meta: {
              delayLazyLoader: true
            },
            component: () => import(/* webpackChunkName: "LearningCenter" */ '@/views/LearningCenter')
          },
          {
            path: 'community',
            component: () => import(/* webpackChunkName: "GenericRouterView" */ '@/layouts/GenericRouterView.vue'),
            meta: {
              delayLazyLoader: true
            },
            children: [
              {
                path: '',
                name: 'Community',
                component: () => import(/* webpackChunkName: "CommunityPage" */ '@/views/Community/CommunityPage.vue'),
              },
              {
                path: ':slug',
                name: 'CommunityPost',
                meta: {
                  fromCms: true,
                  slugKey: "community",
                  sitemap: {
                    slugs: helpers.getLangSlugs("community")
                  }
                },
                component: () => import(/* webpackChunkName: "CommunityPost" */ '@/views/Community/CommunityPost.vue')
              },
              {
                path: "*",
                redirect: {
                  name: "Community"
                }
              }
            ]
          },
          {
            path: 'order-summary',
            name: 'OrderSummary',
            component: () => import(/* webpackChunkName: "OrderSummary" */ '@/views/OrderSummary'),
            meta: {
              blockFootball: true,
              blockMiniBanner: true,
              sitemap: {
                ignoreRoute: true
              }
            }
          },
          {
            path: 'discontinued-software-download',
            name: 'DiscontinuedSoftwareDownload',
            meta: {
              delayLazyLoader: true
            },
            component: () => import(/* webpackChunkName: "DiscontinuedSoftwareDownload" */ '@/views/DiscontinuedSoftwareDownloads')
          },
          {
            path: 'software-download/:slug?',
            name: 'SoftwareDownload',
            component: () => import(/* webpackChunkName: "SoftwareDownload" */ '@/views/SoftwareDownloads'),
            meta: {
              sitemap: {
                slugs: [
                  '',
                  'auto-tune-unlimited',
                  'auto-tune-producer',
                  'auto-tune-pro-x',
                  'auto-tune-unlimited-slice-content',
                  'auto-tune-hybrid',
                  'auto-tune-artist',
                  'auto-tune-efx-plus',
                  'auto-tune-access',
                  'auto-key',
                  'vocal-effects',
                  'antares-central',
                  'auto-tune-vocal-compressor'
                ]
              }
            }
          },
          {
            path: 'documentation',
            meta: {
              delayLazyLoader: true
            },
            component: () => import(/* webpackChunkName: "GenericRouterView" */ '@/layouts/GenericRouterView.vue'),
            children: [
              {
                path: '',
                name: 'Documentation',
                component: () => import(/* webpackChunkName: "DocumentationPage" */ '@/views/Documentation/DocumentationPage.vue'),
              },
              {
                path: ':slug(.+)', // (.+) Regex replaces default behavior, ([^/]+) -> match all characters that is not a "/".
                name: 'ProductManual',
                component: () => import(/* webpackChunkName: "ProductManualPage" */ '@/views/Documentation/ProductManualPage.vue'),
                meta: {
                  slugKey: "documentation",
                  delayLazyLoader: true,
                  fromCms: true,
                  sitemap: {
                    slugs: helpers.getLangSlugs("documentation")
                  }
                }
              }
            ]
          },
          {
            path: 'code-redemption',
            name: 'CodeRedemption',
            meta: {
              delayLazyLoader: true,
              specificSeller: "code-redemption",
              hideDealerCopy: true,
              hideRenewField: true
            },
            component: () => import(/* webpackChunkName: "DealerCode" */ '@/views/BaseReseller')
          },
          {
            path: 'dealercode/:slug?',
            name: 'DealerCode',
            meta: {
              delayLazyLoader: true,
              slugKey: "dealercode",
              sitemap: {
                slugs: ['', ...helpers.getLangSlugs("dealercode")]
              }
            },
            component: () => import(/* webpackChunkName: "DealerCode" */ '@/views/BaseReseller')
          },
          {
            path: 'promotions/:slug',
            name: 'promotions',
            meta: {
              fromCms: true,
              blockFootball: true,
              delayLazyLoader: true,
              slugKey: "promotions",
              sitemap: {
                slugs: helpers.getLangSlugs("promotions")
              }
            },
            component: () => import(/* webpackChunkName: "Promotions" */ '@/views/Promotions')
          },
          {
            path: 'press',
            name: 'Press',
            meta: {
              fromCms: true
            },
            component: () => import(/* webpackChunkName: "Press" */ '@/views/Press/Press.vue')
          },
          {
            path: 'about',
            name: 'About',
            meta: {
              fromCms: true
            },
            component: () => import(/* webpackChunkName: "About" */ '@/views/About.vue')
          },
          {
            path: 'store-test',
            name: 'StoreTest',
            component: () => import(/* webpackChunkName: "StoreTest" */ '@/views/Test/StoreTest.vue'),
            meta: {
              blockFootball: true,
              blockMiniBanner: true,
              sitemap: {
                ignoreRoute: true
              }
            }
          },
          {
            path: 'newsletter-signup',
            name: 'NewsletterSignup',
            component: () => import(/* webpackChunkName: "NewsletterSignup" */ '@/views/NewsletterSignup.vue'),
            meta: {
              blockFootball: true,
              blockMiniBanner: true
            }
          },
          {
            path: 'beta-signup',
            name: 'BetaSignup',
            component: () => import(/* webpackChunkName: "BetaSignup" */ '@/views/BetaSignup.vue'),
            meta: {
              blockFootball: true,
              blockMiniBanner: true,
              sitemap: {
                ignoreRoute: true
              }
            }
          },
          {
            path: 'privacy-policy',
            name: 'PrivacyPolicy',
            meta: {
              fromCms: true 
            },
            component: () => import (/* webpackChunkName: "PrivacyPolicy" */ '@/views/PrivacyPolicy.vue')
          },
          {
            path: 'refunds-policy',
            name: 'RefundsPolicy',
            component: () => import (/* webpackChunkName: "RefundsPolicy" */ '@/views/RefundsPolicy.vue')
          },
          {
            path: 'edu-site-license-inquiry',
            name: 'EduSiteLicenseInquiry',
            component: () => import (/* webpackChunkName: "EduSiteLicenseInquiry" */ '@/views/EduSiteLicenseInquiry.vue')
          },
          {
            path: ':slug',
            name: 'WPPageRenderer',
            meta: {
              blockFootball: true,
              fromCms: true,
              delayLazyLoader: true,
              slugKey: "public",
              sitemap: {
                slugs: helpers.getLangSlugs("public")
              }
            },
            component: () => import (/* webpackChunkName: "WPPageRenderer" */ '@/views/WPPageRenderer')
          },
          {
            path: 'artists',
            component: () => import(/* webpackChunkName: "GenericRouterView" */ '@/layouts/GenericRouterView.vue'),
            children: [
              {
                path: '',
                name: "Artists",
                component: () => import (/* webpackChunkName; "ArtistsPage" */ '@/views/Artists/ArtistsPage'),
              },
              {
                path: ':slug',
                name: "ArtistPage",
                component: () => import (/* webpackChunkName; "ArtistPost" */ '@/views/Artists/ArtistPost'),
                meta: {
                  fromCms: true,
                  delayLazyLoader: true,
                  slugKey: "artists",
                  sitemap: {
                    slugs: helpers.getLangSlugs("artists")
                  }
                }

              },
            ]
          }
        ]
    }
]