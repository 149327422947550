import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["disabled", "href"];
import { inject, computed } from 'vue';
import { useStore } from 'vuex';
import { getFsDataObj } from '@/helpers';
import { useFsLoading } from '@/classes/FastSpring';
export default {
  __name: 'WpElButton',
  props: {
    class: String,
    customClass: String,
    // Deprecated; going forward, please use the 'class' prop above
    type: {
      'type': String,
      'default': '',
      validator(v) {
        return ["", "primary", "success", "warning", "danger", "info"].includes(v?.toLowerCase());
      }
    },
    size: {
      'type': String,
      'default': 'default',
      validator(v) {
        return ["large", "default", "small"].includes(v?.toLowerCase());
      }
    },
    plain: Boolean,
    round: Boolean,
    circle: Boolean,
    disabled: Boolean,
    loading: Boolean,
    bg: Boolean,
    action: {
      'type': String,
      'default': 'add_to_cart',
      validator(v) {
        return ["add_to_cart", "go_to", "go_to_external", "open_trial_modal", "open_product_modal", "open_specific_products_modal", "handle_upgrade", "open_popup"].includes(v?.toLowerCase());
      }
    },
    value: {
      required: false
    }
  },
  setup(__props) {
    const store = useStore();
    const user = inject('user');
    const cart = inject('cart');
    const openProductOptions = inject("openProductOptions", () => {});
    const openSpecificProductOptions = inject("openSpecificProductOptions", () => {});
    const openTrialModal = inject("openTrialModal", () => {});
    const openPopupModal = inject("openPopupModal", () => {});

    // Search for a provided CB; default to empty function if none is found
    const cb = inject('callback', () => {});
    const props = __props;
    const fsLoading = useFsLoading();
    const isLoading = computed(() => {
      const usesFSData = !["go_to", "go_to_external", "open_popup"].includes(props.action);
      return props.loading || usesFSData && fsLoading.value;
    });
    const isDisabled = computed(() => {
      return props.disabled || isLoading.value;
    });
    const fsData = computed(() => store.getters.getFsData);

    /**
     * Handles the button click.
     */
    const handleClick = () => {
      let upgradeHandlerData = {
        path: null,
        followup: false
      };
      switch (props.action) {
        case 'add_to_cart':
          // Value must be a SKU
          try {
            cart.handleAddToCartBtnClick(props.value);
          } catch (e) {
            console.warn(e);
          }
          break;
        case 'open_product_modal':
          // Value must be a SKU
          openProductOptions(getFsDataObj(fsData.value, props.value));
          break;
        case 'open_specific_products_modal':
          try {
            if (!props.value) {
              throw new Error("Missing value property");
            }
            if (typeof props.value !== "object") {
              throw new Error(`Unexcepted value type; expected Object, received value of type "${typeof props.value}"`);
            }
            openSpecificProductOptions(props.value);
          } catch (e) {
            console.warn(e);
          }
          break;
        case 'open_trial_modal':
          // Does not need a value
          openTrialModal();
          break;
        case "handle_upgrade":
          if (typeof props.value === "string") {
            upgradeHandlerData.path = props.value;
          } else {
            upgradeHandlerData = props.value;
          }
          if (!upgradeHandlerData.path) {
            throw new Error("Button is misconfigured");
          }
          if (!user?.id && !upgradeHandlerData.followup) {
            // User is not signed in; show the Product Options Modal for Marketing purposes

            openSpecificProductOptions({
              opts: [upgradeHandlerData.path]
            });
          } else {
            // Attempt to add the Upgrade to the cart
            cart.handleUpgradeCtaClick(upgradeHandlerData.path);
          }
          break;
        case "open_popup":
          // Opens the Marketing Popup; does not allow for nexted marketing popups
          openPopupModal(props.value);
          break;
      }
      cb(); // Execute extra functionality if callback was injected in earlier
    };
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_router_link = _resolveComponent("router-link");
      return __props.action?.toLowerCase() === 'go_to_external' ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        disabled: isDisabled.value,
        href: __props.value,
        target: "_blank"
      }, [_createVNode(_component_el_button, {
        disabled: isDisabled.value,
        loading: isLoading.value,
        class: _normalizeClass(["fill-container", props.class || __props.customClass]),
        type: __props.type?.toLowerCase(),
        plain: __props.plain,
        round: __props.round,
        circle: __props.circle,
        bg: __props.bg,
        size: __props.size?.toLowerCase()
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 3
      }, 8, ["disabled", "loading", "class", "type", "plain", "round", "circle", "bg", "size"])], 8, _hoisted_1)) : __props.action?.toLowerCase() === 'go_to' ? (_openBlock(), _createBlock(_component_router_link, {
        key: 1,
        disabled: isDisabled.value,
        to: __props.value,
        onClick: _cache[0] || (_cache[0] = $event => handleClick())
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          disabled: isDisabled.value,
          loading: isLoading.value,
          class: _normalizeClass(["fill-container", props.class || __props.customClass]),
          type: __props.type?.toLowerCase(),
          plain: __props.plain,
          round: __props.round,
          circle: __props.circle,
          bg: __props.bg,
          size: __props.size?.toLowerCase()
        }, {
          default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
          _: 3
        }, 8, ["disabled", "loading", "class", "type", "plain", "round", "circle", "bg", "size"])]),
        _: 3
      }, 8, ["disabled", "to"])) : (_openBlock(), _createBlock(_component_el_button, {
        key: 2,
        disabled: isDisabled.value,
        loading: isLoading.value,
        class: _normalizeClass(props.class || __props.customClass),
        type: __props.type?.toLowerCase(),
        plain: __props.plain,
        round: __props.round,
        circle: __props.circle,
        bg: __props.bg,
        size: __props.size?.toLowerCase(),
        onClick: handleClick
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 3
      }, 8, ["disabled", "loading", "class", "type", "plain", "round", "circle", "bg", "size"]));
    };
  }
};