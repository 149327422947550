import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { handleConsent } from "@/composables/consent.js";
export default {
  __name: 'ConsentBanner',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_row = _resolveComponent("el-row");
      return _openBlock(), _createBlock(_component_el_row, {
        align: "middle",
        justify: "center",
        class: "consent-banner"
      }, {
        default: _withCtx(() => [_createElementVNode("div", null, [_cache[2] || (_cache[2] = _createTextVNode(" Our website uses ")), _createVNode(_component_router_link, {
          class: "privacy-policy",
          to: {
            path: '/privacy-policy'
          }
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("cookies")])),
          _: 1
        }), _cache[3] || (_cache[3] = _createTextVNode(" to deliver the best possible user experience. By clicking \"Accept\", you allow the use of cookies. "))]), _createVNode(_component_el_button, {
          style: {
            "margin": "5px"
          },
          type: "primary",
          class: "black-base",
          onClick: _cache[0] || (_cache[0] = $event => _unref(handleConsent)(1))
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode(" Accept ")])),
          _: 1
        })]),
        _: 1
      });
    };
  }
};