import { createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  style: {
    "margin-top": "auto",
    "margin-bottom": "auto"
  }
};
import { ref, computed, reactive, onMounted, onBeforeUnmount, inject, provide, watch, nextTick, h, render } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { useStore } from 'vuex';
import { throttle } from '@/helpers';
import { ElMessage, ElButton } from 'element-plus';
import { CloseBold } from "@element-plus/icons-vue";
import RuntimeTemplate from '@/components/WpComponents/RuntimeTemplate.vue';
import WpHttpClient from "@/classes/Wp.js";
import Enum from "@/classes/Enum.js";
import * as modalOps from "@/composables/modal.js";

// Low to High priority on Popup content served

export default {
  __name: 'FootballModal',
  setup(__props, {
    expose: __expose
  }) {
    const UserTypeEnum = new Enum(["default", "wasTrialer", "isTrialer", "wasSubscriber", "isSubscriber"]);
    const UserCommsTypeEnum = new Enum(["isNotOnMarketing", "isNotOnNewsletter"]);
    let timer = null;
    let scrollLock = false;

    // Used to help prevent bombarding the user with popups when login state is changed
    let scrollresettimer = null;
    let scrollreset = false;
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const popupBanner = ref(null);
    const state = reactive({
      visible: false,
      loading: true,
      error: null,
      content: null,
      forceType: null,
      delay: 5000,
      threshold: 0.25
    });
    const user = inject("user");

    // Computed Properties

    /**
     * Determine the type of user the current signed in account is.
     */
    const userType = computed(() => {
      if (!(user && user.id)) {
        return UserTypeEnum.default;
      }
      const {
        is_active_trialer,
        is_active_subscriber,
        email
      } = user;
      if (!email) {
        // abort since we can't do anything without this
        return;
      }
      let wasSubscriber = false; // No property appears to exist for this case; still implemented here for future expansion
      let wasTrialer = false;
      if (user.summary) {
        wasSubscriber = user.summary.wasSubscriber || wasSubscriber;
        wasTrialer = user.summary.wasTrialer || wasTrialer;
      }
      if (is_active_subscriber) {
        return UserTypeEnum.isSubscriber;
      }
      if (wasSubscriber) {
        return UserTypeEnum.wasSubscriber;
      }
      if (is_active_trialer) {
        return UserTypeEnum.isTrialer;
      }
      if (wasTrialer) {
        return UserTypeEnum.wasTrialer;
      }
      return UserTypeEnum.default;
    });

    // Track User comms separately to allow content display fallthrough to be tracked separately.
    const userCommsType = computed(() => {
      if (!(user && user.id)) {
        return UserCommsTypeEnum.isNotOnNewsletter;
      }
      const {
        optin_newsletter,
        optin_marketing,
        email
      } = user;
      if (!email) {
        // abort since we can't do anything without this
        return null;
      }
      if (!optin_newsletter) {
        return UserCommsTypeEnum.isNotOnNewsletter;
      }
      if (!optin_marketing) {
        return UserCommsTypeEnum.isNotOnMarketing;
      }
      return null;
    });

    /**
     * Determine which popup should be shown given the user type.
     */
    const activePopup = computed(() => {
      let popup = {
        ID: -1,
        post_content: ""
      };
      if (!state.content) {
        return popup;
      }
      if (state.forceType && state.content[state.forceType]) {
        return state.content[state.forceType];
      }
      if (userCommsType.value) {
        // Content should not fall through the entire User Comms Enum stack; User may be opted into Marketing but not Newsletter; or vice versa
        const type = userCommsType.value.getName();
        if (state.content[type]) return state.content[type];
      }
      if (!userType.value) {
        return popup; // User does not have a type due to lack of email
      }

      // Determine the appropriate popup to show; fallthrough allowed to help avoid the need to fill each user type in WP

      for (let i = userType.value.valueOf(); i >= 0; i--) {
        let type = UserTypeEnum.getByIndex(i)?.getName();
        if (!type) {
          console.warn(`Enum entry found without a description; enum id: ${i}`);
          continue;
        }
        if (!state.content[type]) {
          continue; // No content found for this state; go to next
        }
        popup = state.content[type];
        break;
      }
      return popup;
    });

    /**
     * Height threshold for Scroll Event Listener
     */
    const scrollThreshold = computed(() => {
      return document.documentElement.scrollHeight * state.threshold;
    });

    /**
     * Discern if the modal content retrieved from WP is newer than the last seen modal recorded in Vuex
     */
    const NewFootballModal = computed(() => {
      return activePopup.value.ID !== -1 && activePopup.value.ID !== store.getters.getLastFootballID;
    });

    /**
     * Discern if the modal is allowed to be shown on the current page.
     * 
     * Hides Modal if not on a valid page, or if the newest modal was already seen by the user.
     */
    const footballAllowed = computed(() => {
      const hasSeenNewModal = !NewFootballModal.value;
      if (hasSeenNewModal) {
        return false;
      }
      return !route.meta.blockFootball && !modalOps.hasOpenModals();
    });

    // Methods

    /**
     * Event Listener function for WP native links; used to inject closeModal behavior into WP native CTAs
     * 
     * @param {Object} e - event target
     */
    const nativeWpLinkHandler = e => {
      if (e.target.classList.contains('wp-block-button__link')) {
        closeModal();
      }
    };

    /**
     * Start a timer to show the modal if the user has not seen it yet. Set to 25s
     */
    const startTimer = () => {
      return setTimeout(() => {
        if (NewFootballModal.value && footballAllowed.value) {
          openModal();
        }
      }, state.delay);
    };

    /**
     * Initialize Timer & Scroll Event Listener to open the football modal.
     * 
     * Scroll handler set to trigger when ~40% of the page is scrolled, if the page is scrollable.
     * 
     * Should display the modal when either condition is met.
     */
    const handleDisplayModal = () => {
      window.addEventListener('scroll', handleScroll);
      timer = startTimer();
    };

    /**
     * Scroll Handler for Event Listener.
     * 
     * Opens Football modal once user scrolls beyond the threshold, if a page is scrollable.
     */
    const handleScroll = throttle(() => {
      if (!(NewFootballModal.value && footballAllowed.value)) {
        return;
      }
      if (scrollLock || scrollreset) {
        return;
      }
      if (document.documentElement.scrollTop >= scrollThreshold.value) {
        openModal();
      }
    }, 17);
    const clearAutomation = () => {
      window.removeEventListener('scroll', handleScroll);
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
    };

    /**
     * Open the Football Modal
     */
    const openModal = (type = null) => {
      if (state.loading && (state.error || !state.content)) {
        return;
      }
      clearAutomation();
      if (!type) {
        state.visible = true;
        return;
      }

      // Verify requested popup type exists & that the user meets the requirements
      const UserCommsTypeEntry = UserCommsTypeEnum[type];
      const UserTypeEntry = UserTypeEnum[type];
      try {
        if (!(UserCommsTypeEntry || UserTypeEntry)) {
          console.error("Invalid popup type entered");
          throw new Error("There has been an error retrieving this content. We apologize for the inconvenience.");
        }
        if (!state.content[type]) {
          console.error("Popup content is misconfigured");
          throw new Error("There has been an error retrieving this content. We apologize for the inconvenience.");
        }
        if (UserCommsTypeEntry && UserCommsTypeEntry !== userCommsType.value) {
          console.error("User cannot view content; user does not meet requirements");
          throw new Error("Our records indicate that you do not qualify for this promotion. We apologize for the inconvenience.");
        }
        if (UserTypeEntry && UserTypeEntry !== userType.value) {
          console.error("User cannot view content; user does not meet requirements");
          throw new Error("Our records indicate that you do not qualify for this promotion. We apologize for the inconvenience.");
        }
        state.forceType = type;
        state.visible = true;
      } catch (e) {
        const notif = ElMessage({
          onClick: () => notif.close(),
          message: e.message,
          type: "error",
          duration: 4000,
          customClass: 'login-message',
          offset: 20
        });
      }
    };

    /**
     * Close the Football Modal.
     * Removes the event listener & commit the latest football ID to Vuex.
     */
    const closeModal = () => {
      state.visible = false;
      state.forceType = null;
      clearAutomation();
      store.commit('setLastFootballID', activePopup.value.ID);
    };

    /**
     * Fetch the latest Football Modal from WP
     * 
     * Response code 200        - as normal; content retrieved
     * 
     * Response code 204        - Page found, but no data returned intentionally; disable modal
     * 
     * Response code 502        - Page found, but data was malformed in WP; disable modal
     * 
     * All other Response Codes - Unexpected Error.
     */
    const fetchContent = async () => {
      state.loading = true;
      state.error = null;
      try {
        const client = new WpHttpClient({
          namespace: '/antares/v1',
          path: '/football',
          global: true
        });
        const resp = await client.get();
        if (!(resp && resp.status === 200)) {
          throw new Error(resp?.status);
        }
        state.delay = parseInt(resp.headers['x-football-delay'] ?? "5") * 1000;
        state.threshold = parseInt(resp.headers['x-football-threshold'] ?? "25") / 100;
        state.content = resp.data;
      } catch (e) {
        /** 
         * 204 -> Football intentionally not set; Disable Football
         * 502 -> Invalid slug encountered Serverside
         */
        if (e.message !== '204') {
          console.log(e);
          state.error = e.message;
        }
      } finally {
        state.loading = false;
      }
    };
    const initialize = async () => {
      await fetchContent();
      if (state.content && !state.error) {
        handleDisplayModal();
      }
    };

    /**
     * Reset the timer for the modal & set a timer to prevent new modal from showing via scroll for 15s. Hides current modal if currently visible
     */
    const resetModal = () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      if (scrollresettimer) {
        clearTimeout(scrollresettimer);
        scrollresettimer = null;
      }
      if (state.visible) {
        state.visible = false;
      }
      scrollreset = true;
      scrollresettimer = setTimeout(() => scrollreset = false, 15000);
      timer = startTimer();
    };

    // Provide Callback to WpELButton; allows us to refine the CTA Handler
    provide('callback', () => closeModal());
    __expose({
      openModal,
      closeModal
    });

    // Hooks & Watchers

    onMounted(() => {
      initialize();
    });
    onBeforeUnmount(() => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      window.removeEventListener('scroll', handleScroll);
      popupBanner.value?.removeEventListener('click', nativeWpLinkHandler);
    });
    router.beforeEach(() => {
      // Prevent ScrollHandler from performing a false-positive on page transition
      scrollLock = true;

      // Clear scroll reset timer, if applicable, just before new page
      scrollreset = false;
      if (scrollresettimer) {
        clearTimeout(scrollresettimer);
        scrollresettimer = null;
      }
      setTimeout(() => scrollLock = false, 500);
    });
    watch(activePopup, oldVal => {
      // Prevent first population from triggering a complete reset
      if (oldVal.ID !== -1 && activePopup.value.ID !== oldVal.ID) {
        resetModal();
      }
    });
    watch(popupBanner, (newVal, oldVal) => {
      if (newVal && newVal !== oldVal) {
        popupBanner.value.addEventListener('click', nativeWpLinkHandler);
        nextTick(() => {
          // Dynamically insert Close Button to Template
          const elem = popupBanner.value.querySelector(".wp-block-cover");
          if (!elem) {
            return;
          }
          const style = {
            position: "absolute",
            top: "20px",
            right: "20px",
            "z-index": 1
          };
          const closeBtn = h(ElButton, {
            "type": "primary",
            "class": "black-base",
            style,
            "icon": CloseBold,
            "circle": true,
            onClick() {
              closeModal();
            }
          });
          render(closeBtn, elem); // Render & append the new vNode as a child of the Cover element
        });
      }
    });
    return (_ctx, _cache) => {
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: state.visible,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => state.visible = $event),
        class: "football-modal",
        center: "",
        top: "10vh",
        fullscreen: "",
        "show-close": false,
        "destroy-on-close": true,
        "append-to-body": true
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "fill-container flex-col post-content",
          style: {
            "align-items": "center"
          },
          ref_key: "popupBanner",
          ref: popupBanner
        }, [_createElementVNode("div", {
          class: "overlayed-container",
          onClick: _cache[0] || (_cache[0] = $event => closeModal())
        }), _createElementVNode("div", _hoisted_1, [_createVNode(RuntimeTemplate, {
          template: activePopup.value.post_content
        }, null, 8, ["template"])])], 512)]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
};