import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.every.js";
import "core-js/modules/esnext.iterator.some.js";
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, Transition as _Transition } from "vue";
const _hoisted_1 = ["data-key"];
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = {
  style: {
    "padding": "5px"
  }
};
const _hoisted_4 = ["innerHTML"];
const _hoisted_5 = {
  style: {
    "padding": "5px"
  }
};
const _hoisted_6 = ["innerHTML"];
const _hoisted_7 = {
  style: {
    "padding": "5px"
  }
};
import { ref, reactive, computed, onBeforeMount, watch, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';
import { registerResize, removeResize } from "@/composables/windowWatcher.js";
import WpElButton from "@/components/WpComponents/WpElButton.vue";
import WpHttpClient from "@/classes/Wp.js";
export default {
  __name: 'MiniBanner',
  props: {
    isSpacer: Boolean
  },
  setup(__props) {
    const route = useRoute();
    const props = __props;
    const target = ref(null);
    const state = reactive({
      loading: true,
      error: false,
      ready: false,
      templateReady: false,
      resize: null,
      content: null,
      blockList: [],
      allowList: []
    });

    // Computed Properties

    const data_key = computed(() => `mini-banner-template${props.isSpacer ? "-spacer" : ""}`);
    const dimensions = computed(() => {
      if (!(state.ready && target.value)) {
        return null;
      }
      return state.resize;
    });

    /**
     * Check to see if mini banner is allowed for current route.
     */
    const allowed = computed(() => {
      if (route.meta?.blockMiniBanner) {
        return false;
      }
      const checkList = list => {
        return list.some(item => {
          switch (item.type) {
            case "routename":
              return Object.values(route.matched).some(match => match.name === item.value);
            case "uri":
              return item.value === route.path;
          }
        });
      };
      if (state.allowList.length) {
        return checkList(state.allowList);
      }
      if (state.blockList.length) {
        return !checkList(state.blockList);
      }
      return true;
    });
    const height = computed(() => `${dimensions.value ? dimensions.value.height : 0}px`);

    // Functions

    /**
     * Initializes the component.
     * - Makes request to `/mini-banner` endpoint on CMS
     * - Attempts to parse 'mini_banner_content' property on response
     * - If present, attempts to parse 'mini_banner_allowList' and 'mini_banner_blockList'
     * - Sets these properties in component state
     */
    const initialize = async () => {
      state.loading = true;
      state.error = false;
      try {
        const client = new WpHttpClient({
          namespace: '/antares/v1',
          path: '/mini-banner',
          global: true
        });
        const resp = await client.get();
        if (!(resp && resp.status === 200)) {
          throw new Error(resp?.status);
        }
        if (!Array.isArray(resp.data) || !resp.data.length || !resp.data.every(v => 'meta' in v)) {
          throw new Error("No data returned in CMS response.");
        }
        const {
          meta
        } = resp.data[0];
        if (!(Array.isArray(meta.mini_banner_content) && meta.mini_banner_content.length)) {
          throw new Error("Invalid or missing data in CMS response");
        }
        state.content = JSON.parse(meta.mini_banner_content[0]);
        if (Array.isArray(meta.mini_banner_allowlist) && meta.mini_banner_allowlist.length) {
          const fetchedAllowList = JSON.parse(meta.mini_banner_allowList[0]);
          if (Array.isArray(fetchedAllowList)) {
            state.allowList = [...state.allowList, ...fetchedAllowList];
          }
        }
        if (Array.isArray(meta.mini_banner_blocklist) && meta.mini_banner_blocklist.length) {
          const fetchedBlocklist = JSON.parse(meta.mini_banner_blocklist[0]);
          if (Array.isArray(fetchedBlocklist)) {
            state.blockList = [...state.blockList, ...fetchedBlocklist];
          }
        }
        state.ready = true;
      } catch (e) {
        /** 
         * 204 -> Football intentionally not set; Disable Football
         * 502 -> Invalid slug encountered Serverside
         */
        if (e.message !== '204') {
          console.error(e);
          state.error = e.message;
        }
      } finally {
        state.loading = false;
      }
    };
    const validateCTA = CTA => {
      if (!(typeof CTA?.action === "string" && typeof CTA?.btn_text === "string")) {
        return false;
      }
      return !!CTA?.value;
    };

    // Hooks & Watchers

    onBeforeMount(initialize);
    onBeforeUnmount(() => {
      removeResize(target.value);
    });
    watch(dimensions, () => {
      setTimeout(() => {
        state.templateReady = true;
      }, 250);
    });
    watch(height, () => {
      document.documentElement.style.setProperty("--mini-banner-offset", height.value);
    });
    const stopWatchingTarget = watch(target, (newVal, oldVal) => {
      if (newVal && oldVal !== newVal) {
        state.resize = registerResize(newVal, ['height']);
        stopWatchingTarget();
      }
    });
    return (_ctx, _cache) => {
      const _component_el_row = _resolveComponent("el-row");
      const _component_router_link = _resolveComponent("router-link");
      return _openBlock(), _createElementBlock(_Fragment, null, [state.ready ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref_key: "target",
        ref: target,
        "data-key": data_key.value,
        class: "mini-banner",
        style: {
          "position": "fixed",
          "top": "0",
          "visibility": "hidden"
        }
      }, [_createVNode(_component_el_row, {
        justify: "center",
        align: "middle",
        style: {
          "padding": "5px"
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          innerHTML: state.content.content
        }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_row, {
          justify: "center"
        }, {
          default: _withCtx(() => [state.content.ctas?.length ? (_openBlock(true), _createElementBlock(_Fragment, {
            key: 0
          }, _renderList(state.content.ctas, (cta, i) => {
            return _openBlock(), _createElementBlock(_Fragment, {
              key: i
            }, [validateCTA(cta) ? (_openBlock(), _createBlock(WpElButton, {
              key: 0,
              type: "primary",
              disabled: "",
              action: cta.action,
              value: cta.value,
              style: {
                "margin": "5px"
              }
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(cta.btn_text), 1)]),
              _: 2
            }, 1032, ["action", "value"])) : _createCommentVNode("", true)], 64);
          }), 128)) : _createCommentVNode("", true)]),
          _: 1
        })])]),
        _: 1
      })], 8, _hoisted_1)) : _createCommentVNode("", true), _createVNode(_Transition, {
        appear: "",
        name: "scale"
      }, {
        default: _withCtx(() => [allowed.value && state.templateReady ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "mini-banner",
          style: _normalizeStyle({
            'background-image': `url(${state.content.background})`,
            '--scale-height': height.value
          })
        }, [state.content?.url ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: {
            path: state.content.url
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            justify: "center",
            align: "middle",
            style: {
              "padding": "5px"
            }
          }, {
            default: _withCtx(() => [_createElementVNode("div", {
              innerHTML: state.content.content
            }, null, 8, _hoisted_4), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_row, {
              justify: "center"
            }, {
              default: _withCtx(() => [state.content.ctas?.length ? (_openBlock(true), _createElementBlock(_Fragment, {
                key: 0
              }, _renderList(state.content.ctas, (cta, i) => {
                return _openBlock(), _createElementBlock(_Fragment, {
                  key: i
                }, [validateCTA(cta) ? (_openBlock(), _createBlock(WpElButton, {
                  key: 0,
                  type: "primary",
                  action: cta.action,
                  value: cta.value,
                  style: {
                    "margin": "5px"
                  }
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(cta.btn_text), 1)]),
                  _: 2
                }, 1032, ["action", "value"])) : _createCommentVNode("", true)], 64);
              }), 128)) : _createCommentVNode("", true)]),
              _: 1
            })])]),
            _: 1
          })]),
          _: 1
        }, 8, ["to"])) : (_openBlock(), _createBlock(_component_el_row, {
          key: 1,
          justify: "center",
          align: "middle",
          style: {
            "padding": "5px"
          }
        }, {
          default: _withCtx(() => [_createElementVNode("div", {
            innerHTML: state.content.content
          }, null, 8, _hoisted_6), _createElementVNode("div", _hoisted_7, [_createVNode(_component_el_row, {
            justify: "center"
          }, {
            default: _withCtx(() => [state.content.ctas?.length ? (_openBlock(true), _createElementBlock(_Fragment, {
              key: 0
            }, _renderList(state.content.ctas, (cta, i) => {
              return _openBlock(), _createElementBlock(_Fragment, {
                key: i
              }, [validateCTA(cta) ? (_openBlock(), _createBlock(WpElButton, {
                key: 0,
                type: "primary",
                action: cta.action,
                value: cta.value,
                style: {
                  "margin": "5px"
                }
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(cta.btn_text), 1)]),
                _: 2
              }, 1032, ["action", "value"])) : _createCommentVNode("", true)], 64);
            }), 128)) : _createCommentVNode("", true)]),
            _: 1
          })])]),
          _: 1
        }))], 4)) : _createCommentVNode("", true)]),
        _: 1
      })], 64);
    };
  }
};