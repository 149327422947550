import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import { useCssVars as _useCssVars } from 'vue';
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, TransitionGroup as _TransitionGroup, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "fill-container",
  style: {
    "overflow": "clip",
    "position": "relative"
  }
};
import { reactive, provide, onMounted, onUnmounted, toRef, ref, computed } from 'vue';
import CarouselIndicators from './CarouselIndicators.vue';
import { useSwipe } from '@vueuse/core';
import { ArrowLeftBold, ArrowRightBold } from '@element-plus/icons-vue';
export default {
  __name: 'CarouselContainer',
  props: {
    interval: {
      type: Number,
      required: false,
      default: 10
    },
    initialIndex: {
      type: Number,
      required: false,
      default: 0
    },
    pauseOnHover: {
      type: Boolean,
      required: false,
      default: true
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: true
    },
    showIndicators: Boolean,
    showArrows: Boolean,
    indicatorPos: {
      type: Object,
      required: false,
      default: () => {
        return {
          bottom: '0',
          left: '50%'
        };
      },
      // Default: Bottom Center
      validator(v) {
        return "bottom" in v || "left" in v || "right" in v || "top" in v; // Positioning for its absolute position
      }
    },
    indicatorAdjustOrigin: {
      type: Object,
      required: false,
      default: () => {
        return {
          vertical: '0',
          horizontal: '-50%'
        }; // center indicators on its x-axis
      },
      validator(v) {
        return v.vertical && v.horizontal;
      }
    },
    indicatorUseExtraContent: {
      type: Boolean,
      required: false,
      default: false
    },
    transitionName: {
      type: String,
      required: false,
      default: "fade"
    },
    disableSwipeGesture: Boolean,
    swipeSkipSlides: {
      type: Array,
      default: () => []
    },
    disableLoop: Boolean
  },
  emits: ["slideChanged"],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    _useCssVars(_ctx => ({
      "5fbf28ab": _unref(translateBy),
      "652f1306": __props.indicatorPos.top,
      "40af8476": __props.indicatorPos.left,
      "54d128e6": __props.indicatorPos.right,
      "7b39c24c": __props.indicatorPos.bottom,
      "59d86a68": __props.indicatorAdjustOrigin.horizontal,
      "5dca06ba": __props.indicatorAdjustOrigin.vertical
    }));
    const props = __props;
    const emits = __emit;
    const state = reactive({
      active_slide: props.initialIndex,
      num_slides: 0,
      interval: props.interval,
      interval_id: null,
      timer: 0,
      isPaused: false,
      slideAlias: [],
      lockSlideChange: false
    });
    const carousel = ref(null);
    const triggerThreshold = ref(0);
    let translateBy = null;
    const progress = ref(0);
    const initSwipeDir = ref(null);
    const initDocumentBodyOverflow = document.body.style.overflowY;
    if (!props.disableSwipeGesture) {
      const {
        lengthX,
        direction
      } = useSwipe(carousel, {
        passive: true,
        // Touch Start
        onSwipeStart() {
          if (!state.isPaused) {
            state.isPaused = true;
          }
          triggerThreshold.value = carousel.value.$el.clientWidth * 0.05;
          progress.value = 0;
          initSwipeDir.value = null;
        },
        // Mid Swipe; executes when initial (internal) threshold (default 50) is met or exceeded
        onSwipe() {
          /**
           * Lock behavior to one axis on swipe;
           * 
           * Locks vertical scroll when Horizontal swipe is initiated, allowing smoother behavior; does not currently work on mobile Safari
           * Should allow cleaner vertical scrolling; horizontal swipe progress should not show
           */

          if (state.num_slides <= 1) {
            return;
          }
          if (!initSwipeDir.value) {
            initSwipeDir.value = ["LEFT", "RIGHT"].includes(direction.value) ? "H" : "V";
          }
          if (initSwipeDir.value !== "H") {
            return;
          }
          document.body.style.overflowY = "hidden";
          if (!carousel.value.$el.classList.contains("swiping-active")) {
            carousel.value.$el.classList.add("swiping-active");
          }
          let canUpdate = true;
          if (props.disableLoop) {
            const swipePastStart = lengthX.value < 0 && state.active_slide === 0;
            const swipePastEnd = lengthX.value > 0 && state.active_slide === state.num_slides - 1;
            canUpdate = !(swipePastStart || swipePastEnd);
          }
          if (!canUpdate) {
            return;
          }
          const adjustedDist = Math.max(0, Math.abs(lengthX.value) - triggerThreshold.value);
          progress.value = Math.min(1.0, adjustedDist / triggerThreshold.value); // Track actual progress on swipe; distance = triggerThreshold (set above) + internal trigger (default 50)
        },
        // Executes on touch end IF swipe was detected
        onSwipeEnd() {
          document.body.style.overflowY = initDocumentBodyOverflow;
          carousel.value.$el.classList.remove("swiping-active");
          state.isPaused = false;
          state.timer = 0;
          if (state.num_slides <= 1) {
            return;
          }
          if (initSwipeDir.value !== "H") {
            return;
          }
          if (progress.value !== 1.0) {
            return;
          }
          if (lengthX.value > 0) {
            swipeNextSlide(true);
          } else if (lengthX.value < 0) {
            swipePrevSlide(true);
          }
        }
      });
      translateBy = computed(() => {
        if (!lengthX.value || initSwipeDir.value !== "H") {
          return 0;
        }
        const dir = lengthX.value > 0 ? -1 : 1;
        return `${dir * (progress.value * 2)}%`;
      });
    }

    /**
     * Register Carousel Slide with optional slide name. Modifies value stored in passed in ref id.
     * @param {ref} id
     * @param {string} name 
     */
    const assignSlideNum = (id, name) => {
      try {
        if (name && state.slideAlias.find(slide => slide.name === name)) {
          throw new Error(`Alias "${name}" already exists in the carousel`);
        }
        state.slideAlias.push({
          name,
          id
        });
        id.value = state.num_slides;
        state.num_slides++;
      } catch (e) {
        console.log(e);
      }
    };

    /**
     * Unregister slide by name or by its index
     * Currently used when Slide component Unmounts to clean up the carousel if dynamic content is registered/unregistered (i.e. v-for loops)
     * 
     * @param {string | number} val 
     */
    const removeSlideNum = val => {
      let slide = -1;
      if (typeof val === "string") {
        try {
          const slide_obj = state.slideAlias.find(slide => slide.name === val);
          if (!slide_obj) {
            throw new Error(`Unable to remove "${val}"; alias not found`);
          }
          slide = slide_obj.id;
        } catch (e) {
          console.log(e);
          return;
        }
      } else if (0 <= val && val < state.num_slides) {
        slide = val;
      } else {
        console.log(`"${val}" is out of bounds`);
        return;
      }
      if (state.active_slide >= slide) {
        state.active_slide = 0;
      }
      for (let i = slide + 1; i < state.slideAlias.length; i++) {
        state.slideAlias[i].id--;
      }
      state.slideAlias.splice(slide, 1);
      state.num_slides = state.slideAlias.length;
      state.timer = 0;
    };
    const updateVisibility = () => {
      state.isPaused = document.visibilityState === "hidden";
    };

    /**
     * Switch slide to specific index
     * May use a slide alias instead if known
     * 
     * optional boolean emit to allow watching for change completion
     * @param {string | number} val
     * @param {boolean} emit
     */
    const setSlideNum = (val, emit = false) => {
      let newSlide = 0;
      if (typeof val === 'string') {
        try {
          const slide_obj = state.slideAlias.find(slide => slide.name === val);
          if (!slide_obj) {
            throw new Error(`"${val}" is not an alias for a slide`);
          }
          newSlide = slide_obj.id;
        } catch (e) {
          console.log(e);
        }
      } else if (0 <= val && val < state.num_slides) {
        newSlide = val;
      }
      state.active_slide = newSlide;
      state.timer = 0;
      if (emit) {
        emits('slideChanged', {
          index: state.active_slide,
          name: state.slideAlias[state.active_slide].name
        });
      }
    };

    /**
     * Used to increment slide to next neighbor
     * 
     * optional boolean emit to allow watching for change completion
     * @param {boolean} emit 
     */
    const nextSlide = (emit = false) => {
      let changeTriggered = false;
      if (!props.disableLoop) {
        state.active_slide = (state.active_slide + 1) % state.num_slides;
        changeTriggered = true;
      } else if (state.active_slide < state.num_slides - 1) {
        state.active_slide += 1;
        changeTriggered = true;
      }
      if (changeTriggered) {
        state.timer = 0;
        if (emit) {
          emits('slideChanged', {
            index: state.active_slide,
            name: state.slideAlias[state.active_slide].name
          });
        }
      }
    };
    const swipeNextSlide = (emit = false) => {
      if (!props.swipeSkipSlides.length) {
        nextSlide(emit);
        return;
      }
      let changeTriggered = false;
      let nextSlideIndex = state.active_slide;
      if (!props.disableLoop) {
        nextSlideIndex = (nextSlideIndex + 1) % state.num_slides;
        while (nextSlideIndex !== state.active_slide && props.swipeSkipSlides.includes(state.slideAlias[nextSlideIndex].name)) {
          nextSlideIndex = (nextSlideIndex + 1) % state.num_slides;
        }
        if (nextSlideIndex !== state.activeSlide) {
          changeTriggered = true;
        }
      } else if (nextSlideIndex + 1 < state.num_slides) {
        nextSlideIndex += 1;
        while (nextSlideIndex < state.num_slides && props.swipeSkipSlides.includes(state.slideAlias[nextSlideIndex].name)) {
          nextSlideIndex += 1;
        }
        if (nextSlideIndex !== state.num_slides) {
          changeTriggered = true;
        }
      }
      if (changeTriggered) {
        state.active_slide = nextSlideIndex;
        state.timer = 0;
        if (emit) {
          emits('slideChanged', {
            index: state.active_slide,
            name: state.slideAlias[state.active_slide].name
          });
        }
      }
    };

    /**
     * Used to decrement slide to previous neighbor
     * 
     * optional boolean emit to allow watching for change completion
     * @param {boolean} emit
     */
    const prevSlide = (emit = false) => {
      let changeTriggered = false;
      if (!props.disableLoop) {
        state.active_slide = state.active_slide - 1 >= 0 ? state.active_slide - 1 : state.num_slides - 1;
        changeTriggered = true;
      } else if (state.active_slide > 0) {
        state.active_slide -= 1;
        changeTriggered = true;
      }
      if (changeTriggered) {
        state.timer = 0;
        if (emit) {
          emits('slideChanged', {
            index: state.active_slide,
            name: state.slideAlias[state.active_slide].name
          });
        }
      }
    };
    const swipePrevSlide = (emit = false) => {
      if (!props.swipeSkipSlides.length) {
        prevSlide(emit);
        return;
      }
      let changeTriggered = false;
      let prevSlideIndex = state.active_slide;
      if (!props.disableLoop) {
        prevSlideIndex = prevSlideIndex - 1 >= 0 ? prevSlideIndex - 1 : state.num_slides - 1;
        while (prevSlideIndex !== state.active_slide && props.swipeSkipSlides.includes(state.slideAlias[prevSlideIndex].name)) {
          prevSlideIndex = prevSlideIndex - 1 >= 0 ? prevSlideIndex - 1 : state.num_slides - 1;
        }
        if (prevSlideIndex !== state.activeSlide) {
          changeTriggered = true;
        }
      } else if (prevSlideIndex - 1 >= 0) {
        prevSlideIndex -= 1;
        while (prevSlideIndex >= 0 && props.swipeSkipSlides.includes(state.slideAlias[prevSlideIndex].name)) {
          prevSlideIndex -= 1;
        }
        if (prevSlideIndex >= 0) {
          changeTriggered = true;
        }
      }
      if (changeTriggered) {
        state.active_slide = prevSlideIndex;
        state.timer = 0;
        if (emit) {
          emits('slideChanged', {
            index: state.active_slide,
            name: state.slideAlias[state.active_slide].name
          });
        }
      }
    };

    /**
     * Provide for component injection
     * Allows us to pass data to N number of Slides without needing to pass props
     */

    provide('setSlideNum', setSlideNum);
    provide('nextSlide', nextSlide);
    provide('prevSlide', prevSlide);
    provide('slideControllers', {
      setSlideNum,
      nextSlide,
      prevSlide
    });
    provide('assignSlideNum', assignSlideNum); // Used when Slide mounts; any component can use this to be come a slide of this carousel
    provide('removeSlideNum', removeSlideNum); // Used when Slide unmounts; be sure to call this onUnmount to ensure "slides" get cleaned up properly
    provide('numSlides', toRef(state, 'num_slides'));
    provide('activeSlide', toRef(state, 'active_slide'));
    provide('transitionName', toRef(props, 'transitionName'));
    __expose({
      setSlideNum,
      nextSlide,
      prevSlide,
      "numSlides": toRef(state, 'num_slides'),
      "activeSlide": toRef(state, 'active_slide')
    });

    /**
     * Lifecycle Hooks
     */

    onMounted(() => {
      // Verifies active slide is within bounds, resets to 1st slide otherwise
      if (!(0 <= state.active_slide && state.active_slide < state.num_slides)) {
        state.active_slide = 0;
      }
      if (props.autoplay) {
        state.interval_id = setInterval(() => {
          if (!state.isPaused) {
            state.timer++;
          }
          if (state.timer >= state.interval) {
            nextSlide();
            state.timer = 0;
          }
        }, 1000);
        document.addEventListener("visibilitychange", updateVisibility);
      }
    });
    onUnmounted(() => {
      if (props.autoplay) {
        document.removeEventListener("visibilitychange", updateVisibility);
      }
      if (state.interval_id) {
        clearInterval(state.interval_id);
        state.interval_id = null;
      }
    });
    return (_ctx, _cache) => {
      const _component_el_container = _resolveComponent("el-container");
      const _component_el_icon = _resolveComponent("el-icon");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, {
        class: "carousel-container",
        ref_key: "carousel",
        ref: carousel,
        onMouseover: _cache[0] || (_cache[0] = () => {
          if (__props.pauseOnHover) {
            state.isPaused = true;
          }
        }),
        onMouseleave: _cache[1] || (_cache[1] = () => {
          if (__props.pauseOnHover) {
            state.isPaused = false;
          }
        })
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 3
      }, 512), _renderSlot(_ctx.$slots, "carousel-indicators", {}, () => [__props.showIndicators ? (_openBlock(), _createBlock(CarouselIndicators, {
        key: 0,
        class: "integrated-indicators",
        useExtraContent: __props.indicatorUseExtraContent
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "indicators-extra-content")]),
        _: 3
      }, 8, ["useExtraContent"])) : _createCommentVNode("", true)]), __props.showArrows ? (_openBlock(), _createBlock(_TransitionGroup, {
        key: 0,
        appear: "",
        name: "fade-in-place"
      }, {
        default: _withCtx(() => [_withDirectives(_createElementVNode("div", {
          class: "arrows",
          style: {
            "left": "15px"
          },
          onClick: prevSlide,
          key: "prev",
          "data-testid": "prevArrow"
        }, [_createVNode(_component_el_icon, {
          size: 30
        }, {
          default: _withCtx(() => [_createVNode(_unref(ArrowLeftBold))]),
          _: 1
        })], 512), [[_vShow, !__props.disableLoop || state.active_slide > 0]]), _withDirectives(_createElementVNode("div", {
          class: "arrows",
          style: {
            "right": "15px"
          },
          onClick: nextSlide,
          key: "next",
          "data-testid": "nextArrow"
        }, [_createVNode(_component_el_icon, {
          size: 30
        }, {
          default: _withCtx(() => [_createVNode(_unref(ArrowRightBold))]),
          _: 1
        })], 512), [[_vShow, !__props.disableLoop || state.active_slide < state.num_slides - 1]])]),
        _: 1
      })) : _createCommentVNode("", true)]);
    };
  }
};