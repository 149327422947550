import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "flex-row flex-centered fullwidth fullheight"
};
const _hoisted_2 = {
  class: "flex-col fullwidth fullheight"
};
const _hoisted_3 = {
  class: "flex-row",
  id: "cart-product-container"
};
const _hoisted_4 = {
  class: "flex-col fullwidth"
};
const _hoisted_5 = {
  class: "flex-row flex-centered cart-footer"
};
const _hoisted_6 = {
  class: "flex-col fullwidth"
};
const _hoisted_7 = {
  class: "flex-row flex-centered",
  style: {
    "padding": "12px 0"
  }
};
const _hoisted_8 = {
  key: 0,
  class: "flex-row flex-centered",
  style: {
    "font-size": "12px",
    "text-align": "center",
    "font-weight": "bold"
  }
};
const _hoisted_9 = {
  class: "flex-row flex-centered",
  style: {
    "padding-top": "12px"
  }
};
import { watch, computed, inject, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import CartProduct from './CartProduct';
import { ElNotification } from 'element-plus';
import UpgradeDialog from './UpgradeDialog.vue';
import { watchFastSpring } from '@/classes/FastSpring';
export default {
  __name: 'Cart',
  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const user = inject('user');
    const cart = inject('cart');
    let displayedNotifs = [];

    /**
     * Closes the cart and sends the user to the checkout process. Handles GA event tracking beforehand.
     */
    const startCheckout = () => {
      cart.setVisibility(false);
      router.push({
        name: 'Checkout'
      });
    };

    // Computed

    const notifs = computed(() => store.getters.getCartNotifs);
    const sendToCheckout = computed(() => store.getters.getSendUserToCheckout);

    // Watchers

    /**
     * Handles displaying notifications. Assigns randomId to each notification instance in order to be able to show multiple
     * notifications at once and close each one selectively. Limits the number of shown notifs to four.
     */
    watch(notifs, () => {
      notifs.value.forEach((v, i) => {
        if (displayedNotifs.indexOf(v.id) >= 0) {
          return;
        }
        let title = null;
        switch (v.type) {
          case 'error':
            title = "Error";
            break;
          case 'info':
            title = "Info";
            break;
          default:
            title = `${v.data.name || 'Product'} added to your cart!`;
        }
        let payload = {
          title,
          type: v.type || 'success',
          duration: v.type === 'error' ? 0 : 10000,
          offset: i !== 0 ? (i + 1) * 38 : 0,
          customClass: 'notif',
          onClose: () => {
            if (notifs.value.length) {
              store.commit('unsetCartNotif', v.id);
              displayedNotifs = displayedNotifs.filter(id => id !== v.id);
            }
          }
        };
        if (typeof v.msg === 'string') {
          payload.message = v.msg;
        }
        if (v.clickToCheckout && route.name !== 'Checkout') {
          payload.onClick = () => {
            notifs.value.forEach(n => n.notif.close());
            startCheckout();
          };
          payload.message = 'Click to start checkout';
        } else {
          payload.onClick = () => v.notif.close();
        }
        v.notif = ElNotification(payload);
        displayedNotifs.push(v.id);
      });
      if (notifs.value.length >= 4) {
        notifs.value[3].notif.close();
        store.commit('removeLastCartNotif');
      }
    }, {
      deep: true
    });
    watch(sendToCheckout, () => {
      if (sendToCheckout.value) {
        startCheckout();
      }
    });

    // Clear user's upgrades if they logout
    watch(() => user.state.logoutLoading, (newVal, oldVal) => {
      if (!newVal && newVal !== oldVal) {
        cart.clearUpgrades();
      }
    });
    onMounted(() => {
      store.commit('unsetAllCartNotifs');
      watchFastSpring(() => cart.validateCart());
    });
    return (_ctx, _cache) => {
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_drawer = _resolveComponent("el-drawer");
      return _openBlock(), _createElementBlock(_Fragment, null, [_unref(cart) ? (_openBlock(), _createBlock(_component_el_drawer, {
        key: 0,
        title: "YOUR CART",
        modelValue: _unref(cart).visible,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(cart).visible = $event),
        direction: "rtl",
        onClosed: _cache[3] || (_cache[3] = $event => _unref(cart).setVisibility(false)),
        class: "drawer-user-cart"
      }, {
        default: _withCtx(() => [_unref(cart).cartIsNotEmpty ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(cart).products, product => {
          return _openBlock(), _createBlock(_unref(CartProduct), {
            onSetQuantity: _cache[0] || (_cache[0] = data => _unref(cart).setQuantity(data)),
            cartProduct: product,
            key: `p-${product}`
          }, null, 8, ["cartProduct"]);
        }), 128)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(cart).upgrades, upgrade => {
          return _openBlock(), _createBlock(_unref(CartProduct), {
            onRmUpgrade: _cache[1] || (_cache[1] = data => _unref(cart).removeUpgrade(data)),
            isUpgrade: true,
            cartProduct: upgrade,
            key: `u-${upgrade}`
          }, null, 8, ["cartProduct"]);
        }), 128))])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_el_col, {
          span: 12
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            align: "middle",
            justify: "start",
            style: {
              "font-weight": "bold"
            }
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode(" Subtotal: ")])),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 12
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            align: "middle",
            justify: "end",
            "data-testid": "subtotalValue"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(cart).formattedSubtotal), 1)]),
            _: 1
          })]),
          _: 1
        })]), _unref(cart).trialpricing ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", null, "If you don't cancel your free trial before it ends in 14 days, you will be automatically charged " + _toDisplayString(_unref(cart).formattedTrialPricing) + " to begin your paid monthly subscription.", 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_9, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: startCheckout,
          id: "cart-checkout-btn",
          "data-testid": "checkoutButton"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("CHECKOUT")])),
          _: 1
        })])])])])])) : (_openBlock(), _createBlock(_component_el_row, {
          key: 1,
          align: "middle",
          justify: "center",
          class: "cart-full-height"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 24,
            class: "cart-full-height"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_row, {
              align: "middle",
              justify: "center",
              class: "cart-full-height"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_col, {
                span: 24
              }, {
                default: _withCtx(() => [_createVNode(_component_el_row, {
                  align: "middle",
                  justify: "center",
                  style: {
                    "font-weight": "bold",
                    "font-size": "18px",
                    "padding-bottom": "12px"
                  }
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [_createElementVNode("h1", null, "Your cart is empty!", -1)])),
                  _: 1
                }), _createVNode(_component_el_row, {
                  align: "middle",
                  justify: "center"
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [_createElementVNode("h3", null, "Shop around and check back when you're ready.", -1)])),
                  _: 1
                })]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }))]),
        _: 1
      }, 8, ["modelValue"])) : _createCommentVNode("", true), _createVNode(UpgradeDialog)], 64);
    };
  }
};