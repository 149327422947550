import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "toggle"
};
const _hoisted_2 = {
  class: "toggle-track"
};
import { computed } from 'vue';
export default {
  __name: 'ToggleSwitch',
  props: {
    state: {
      type: Boolean,
      default: false
    }
  },
  emits: ['toggled'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emits = __emit;
    const power = computed({
      get() {
        return props.state;
      },
      set(val) {
        emits('toggled', val);
      }
    });
    const toggle = () => {
      power.value = !power.value;
    };
    return (_ctx, _cache) => {
      const _component_el_row = _resolveComponent("el-row");
      return _openBlock(), _createBlock(_component_el_row, {
        align: "middle",
        justify: "center",
        onClick: _cache[0] || (_cache[0] = $event => toggle())
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "off", {}, () => [_cache[1] || (_cache[1] = _createTextVNode("Off"))]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
          class: _normalizeClass(["toggle-btn", {
            active: power.value
          }])
        }, null, 2)])]), _renderSlot(_ctx.$slots, "on", {}, () => [_cache[2] || (_cache[2] = _createTextVNode("On"))])]),
        _: 3
      });
    };
  }
};