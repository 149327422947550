import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.every.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.some.js";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "pw-requirement-text"
};
const _hoisted_2 = {
  class: "flex-col",
  style: {
    "padding-right": "10px"
  }
};
const _hoisted_3 = {
  key: 1
};
import { ref, reactive, markRaw, computed, onMounted, onBeforeUnmount, inject, watch } from 'vue';
import { useStore } from "vuex";
import { useRoute, useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import { CircleClose, CircleCheck } from '@element-plus/icons-vue';
import ResetPasswordModal from '../components/ResetPasswordModal';
import Tag from "@/components/Typography/Tag.vue";
import { validateEmail, validatePw } from '../helpers';
import { currentBP } from '../composables/windowWatcher';
import { handleAuthError, closeErrNotif } from '@/composables/authHelpers';
import { compareRoutes, translateRoute, updateCurrentRoute } from "@/helpers/language";
import * as modalOps from "@/composables/modal.js";
import "element-plus/theme-chalk/src/message.scss";
export default {
  __name: 'AuthModal',
  setup(__props, {
    expose: __expose
  }) {
    const {
      VUE_APP_SERVER,
      VUE_APP_TEST_USER_EMAIL,
      VUE_APP_TEST_USER_PW
    } = process.env;
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const localize = inject('localize');
    const user = inject('user');
    const cart = inject('cart');
    const ir = inject('ir');
    let warningMsg = null;
    let loginMsg = null;
    const formType = ref(1); // 0 - CREATE AN ACCOUNT, 1 - Login
    const showPwResetModal = ref(false);
    const lockEmailField = ref(false);
    const validationConditions = ref(['', '', '', '', '', '']);
    const pwRequirement1 = ref(null);
    const pwRequirement2 = ref(null);
    const initialEmail = () => {
      if (VUE_APP_SERVER === 'dev' && VUE_APP_TEST_USER_EMAIL) {
        return VUE_APP_TEST_USER_EMAIL;
      }
      if (route.query.loginEmail) {
        return route.query.loginEmail.replace(/\s/g, "+");
      }
      return '';
    };

    /**
     * Used to help track current input field for each form
     */
    const formElems = reactive({
      "create": null,
      "login": null
    });
    const loginForm = reactive({
      email: initialEmail(),
      password: VUE_APP_SERVER === 'dev' && VUE_APP_TEST_USER_PW ? VUE_APP_TEST_USER_PW : ''
    });
    const newAccountForm = reactive({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      optin: true
    });
    const visible = ref(false);
    let isRedirecting = false;
    const cbs = ref([]);

    // Computed properties

    const formIsValid = computed(() => {
      return validationConditions.value.every(c => c === CircleCheck);
    });
    const loading = ref(false);
    const AuthModalEventBus = computed(() => store.getters.getEventBus["AuthModalRequest"]);

    // Methods

    /**
     * Handle "Enter" Keyup event.
     * 
     * Pressing "Enter" while focused on the last text input element of the form will attempt to submit the form.
     * 
     * On other text inputs elements, pressing "Enter" should behave as a "Tab" key press within the form.
     * 
     * @param {KeyboardEvent} event - Keyboard event
     * @param {String} type - Form type
     */
    const handleEnter = (event, type) => {
      if (!(formElems && formElems[type])) return;
      const inputs = Object.values(formElems[type].$el?.querySelectorAll("input.el-input__inner"));
      const index = inputs.findIndex(value => value === event.target);
      if (index < 0) return;
      if (index < inputs.length - 1) {
        inputs[index + 1].focus();
        return;
      }
      const canSubmit = type === "login" || type === "create" && formIsValid.value;
      if (!loading.value && canSubmit) {
        handleLoginOrCreate(type, {
          ...(type === 'login' ? loginForm : newAccountForm)
        });
      }
    };

    /**
     * Add a callback function to the Auth Modal to be executed after a successful Authentication.
     * 
     * Takes in an optional parameter to open the Authentication Modal immediately.
     * 
     * @param {Function} fn
     * @param {Boolean} openImmediately Default false
     */
    const addCallback = (fn, openImmediately = false) => {
      if (!(fn && typeof fn === "function")) {
        return;
      }
      cbs.value.push(fn);
      if (openImmediately) {
        openModal();
      }
    };

    /**
     * 
     */
    const clearCallbacks = () => {
      cbs.value = [];
    };

    /**
     * Opens Authentication Modal.
     * Sets post login destination if one is passed in.
     * 
     * @param {RouteLocationRaw} destination - (optional) Vue-router Destination object to set as post login destination
     */
    const openModal = destination => {
      modalOps.registerModal('auth');
      if (destination) {
        addCallback(() => {
          const targetLang = user.language_preference || localize.currCode.value;
          localize.setLanguage(targetLang);
          const finalDestination = translateRoute(destination, targetLang, destination.meta?.lang);
          finalDestination.params.pretranslated = true;
          router.push(finalDestination);
        });
      }
      visible.value = true;
      if (!cbs.value.length) return;
      warningMsg = ElMessage({
        onClick: () => warningMsg.close(),
        message: 'Please log in to continue',
        type: 'warning',
        duration: 4000,
        customClass: 'login-message',
        offset: 20
      });
      if (destination?.name === 'Checkout') {
        const upgrades = cart.upgrades.map(v => v.path);
        const products = Object.keys(cart.products);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'CheckoutSignIn',
          totalPrice: '',
          subtotalPrice: cart.subtotal,
          productIds: [...upgrades, ...products]
        });
      }
    };

    /**
     * Close Authentication Modal
     */
    const closeModal = () => {
      if (!visible.value) {
        return;
      }
      visible.value = false;
      modalOps.unregisterModal('auth');
      lockEmailField.value = false;
      closeWarningMsg();
      closeErrNotif();
      resetForms();
      formType.value = 1;
      clearCallbacks();
    };

    /**
     * Toggle between forms
     * @param {Number} num form number
     */
    const switchForms = num => {
      if (!lockEmailField.value && num !== formType.value && !loading.value) {
        closeErrNotif();
        resetForms();
        formType.value = num;
      }
    };

    /**
     * Reset all Login and Signup forms. By default, resets all fields.
     * @param {Boolean} fullReset default true
     */
    const resetForms = (fullReset = true) => {
      if (fullReset) {
        loginForm.email = initialEmail();
        newAccountForm.firstName = '';
        newAccountForm.lastName = '';
        newAccountForm.email = '';
        newAccountForm.optin = true;
      }
      loginForm.password = VUE_APP_SERVER === 'dev' && VUE_APP_TEST_USER_PW ? VUE_APP_TEST_USER_PW : '';
      newAccountForm.password = '';
      newAccountForm.confirmPassword = '';
      for (let i = 0; i < validationConditions.value.length; i++) validationConditions.value[i] = "";
    };

    /**
     * Closes the login message
     */
    const closeLoginMsg = () => {
      if (loginMsg && typeof loginMsg.close === 'function') {
        loginMsg.close();
        loginMsg = null;
      }
    };

    /**
     * Close the Warning Message
     */
    const closeWarningMsg = () => {
      if (warningMsg && typeof warningMsg.close === "function") {
        warningMsg.close();
        warningMsg = null;
      }
    };

    /**
     * Handles user login or account creation by calling the appropriate method on the user class
     * Defaults to a post-success destination of "Overview" (/overview) unless the user created an
     * account explicitly to check out a purchase, in which case the destination is changed to the
     * "Checkout" view.
     * @param method    'login' || 'create'
     * @param form      { ...loginForm } || { ...newAccountForm }
     */
    const handleLoginOrCreate = async (method, form) => {
      try {
        loading.value = true;
        closeLoginMsg();
        closeErrNotif();
        await user[method](form);
        if (method === 'login') {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'login',
            method: 'password'
          });
        } else {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'sign_up',
            method: 'password'
          });
        }
        if (ir?.loaded && typeof ir?.identify === 'function') {
          ir.identify();
        }
        loginMsg = ElMessage({
          onClick: () => loginMsg.close(),
          message: 'Login Successful',
          type: 'success',
          duration: 4000,
          customClass: 'login-message',
          offset: 20
        });
        if (cbs.value.length) {
          cbs.value.forEach(cb => {
            try {
              cb();
            } catch (e) {
              console.error(e);
            }
          });
        } else {
          const targetLang = user.language_preference || localize.currCode.value;
          updateCurrentRoute(targetLang);
        }
        closeModal();
      } catch (e) {
        resetForms(handleAuthError(e.message));
      } finally {
        loading.value = false;
      }
    };

    /**
     * Validates the new account form
     */
    const validate = () => {
      const {
        firstName,
        lastName,
        email,
        password,
        confirmPassword
      } = newAccountForm;
      const iconValid = markRaw(CircleCheck);
      const iconInvalid = markRaw(CircleClose);
      const pwMatches = () => password && confirmPassword && password === confirmPassword ? iconValid : iconInvalid;
      const pwMeetsCriteria = () => password && validatePw(password) ? iconValid : iconInvalid;
      const pwFieldsValid = () => {
        if (validationConditions.value[3] === iconValid && validationConditions.value[4] === iconValid) {
          return iconValid;
        }
        return iconInvalid;
      };
      const toggleInfoClass = el => {
        el.classList.remove('el-alert--success');
        el.classList.add('el-alert--error');
      };
      const toggleSuccessClass = el => {
        el.classList.remove('el-alert--error');
        el.classList.add('el-alert--success');
      };
      validationConditions.value[0] = firstName ? iconValid : '';
      validationConditions.value[1] = lastName ? iconValid : '';
      if (email === '') {
        validationConditions.value[2] = '';
      } else if (email) {
        validationConditions.value[2] = validateEmail(email) ? iconValid : iconInvalid;
      }
      if (password === '') {
        validationConditions.value[3] = '';
        toggleInfoClass(pwRequirement1.value.$el);
      } else if (password) {
        validationConditions.value[3] = pwMeetsCriteria();
      }
      if (confirmPassword === '') {
        validationConditions.value[4] = '';
        toggleInfoClass(pwRequirement2.value.$el);
      } else if (confirmPassword) {
        validationConditions.value[4] = pwMatches();
      }
      if (validationConditions.value[3] === iconValid) {
        toggleSuccessClass(pwRequirement1.value.$el);
      } else {
        toggleInfoClass(pwRequirement1.value.$el);
      }
      if (validationConditions.value[4] === iconValid) {
        toggleSuccessClass(pwRequirement2.value.$el);
      } else {
        toggleInfoClass(pwRequirement2.value.$el);
      }
      if (password === '' && confirmPassword === '') {
        validationConditions.value[5] = '';
      } else if (password && confirmPassword) {
        validationConditions.value[5] = pwFieldsValid();
      }
    };
    const exposed = {
      addCallback,
      clearCallbacks,
      openModal,
      closeModal
    };
    __expose(exposed);
    watch(AuthModalEventBus, (newVal, oldVal) => {
      if (newVal && newVal !== oldVal) {
        try {
          newVal(exposed);
        } catch (e) {
          console.error(e);
        } finally {
          store.commit("setEventBusCallbackFunction", {
            name: "AuthModalRequest"
          });
        }
      }
    });
    watch(user, () => {
      const isAuthenticated = user?.id;
      if (route.meta.requiresAuth && !isAuthenticated) {
        router.replace({
          name: "Home"
        });
      }
    });

    // Close modal on current Tab/Window if Authentication has completed on another Tab/Window
    watch(user, () => {
      if (user?.id && !loading.value) {
        closeModal();
      }
    });
    onMounted(() => {
      loginMsg = null;
    });
    onBeforeUnmount(() => {
      closeLoginMsg();
      closeWarningMsg();
      closeErrNotif();
    });
    router.beforeEach((to, from, next) => {
      let isAuthenticated = user?.id;
      if (user?.email && to.query.loginEmail && user?.email !== to.query.loginEmail) {
        user.logout();
        isAuthenticated = 0;
      }
      if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
        // catch-all logic to redirect user to originally requested url
        const sourceLang = from?.meta?.lang;
        const targetLang = to.meta?.lang || sourceLang || localize.currCode.value || localize.userLangs.value?.[0];
        let destination = translateRoute({
          name: 'Home'
        }, targetLang, sourceLang);
        if (from && (from.name || from.path) && from.meta.appLoaded && !from.meta.requiresAuth) {
          destination = from;
        }
        if (to.query.loginEmail) {
          loginForm.email = to.query.loginEmail;
          lockEmailField.value = true;
          delete to.query.loginEmail;
        }
        destination.redirectedFrom = to;
        if (!destination.params) {
          destination.params = {};
        }
        destination.params.pretranslated = true;
        openModal({
          ...to
        });
        isRedirecting = true;
        next(destination);
      } else {
        if (!(isRedirecting || compareRoutes(to, from))) {
          closeModal();
        }
        next();
        isRedirecting = false;
      }
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: visible.value,
        "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => visible.value = $event),
        "append-to-body": "",
        center: "",
        fullscreen: ['xs'].includes(_unref(currentBP)),
        "show-close": false,
        "close-on-click-modal": !loading.value,
        "close-on-press-escape": !loading.value,
        class: "auth_dialog",
        onClose: _cache[17] || (_cache[17] = $event => closeModal())
      }, {
        header: _withCtx(() => [_createVNode(_component_el_col, {
          class: "flex-col"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            justify: "end",
            class: "std-padding_line-md"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              onClick: _cache[0] || (_cache[0] = $event => closeModal()),
              type: "primary",
              icon: _unref(CircleClose),
              circle: "",
              plain: "",
              "data-testid": "closeAuthModal"
            }, null, 8, ["icon"])]),
            _: 1
          }), _createVNode(_component_el_row, {
            type: "flex",
            align: "bottom",
            justify: "center",
            class: "form-select-btns"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12,
              onClick: _cache[1] || (_cache[1] = $event => switchForms(1))
            }, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [_createElementVNode("p", {
                style: {
                  "line-height": "1em",
                  "font-size": "1em",
                  "min-height": "2em"
                },
                "data-testid": "switchToSignIn"
              }, [_createElementVNode("strong", null, "SIGN IN")], -1)])),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12,
              onClick: _cache[2] || (_cache[2] = $event => switchForms(0))
            }, {
              default: _withCtx(() => _cache[19] || (_cache[19] = [_createElementVNode("p", {
                style: {
                  "line-height": "1em",
                  "font-size": "1em",
                  "min-height": "2em"
                },
                "data-testid": "switchToCreateAccount"
              }, [_createElementVNode("strong", null, "CREATE AN ACCOUNT")], -1)])),
              _: 1
            }), _createElementVNode("div", {
              class: _normalizeClass(["active-bar", {
                offset: formType.value === 0
              }])
            }, null, 2)]),
            _: 1
          })]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_el_row, {
          justify: "center",
          align: "middle"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            style: {
              "max-width": "500px"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_el_row, null, {
              default: _withCtx(() => [_createVNode(_Transition, {
                name: "fast-fade"
              }, {
                default: _withCtx(() => [formType.value === 0 ? (_openBlock(), _createBlock(_component_el_col, {
                  key: "sign-up"
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_form, {
                    ref: el => formElems['create'] = el,
                    "label-position": ['xs', 'sm'].includes(_unref(currentBP)) ? 'top' : 'left',
                    "label-width": "100px",
                    model: newAccountForm,
                    onKeyup: _cache[9] || (_cache[9] = _withKeys(e => handleEnter(e, 'create'), ["enter"]))
                  }, {
                    default: _withCtx(() => [_createVNode(_component_el_row, null, {
                      default: _withCtx(() => [_createVNode(_component_el_col, null, {
                        default: _withCtx(() => [_createVNode(_component_el_form_item, {
                          label: "E-mail"
                        }, {
                          default: _withCtx(() => [_createVNode(_component_el_input, {
                            placeholder: "john@doe.com",
                            modelValue: newAccountForm.email,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => newAccountForm.email = $event),
                            disabled: loading.value || lockEmailField.value,
                            "data-testid": "emailField"
                          }, null, 8, ["modelValue", "disabled"])]),
                          _: 1
                        })]),
                        _: 1
                      })]),
                      _: 1
                    }), _createVNode(_component_el_row, {
                      gutter: 10
                    }, {
                      default: _withCtx(() => [_createVNode(_component_el_col, {
                        md: 12
                      }, {
                        default: _withCtx(() => [_createVNode(_component_el_form_item, {
                          label: "First name",
                          class: "form-item"
                        }, {
                          default: _withCtx(() => [_createVNode(_component_el_input, {
                            placeholder: "John",
                            modelValue: newAccountForm.firstName,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => newAccountForm.firstName = $event),
                            onInput: validate,
                            "suffix-icon": validationConditions.value[0],
                            disabled: loading.value,
                            "data-testid": "firstNameField"
                          }, null, 8, ["modelValue", "suffix-icon", "disabled"])]),
                          _: 1
                        })]),
                        _: 1
                      }), _createVNode(_component_el_col, {
                        md: 12
                      }, {
                        default: _withCtx(() => [_createVNode(_component_el_form_item, {
                          label: "Last name",
                          class: "form-item"
                        }, {
                          default: _withCtx(() => [_createVNode(_component_el_input, {
                            placeholder: "Doe",
                            modelValue: newAccountForm.lastName,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => newAccountForm.lastName = $event),
                            onInput: validate,
                            "suffix-icon": validationConditions.value[1],
                            disabled: loading.value,
                            "data-testid": "lastNameField"
                          }, null, 8, ["modelValue", "suffix-icon", "disabled"])]),
                          _: 1
                        })]),
                        _: 1
                      })]),
                      _: 1
                    }), _createVNode(_component_el_form_item, {
                      label: "Password",
                      class: "form-item"
                    }, {
                      default: _withCtx(() => [_createVNode(_component_el_input, {
                        placeholder: "Set password",
                        modelValue: newAccountForm.password,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => newAccountForm.password = $event),
                        "show-password": "",
                        "suffix-icon": validationConditions.value[5],
                        onInput: validate,
                        disabled: loading.value,
                        "data-testid": "passwordField"
                      }, null, 8, ["modelValue", "suffix-icon", "disabled"]), _createElementVNode("label", null, [_createVNode(Tag, {
                        ref_key: "pwRequirement1",
                        ref: pwRequirement1,
                        type: validationConditions.value[3] === _unref(CircleCheck) ? 'success' : 'danger',
                        class: "pw-requirement"
                      }, {
                        default: _withCtx(() => _cache[20] || (_cache[20] = [_createElementVNode("p", {
                          class: "pw-requirement-text"
                        }, "6+ capital and lowercase letters and numbers", -1)])),
                        _: 1
                      }, 8, ["type"])])]),
                      _: 1
                    }), _createVNode(_component_el_form_item, {
                      label: "Confirm",
                      class: "form-item"
                    }, {
                      default: _withCtx(() => [_createVNode(_component_el_input, {
                        placeholder: "Confirm password",
                        modelValue: newAccountForm.confirmPassword,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => newAccountForm.confirmPassword = $event),
                        "show-password": "",
                        "suffix-icon": validationConditions.value[5],
                        onInput: validate,
                        disabled: loading.value,
                        "data-testid": "passwordConfirmField"
                      }, null, 8, ["modelValue", "suffix-icon", "disabled"]), _createElementVNode("label", null, [_createVNode(Tag, {
                        ref_key: "pwRequirement2",
                        ref: pwRequirement2,
                        type: validationConditions.value[4] === _unref(CircleCheck) ? 'success' : 'danger',
                        class: "pw-requirement"
                      }, {
                        default: _withCtx(() => [_createElementVNode("p", _hoisted_1, _toDisplayString(validationConditions.value[4] === _unref(CircleCheck) ? "Passwords match" : "Passwords don't match"), 1)]),
                        _: 1
                      }, 8, ["type"])])]),
                      _: 1
                    }), _createVNode(_component_el_form_item, {
                      class: "form-item"
                    }, {
                      default: _withCtx(() => [_createVNode(_component_el_row, {
                        align: "middle",
                        justify: "start"
                      }, {
                        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_checkbox, {
                          modelValue: newAccountForm.optin,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => newAccountForm.optin = $event),
                          checked: newAccountForm.optin,
                          disabled: loading.value,
                          "data-testid": "getUpdatesCheckbox"
                        }, null, 8, ["modelValue", "checked", "disabled"])]), _cache[21] || (_cache[21] = _createElementVNode("div", {
                          class: "flex-col",
                          style: {
                            "flex": "1"
                          }
                        }, [_createElementVNode("p", {
                          style: {
                            "font-size": "12px",
                            "line-height": "13px"
                          }
                        }, "Get updates about our products, company, and special offers")], -1))]),
                        _: 1
                      })]),
                      _: 1
                    })]),
                    _: 1
                  }, 8, ["label-position", "model"])]),
                  _: 1
                })) : (_openBlock(), _createBlock(_component_el_col, {
                  key: "sign-in"
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_form, {
                    ref: el => formElems['login'] = el,
                    "label-position": _unref(currentBP) === 'xs' ? 'top' : 'left',
                    "label-width": "100px",
                    model: loginForm,
                    onKeyup: _cache[12] || (_cache[12] = _withKeys(e => handleEnter(e, 'login'), ["enter"]))
                  }, {
                    default: _withCtx(() => [_createVNode(_component_el_form_item, {
                      label: "E-mail"
                    }, {
                      default: _withCtx(() => [_createVNode(_component_el_input, {
                        placeholder: "john@doe.com",
                        modelValue: loginForm.email,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => loginForm.email = $event),
                        "data-testid": "emailInput",
                        disabled: loading.value || lockEmailField.value
                      }, null, 8, ["modelValue", "disabled"])]),
                      _: 1
                    }), _createVNode(_component_el_form_item, {
                      label: "Password"
                    }, {
                      default: _withCtx(() => [_createVNode(_component_el_input, {
                        placeholder: "********",
                        modelValue: loginForm.password,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => loginForm.password = $event),
                        "data-testid": "passwordInput",
                        "show-password": "",
                        disabled: loading.value
                      }, null, 8, ["modelValue", "disabled"])]),
                      _: 1
                    })]),
                    _: 1
                  }, 8, ["label-position", "model"])]),
                  _: 1
                }))]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_row, {
          type: "flex",
          align: "middle",
          justify: "center"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 8,
            xs: 24
          }, {
            default: _withCtx(() => [_createVNode(_component_el_row, {
              class: "std-padding_line-sm"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_button, {
                type: "primary",
                onClick: _cache[13] || (_cache[13] = $event => handleLoginOrCreate(formType.value ? 'login' : 'create', {
                  ...(formType.value ? loginForm : newAccountForm)
                })),
                loading: loading.value,
                disabled: !formType.value && !formIsValid.value,
                style: {
                  "width": "100%"
                },
                "data-testid": "submitButton"
              }, {
                default: _withCtx(() => _cache[22] || (_cache[22] = [_createTextVNode(" Submit ")])),
                _: 1
              }, 8, ["loading", "disabled"])]),
              _: 1
            }), _createVNode(_component_el_row, {
              type: "flex",
              align: "middle",
              justify: "center"
            }, {
              default: _withCtx(() => [formType.value ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["forgot-password", {
                  disabled: loading.value
                }]),
                onClick: _cache[14] || (_cache[14] = () => {
                  if (!loading.value) showPwResetModal.value = true;
                }),
                "data-testid": "forgotPassword"
              }, " Forgot Password ", 2)) : (_openBlock(), _createElementBlock("br", _hoisted_3))]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_unref(ResetPasswordModal), {
          visible: showPwResetModal.value,
          onClose: _cache[15] || (_cache[15] = $event => showPwResetModal.value = false)
        }, null, 8, ["visible"])]),
        _: 1
      }, 8, ["modelValue", "fullscreen", "close-on-click-modal", "close-on-press-escape"]);
    };
  }
};