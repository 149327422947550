import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { ref } from 'vue';
import { onBeforeRouteUpdate } from 'vue-router';
export default {
  __name: 'WpFaqContainer',
  setup(__props) {
    const hardReload = ref(false);

    // Reset FAQ section on page transitions. EL+ lacks methods to control collapse component
    onBeforeRouteUpdate(() => {
      hardReload.value = true;
      setTimeout(() => {
        hardReload.value = false;
      }, 500);
    });
    return (_ctx, _cache) => {
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_collapse = _resolveComponent("el-collapse");
      const _component_el_col = _resolveComponent("el-col");
      return _openBlock(), _createBlock(_component_el_row, {
        justify: "center",
        align: "middle",
        class: "std-section-padding"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, null, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            justify: "center",
            align: "middle",
            class: "std-padding_line-xl"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("h2", {
              class: "size-page-section-header",
              style: {
                "text-align": "center"
              }
            }, "Frequently Asked Questions", -1)])),
            _: 1
          }), !hardReload.value ? (_openBlock(), _createBlock(_component_el_collapse, {
            key: 0,
            accordion: "",
            class: "accordion",
            style: {
              "--el-collapse-header-height": "auto"
            }
          }, {
            default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
            _: 3
          })) : _createCommentVNode("", true)]),
          _: 3
        })]),
        _: 3
      });
    };
  }
};