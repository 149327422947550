import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { inject, computed } from 'vue';
import WpElButton from '@/components/WpComponents/WpElButton.vue';
export default {
  __name: 'TryFreeBtn',
  setup(__props) {
    const user = inject('user');
    const buttonData = computed(() => {
      const userSignedIn = user && user.id;
      let buttonObject = {
        visible: true,
        text: 'FREE TRIAL'
      };
      if (!userSignedIn) {
        return buttonObject;
      }
      const isActiveSubscriber = user?.summary?.isActiveSubscriber;
      const isActiveTrialer = user?.summary?.isActiveTrialer;
      const hasAvailableTrial = user?.hasAvailableTrial;
      if (isActiveSubscriber || isActiveTrialer) {
        buttonObject.visible = false;
      } else if (!hasAvailableTrial) {
        buttonObject.text = 'Subscribe Now';
      }
      return buttonObject;
    });
    return (_ctx, _cache) => {
      return buttonData.value.visible ? (_openBlock(), _createBlock(WpElButton, {
        key: 0,
        action: "add_to_cart",
        value: 'auto-tune-unlimited-monthly-14d-free',
        type: "primary",
        class: "large"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(buttonData.value.text), 1)]),
        _: 1
      })) : _createCommentVNode("", true);
    };
  }
};