import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "spinner-container"
};
import { onMounted, onUnmounted, reactive, ref } from 'vue';
import { onBeforeRouteUpdate } from 'vue-router';
import WpHttpClient from '@/classes/Wp';
import WaveSpinner from '@/components/WaveSpinner';
import Carousel from "@/components/Carousel/Carousel.vue";
export default {
  __name: 'WpTestimonials',
  props: {
    slugs: {
      type: String,
      required: true,
      validator: prop => {
        return prop !== '';
      }
    }
  },
  setup(__props) {
    const props = __props;
    const state = reactive({
      loading: false,
      data: null,
      error: false
    });
    const isVisible = ref(false);
    const lazyLoadContainer = ref(null);
    let observer = null;

    // Methods

    const initialize = () => {
      fetchData(props.slugs);
    };
    const onIntersect = entries => {
      if (entries[0].isIntersecting) {
        isVisible.value = true;
        initialize();
        observer.disconnect(); // Stop observing after loading the data
      }
    };

    /**
     * Fetches the testimonials from the WP CMS. If a slugs props of type Array is passed,
     * fetches only the testimonials whose WP page slug matches the slugs passed in.
     */
    const fetchData = async slugs => {
      state.loading = true;
      state.error = false;
      try {
        const payload = {
          namespace: '/antares/v1',
          path: '/testimonials',
          query: {
            s: slugs
          }
        };
        const resp = await new WpHttpClient(payload).get();
        if (!(resp.data && Array.isArray(resp.data))) {
          throw new Error('Missing/invalid data property in response');
        }
        state.data = resp.data.filter(v => {
          return v?.meta?.testimonial_author.length && v?.meta?.testimonial_quote.length && (v?.photo[0] || v?.photo_m[0]);
        });
      } catch (e) {
        if (e.message !== "canceled") {
          console.warn(e);
          state.error = true;
        }
      } finally {
        state.loading = false;
      }
    };

    // Lifecycle hooks

    onMounted(() => {
      observer = new IntersectionObserver(onIntersect, {
        root: null,
        // Use the viewport as the root
        threshold: 0.1 // Trigger when 10% of the element is visible
      });
      if (lazyLoadContainer.value) {
        observer.observe(lazyLoadContainer.value);
      }
    });
    onUnmounted(() => {
      if (observer && lazyLoadContainer.value) {
        observer.unobserve(lazyLoadContainer.value);
      }
    });

    // Router guards

    onBeforeRouteUpdate((to, from) => {
      if (to.path !== from.path) {
        initialize();
      }
    });
    return (_ctx, _cache) => {
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_col = _resolveComponent("el-col");
      return !state.error ? (_openBlock(), _createBlock(_component_el_row, {
        key: 0,
        align: "middle",
        justify: "center"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          class: "testimonials-wrapper"
        }, {
          default: _withCtx(() => [_createElementVNode("div", {
            ref_key: "lazyLoadContainer",
            ref: lazyLoadContainer,
            class: "lazy-load-container"
          }, [!state.loading ? (_openBlock(), _createBlock(_component_el_row, {
            key: 0,
            class: "testimonial-container",
            align: "middle",
            justify: "center"
          }, {
            default: _withCtx(() => [_createVNode(Carousel, {
              testimonials: state.data
            }, null, 8, ["testimonials"])]),
            _: 1
          })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(WaveSpinner))]))], 512)]),
          _: 1
        })]),
        _: 1
      })) : _createCommentVNode("", true);
    };
  }
};