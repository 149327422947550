import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "FAQ-header"
};
const _hoisted_2 = {
  class: "FAQ-body"
};
export default {
  __name: 'WpFaqEntry',
  props: {
    question: String
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_el_collapse_item = _resolveComponent("el-collapse-item");
      return _openBlock(), _createBlock(_component_el_collapse_item, {
        class: "FAQ-entry"
      }, {
        title: _withCtx(() => [_createElementVNode("h3", _hoisted_1, [_renderSlot(_ctx.$slots, "question", {}, () => [_createTextVNode(_toDisplayString(__props.question), 1)])])]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default")])]),
        _: 3
      });
    };
  }
};