import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  style: {
    "padding-left": "10px",
    "padding-right": "10px"
  }
};
const _hoisted_2 = {
  class: "dots"
};
const _hoisted_3 = ["onClick"];
import { reactive, computed, ref, onMounted, onUnmounted } from 'vue';
import WpTestimonial from '../WpComponents/WpTestimonial.vue';
export default {
  __name: 'Carousel',
  props: {
    testimonials: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  setup(__props) {
    const props = __props;
    const carousel = ref(null);
    const state = reactive({
      slides: props.testimonials,
      currentIndex: 0,
      startX: 0,
      currentTranslate: 0,
      isDragging: false
    });

    /**
     * Get the current width of the carousel
     */
    const getCurrentWidth = () => carousel.value?.clientWidth || 0;

    /**
     * Set slide X translate value when horizontally dragging or scrolling
     */
    const slideStyle = computed(() => {
      return {
        transform: `translateX(${state.currentTranslate}px)`,
        transition: state.isDragging ? 'none' : 'transform 0.3s ease'
      };
    });

    /**
     * Go to the slide at the given index
     * @param index 
     */
    const goToSlide = index => {
      state.currentIndex = index;
      state.currentTranslate = -index * getCurrentWidth();
    };
    const onTouchStart = event => {
      state.startX = event.touches[0].clientX;
      state.isDragging = true;
    };

    /**
     * Handle touch move event
     * @param event 
     */

    const onTouchMove = event => {
      if (!state.isDragging) return;
      const moveX = event.touches[0].clientX - state.startX;
      state.currentTranslate = -state.currentIndex * getCurrentWidth() + moveX;
    };

    /**
     * Handle touch end event
     * @param event 
     */
    const onTouchEnd = event => {
      state.isDragging = false;
      const threshold = getCurrentWidth() / 4; // Threshold for swipe

      const moveX = event.changedTouches[0].clientX - state.startX;
      if (moveX < -threshold) {
        nextSlide();
      } else if (moveX > threshold) {
        prevSlide();
      } else {
        // Reset to the current slide
        state.currentTranslate = -state.currentIndex * getCurrentWidth();
      }
    };

    /**
     * Move to the next slide
     */
    const nextSlide = () => {
      state.currentIndex = (state.currentIndex + 1) % state.slides.length;
      state.currentTranslate = -state.currentIndex * getCurrentWidth();
    };

    /**
     * Move to the previous slide
     */
    const prevSlide = () => {
      state.currentIndex = (state.currentIndex - 1 + state.slides.length) % state.slides.length;
      state.currentTranslate = -state.currentIndex * getCurrentWidth();
    };

    /**
     * Handle resize event
     */
    const handleResize = () => {
      state.currentTranslate = -state.currentIndex * getCurrentWidth();
    };
    onMounted(() => {
      goToSlide(state.currentIndex);
      window.addEventListener('resize', handleResize);
    });
    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        ref_key: "carousel",
        ref: carousel,
        class: "carousel",
        onTouchstart: onTouchStart,
        onTouchmove: onTouchMove,
        onTouchend: onTouchEnd
      }, [_createElementVNode("div", {
        class: "slides",
        style: _normalizeStyle(slideStyle.value)
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.slides, (slide, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "slide",
          key: index
        }, [_createElementVNode("div", _hoisted_1, [_createVNode(WpTestimonial, {
          data: slide
        }, null, 8, ["data"])])]);
      }), 128))], 4), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.slides, (slide, index) => {
        return _openBlock(), _createElementBlock("span", {
          key: index,
          class: _normalizeClass(['dot', {
            active: index === state.currentIndex
          }]),
          onClick: $event => goToSlide(index)
        }, null, 10, _hoisted_3);
      }), 128))])], 544);
    };
  }
};