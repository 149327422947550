import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref, computed, inject, onUnmounted } from 'vue';
export default {
  __name: 'CarouselSlide',
  props: {
    name: {
      type: String,
      required: false,
      default: null
    }
  },
  setup(__props) {
    const props = __props;
    const assignSlideNum = inject('assignSlideNum', null);
    const active_slide = inject('activeSlide', ref(-1));
    const remove_slide = inject('removeSlideNum', () => {});
    const transitionName = inject('transitionName', "fade");
    const slide_num = ref(-1);
    if (assignSlideNum) {
      assignSlideNum(slide_num, props.name);
    }
    const showSlide = computed(() => {
      return slide_num.value === active_slide.value;
    });
    onUnmounted(() => {
      if (props.name) {
        remove_slide(props.name);
      } else {
        remove_slide(slide_num.value);
      }
    });
    return (_ctx, _cache) => {
      const _component_el_container = _resolveComponent("el-container");
      return _openBlock(), _createBlock(_Transition, {
        name: _unref(transitionName)
      }, {
        default: _withCtx(() => [_withDirectives(_createVNode(_component_el_container, {
          class: "fill-container"
        }, {
          default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
          _: 3
        }, 512), [[_vShow, showSlide.value]])]),
        _: 3
      }, 8, ["name"]);
    };
  }
};