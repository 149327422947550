import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition } from "vue";
const _hoisted_1 = {
  class: "flex-col flex-centered fullheight",
  style: {
    "padding-right": "20px"
  }
};
const _hoisted_2 = {
  class: "flex-col flex-centered",
  style: {
    "flex": "0 1 100%"
  }
};
const _hoisted_3 = {
  key: 0,
  class: "flex-col flex-centered fullheight",
  style: {
    "box-sizing": "border-box",
    "padding-right": "20px"
  }
};
const _hoisted_4 = ["href"];
const _hoisted_5 = {
  key: 2,
  class: "icon-container"
};
const _hoisted_6 = ["href"];
const _hoisted_7 = ["data-key"];
const _hoisted_8 = ["href"];
const _hoisted_9 = ["data-key"];
const _hoisted_10 = ["href"];
const _hoisted_11 = {
  class: "flex-col nav-overlay-col newsletter hidden-xs-only"
};
import { ref, computed, inject, watch, nextTick, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { CloseBold, ShoppingCart, ShoppingCartFull, UserFilled } from '@element-plus/icons-vue';
import NavMenu from "./NavMenu.json";
import NavMenuLang from "./NavMenuLang.vue";
import Newsletter from '@/components/Newsletter.vue';
import SocialLinks from '@/components/SocialLinks.vue';
import { currentBP, registerResize, removeResize } from '@/composables/windowWatcher';
import { useFsLoading } from '@/classes/FastSpring';
export default {
  __name: 'NavMenuOverlay',
  props: {
    visible: Boolean
  },
  emits: ['close'],
  setup(__props, {
    emit: __emit
  }) {
    const cart = inject('cart');
    const user = inject('user');
    const route = useRoute();
    const fsLoading = useFsLoading();
    const props = __props;
    const emit = __emit;
    const openState = computed({
      get: () => {
        return props.visible;
      },
      set: () => {
        handleClose();
      }
    });
    const mainMenu = ref(null);
    const subMenuRefs = ref(null);
    const activeSubMenu = ref(-1);
    const heights = ref([]);
    const copy = JSON.parse(JSON.stringify(NavMenu));
    const navMenuEntries = ref(copy.filter(item => {
      if (item.submenu) {
        item.submenu = item.submenu.filter(subitem => !subitem.hideFromOverlay);
      }
      return !item.hideFromOverlay;
    }));
    const subMenus = computed(() => {
      return navMenuEntries.value.filter(e => {
        return e.submenu;
      });
    });
    const mainMenuHeight = computed(() => {
      let height = 0;
      if (mainMenu.value?.clientHeight) {
        height = mainMenu.value.clientHeight;
      }
      return height;
    });
    const minMaxSubMenu = computed(() => {
      let val = 0;
      if (!['xs', 'sm'].includes(currentBP) || subMenus.value?.length) {
        val = Math.max(...subMenus.value.map((e, i) => {
          return getSubMenuHeight(i);
        }));
      }
      return `${val}px`;
    });
    const lineHeight = computed(() => {
      let height = 0;
      if (mainMenu.value?.children[0]?.clientHeight) {
        height = mainMenu.value.children[0].clientHeight;
      }
      return height;
    });
    const getSubMenuHeight = index => {
      let height = 0;
      if (resizeWatchers[index]?.height) {
        height = resizeWatchers[index].height;
      }
      return height;
    };

    /**
     * Calc offset from center of alignment for transform anchor to occur
     * Will return a value that will be added or subtracted from Y midpoint
     */
    const menuOffset = index => {
      let middleOffset = 0;
      const mainMenuIndex = navMenuEntries.value.findIndex(e => {
        return e == subMenus.value[index];
      });
      const numEntries = navMenuEntries.value.length;
      if (numEntries <= 1) {
        // Shouldn't ever happen, but just in case
        return middleOffset;
      }
      const middleIndex = Math.ceil(numEntries / 2) - 1;

      // Determine if slight offset is needed; this is not needed when mainMenu entries are odd
      if (numEntries % 2 === 0) {
        middleOffset -= lineHeight.value / 2; // Account for active line in Main Menu; should center anchor on line
      }
      const numLines = middleIndex - mainMenuIndex;
      middleOffset += -1 * lineHeight.value * numLines; // Offset from center

      return middleOffset;
    };
    const contentAnchor = index => {
      let middleOffset = 0;
      const subMenuHeight = getSubMenuHeight(index);
      const transformRel = menuOffset(index);
      const transformPos = mainMenuHeight.value / 2 + transformRel; // Convert to position relative to top of container

      const closerBound = Math.min(transformPos, mainMenuHeight.value - transformPos);
      if (subMenuHeight / 2 <= closerBound) {
        // Sub Menu can sit directly on Anchor
        middleOffset = transformRel - subMenuHeight / 2;
      } else if (mainMenuHeight.value > subMenuHeight) {
        const baseOffset = transformRel - subMenuHeight / 2;
        let overflow = Math.min(mainMenuHeight.value - (transformPos + subMenuHeight / 2), transformPos);
        middleOffset = baseOffset + overflow;
      } else {
        middleOffset -= subMenuHeight / 2;
      }
      return middleOffset;
    };
    const relativeContentAnchor = index => {
      let res = mainMenuHeight.value / 2 + contentAnchor(index);
      return res > 0 ? res : 0;
    };
    const transformAnchor = index => {
      return menuOffset(index) - contentAnchor(index);
    };
    const cartToggle = () => {
      if (!fsLoading.value) {
        cart.toggleVisibility();
      }
    };
    const handleClose = () => {
      emit('close');
      setTimeout(() => activeSubMenu.value = -1, 500);
    };

    /**
     * Handles clicking on a submenu item. If submenu item's parent object contains a property 'track', then
     * then an event object is 'pushed' into the GA dataLayer object.
     * @param {object} item
     * @param {object} subMenu
     */
    const handleSubmenuClick = (item, subMenu) => {
      if (subMenu.track) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'view_item',
          ecommerce: {
            items: [{
              item_name: item.name
            }]
          }
        });
      }
    };
    const toggleSubmenu = (paths = []) => {
      let index = subMenus.value?.indexOf(paths);
      if (activeSubMenu.value === index) {
        activeSubMenu.value = -1;
      } else {
        activeSubMenu.value = index;
      }
    };
    const resizeWatchers = reactive({});
    watch(openState, () => {
      nextTick(() => {
        if (openState.value) {
          for (let entry of heights.value) {
            let index = entry.getAttribute('data-key');
            resizeWatchers[index] = registerResize(entry);
          }
        } else {
          for (let entry of heights.value) {
            removeResize(entry);
          }
        }
      });
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_router_link = _resolveComponent("router-link");
      const _component_el_badge = _resolveComponent("el-badge");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_container = _resolveComponent("el-container");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createBlock(_component_el_dialog, {
        class: "nav-overlay-dialog",
        modelValue: openState.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => openState.value = $event),
        "show-close": false,
        fullscreen: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_el_container, {
          direction: "vertical",
          class: "fill-container"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            class: "topbar"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_col, null, {
              default: _withCtx(() => [_createVNode(_component_el_row, {
                align: "middle",
                class: "fill-container",
                style: {
                  "flex-wrap": "nowrap"
                }
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_button, {
                  class: "white-base",
                  size: "large",
                  type: "primary",
                  plain: "",
                  onClick: handleClose,
                  icon: _unref(CloseBold),
                  circle: ""
                }, null, 8, ["icon"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
                  justify: "end",
                  align: "middle",
                  class: "fill-container",
                  style: {
                    "box-sizing": "border-box",
                    "flex-wrap": "nowrap"
                  }
                }, {
                  default: _withCtx(() => [_unref(route).name !== 'Overview' && !_unref(user).state.logoutLoading ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_router_link, {
                    to: {
                      name: 'Overview'
                    },
                    onClick: _cache[0] || (_cache[0] = $event => handleClose())
                  }, {
                    default: _withCtx(() => [_createVNode(_component_el_button, {
                      class: "white-base",
                      size: "large",
                      type: "primary",
                      plain: "",
                      icon: _unref(UserFilled),
                      circle: "",
                      disabled: _unref(user).state.logoutLoading
                    }, null, 8, ["icon", "disabled"])]),
                    _: 1
                  })])) : _createCommentVNode("", true), _unref(route).name !== 'Checkout' ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    onClick: _cache[1] || (_cache[1] = () => cartToggle()),
                    class: "flex-col flex-centered fullheight",
                    style: {
                      "box-sizing": "border-box"
                    }
                  }, [_unref(cart).totalItems && typeof _unref(cart).totalItems === 'number' && _unref(cart).totalItems > 0 ? (_openBlock(), _createBlock(_component_el_badge, {
                    key: 0,
                    type: "primary",
                    value: _unref(cart).totalItems,
                    class: "cart-badge"
                  }, {
                    default: _withCtx(() => [_createVNode(_component_el_button, {
                      disabled: _unref(fsLoading),
                      loading: _unref(fsLoading),
                      class: "white-base",
                      size: "large",
                      type: "primary",
                      plain: "",
                      icon: _unref(ShoppingCartFull),
                      circle: ""
                    }, null, 8, ["disabled", "loading", "icon"])]),
                    _: 1
                  }, 8, ["value"])) : (_openBlock(), _createBlock(_component_el_button, {
                    key: 1,
                    disabled: _unref(fsLoading),
                    loading: _unref(fsLoading),
                    class: "white-base",
                    size: "large",
                    type: "primary",
                    plain: "",
                    icon: _unref(ShoppingCart),
                    circle: ""
                  }, null, 8, ["disabled", "loading", "icon"]))])) : _createCommentVNode("", true)]),
                  _: 1
                })])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_row, {
            align: "middle",
            class: "nav-overlay-body"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_col, null, {
              default: _withCtx(() => [_createVNode(_component_el_row, {
                align: "middle",
                class: "main-row",
                style: _normalizeStyle({
                  'min-height': _unref(currentBP) !== 'xs' ? minMaxSubMenu.value : 'auto'
                })
              }, {
                default: _withCtx(() => [_createElementVNode("div", {
                  class: "flex-col nav-overlay-col",
                  style: _normalizeStyle({
                    'min-height': _unref(currentBP) !== 'xs' ? minMaxSubMenu.value : 'auto'
                  })
                }, [_createVNode(_component_el_row, {
                  align: "middle",
                  style: {
                    "flex-wrap": "nowrap"
                  }
                }, {
                  default: _withCtx(() => [_createElementVNode("div", {
                    class: "flex-col menu-col",
                    ref_key: "mainMenu",
                    ref: mainMenu
                  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navMenuEntries.value, (v, index) => {
                    return _openBlock(), _createBlock(_component_el_row, {
                      class: "nav-option",
                      key: index
                    }, {
                      default: _withCtx(() => [_createVNode(_component_el_col, null, {
                        default: _withCtx(() => [_createVNode(_component_el_row, {
                          style: {
                            "flex-wrap": "nowrap"
                          }
                        }, {
                          default: _withCtx(() => [v.externalLink ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            href: v.externalLink,
                            target: "_blank"
                          }, [_createElementVNode("h5", null, _toDisplayString(v.name), 1)], 8, _hoisted_4)) : (_openBlock(), _createBlock(_component_router_link, {
                            key: 1,
                            to: v.destination,
                            onClick: _cache[2] || (_cache[2] = $event => handleClose())
                          }, {
                            default: _withCtx(() => [_createElementVNode("h5", null, _toDisplayString(v.name), 1)]),
                            _: 2
                          }, 1032, ["to"])), v.submenu ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", {
                            class: _normalizeClass(["icon-track", {
                              active: v === subMenus.value[activeSubMenu.value]
                            }])
                          }, [_createVNode(_component_el_icon, {
                            onClick: () => {
                              toggleSubmenu(v);
                            }
                          }, {
                            default: _withCtx(() => [_createVNode(_unref(CloseBold))]),
                            _: 2
                          }, 1032, ["onClick"])], 2)])) : _createCommentVNode("", true)]),
                          _: 2
                        }, 1024), v.submenu ? (_openBlock(), _createBlock(_component_el_row, {
                          key: 0,
                          class: "hidden-sm-and-up"
                        }, {
                          default: _withCtx(() => [_createVNode(_component_el_col, {
                            class: _normalizeClass(["submenu-m", {
                              active: subMenus.value[activeSubMenu.value] === v
                            }])
                          }, {
                            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v.submenu, entry => {
                              return _openBlock(), _createBlock(_component_el_row, {
                                key: entry,
                                class: "subnav-option",
                                notranslate: v.track
                              }, {
                                default: _withCtx(() => [entry.externalLink ? (_openBlock(), _createElementBlock("a", {
                                  key: 0,
                                  href: entry.externalLink,
                                  target: "_blank"
                                }, [_createElementVNode("h5", null, _toDisplayString(entry.name), 1)], 8, _hoisted_6)) : entry.slug ? (_openBlock(), _createBlock(_component_router_link, {
                                  key: 1,
                                  to: {
                                    ...v.destination,
                                    params: {
                                      slug: entry.slug
                                    }
                                  },
                                  onClick: () => {
                                    handleSubmenuClick(entry, v);
                                    handleClose();
                                  }
                                }, {
                                  default: _withCtx(() => [_createElementVNode("h5", null, _toDisplayString(entry.name), 1)]),
                                  _: 2
                                }, 1032, ["to", "onClick"])) : entry.destination ? (_openBlock(), _createBlock(_component_router_link, {
                                  key: 2,
                                  to: entry.destination,
                                  onClick: () => {
                                    handleSubmenuClick(entry, v);
                                    handleClose();
                                  }
                                }, {
                                  default: _withCtx(() => [_createElementVNode("h5", null, _toDisplayString(entry.name), 1)]),
                                  _: 2
                                }, 1032, ["to", "onClick"])) : _createCommentVNode("", true)]),
                                _: 2
                              }, 1032, ["notranslate"]);
                            }), 128))]),
                            _: 2
                          }, 1032, ["class"])]),
                          _: 2
                        }, 1024)) : _createCommentVNode("", true)]),
                        _: 2
                      }, 1024)]),
                      _: 2
                    }, 1024);
                  }), 128))], 512), _createElementVNode("div", {
                    class: "menu-col submenu hidden-xs-only",
                    style: _normalizeStyle({
                      'min-height': `${mainMenuHeight.value}px`
                    })
                  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subMenus.value, (subMenu, index) => {
                    return _openBlock(), _createElementBlock(_Fragment, {
                      key: index
                    }, [_createElementVNode("div", {
                      ref_for: true,
                      ref: el => {
                        heights.value[index] = el;
                      },
                      class: "submenu-list-outer",
                      style: _normalizeStyle([{
                        "position": "absolute",
                        "visibility": "hidden !important"
                      }, {
                        '--anchor': `${transformAnchor(index)}px`,
                        'top': `calc(50% + ${contentAnchor(index)}px)`
                      }]),
                      "data-key": index
                    }, [_createVNode(_component_el_col, {
                      class: "submenu-list"
                    }, {
                      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subMenu.submenu, v => {
                        return _openBlock(), _createBlock(_component_el_row, {
                          key: v,
                          class: "nav-option"
                        }, {
                          default: _withCtx(() => [v.externalLink ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            href: v.externalLink,
                            target: "_blank"
                          }, [_createElementVNode("h5", null, _toDisplayString(v.name), 1)], 8, _hoisted_8)) : v.slug ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 1,
                            onClick: () => {
                              handleSubmenuClick(v, subMenu);
                              handleClose();
                            },
                            to: {
                              ...subMenu.destination,
                              params: {
                                slug: v.slug
                              }
                            }
                          }, {
                            default: _withCtx(() => [_createElementVNode("h5", null, _toDisplayString(v.name), 1)]),
                            _: 2
                          }, 1032, ["onClick", "to"])) : v.destination ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 2,
                            onClick: () => {
                              handleSubmenuClick(v, subMenu);
                              handleClose();
                            },
                            to: v.destination
                          }, {
                            default: _withCtx(() => [_createElementVNode("h5", null, _toDisplayString(v.name), 1)]),
                            _: 2
                          }, 1032, ["onClick", "to"])) : _createCommentVNode("", true)]),
                          _: 2
                        }, 1024);
                      }), 128))]),
                      _: 2
                    }, 1024)], 12, _hoisted_7), _createVNode(_Transition, {
                      name: "submenu"
                    }, {
                      default: _withCtx(() => [_withDirectives(_createElementVNode("div", {
                        ref_for: true,
                        ref_key: "subMenuRefs",
                        ref: subMenuRefs,
                        class: "submenu-list-outer",
                        "data-key": index,
                        style: _normalizeStyle({
                          '--anchor': `${transformAnchor(index)}px`,
                          '--abs-top': `${contentAnchor(index)}px`,
                          'top': `${relativeContentAnchor(index)}px`
                        })
                      }, [_createVNode(_component_el_col, {
                        class: "submenu-list"
                      }, {
                        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subMenu.submenu, v => {
                          return _openBlock(), _createBlock(_component_el_row, {
                            key: v,
                            class: "nav-option"
                          }, {
                            default: _withCtx(() => [v.externalLink ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              href: v.externalLink,
                              target: "_blank"
                            }, [_createElementVNode("h5", null, _toDisplayString(v.name), 1)], 8, _hoisted_10)) : v.slug ? (_openBlock(), _createBlock(_component_router_link, {
                              key: 1,
                              onClick: () => {
                                handleSubmenuClick(v, subMenu);
                                handleClose();
                              },
                              to: {
                                ...subMenu.destination,
                                params: {
                                  slug: v.slug
                                }
                              }
                            }, {
                              default: _withCtx(() => [_createElementVNode("h5", null, _toDisplayString(v.name), 1)]),
                              _: 2
                            }, 1032, ["onClick", "to"])) : v.destination ? (_openBlock(), _createBlock(_component_router_link, {
                              key: 2,
                              onClick: () => {
                                handleSubmenuClick(v, subMenu);
                                handleClose();
                              },
                              to: v.destination
                            }, {
                              default: _withCtx(() => [_createElementVNode("h5", null, _toDisplayString(v.name), 1)]),
                              _: 2
                            }, 1032, ["onClick", "to"])) : _createCommentVNode("", true)]),
                            _: 2
                          }, 1024);
                        }), 128))]),
                        _: 2
                      }, 1024)], 12, _hoisted_9), [[_vShow, activeSubMenu.value === index]])]),
                      _: 2
                    }, 1024)], 64);
                  }), 128))], 4)]),
                  _: 1
                })], 4), _createElementVNode("div", _hoisted_11, [_createVNode(_component_el_row, null, {
                  default: _withCtx(() => [_createVNode(_component_el_col, null, {
                    default: _withCtx(() => [_createVNode(Newsletter, {
                      darkmode: ""
                    }), _createVNode(SocialLinks, {
                      darkmode: ""
                    })]),
                    _: 1
                  })]),
                  _: 1
                })])]),
                _: 1
              }, 8, ["style"])]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_row, {
            align: "middle",
            class: "nav-overlay-footer"
          }, {
            default: _withCtx(() => [_createVNode(NavMenuLang)]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
};