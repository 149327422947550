import { unref as _unref, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { h } from "vue";
import CarouselSlide from "@/components/CarouselBanner/CarouselSlide";
export default {
  __name: 'WpCarouselSlide',
  props: CarouselSlide.props,
  setup(__props) {
    const props = __props;
    const forwardedComp = h(CarouselSlide, {
      ...props
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_resolveDynamicComponent(_unref(forwardedComp)), null, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 3
      });
    };
  }
};