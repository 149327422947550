import { useCssVars as _useCssVars } from 'vue';
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, unref as _unref, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = ["disabled"];
import { reactive, computed } from "vue";
import ImageWithAlt from "@/components/ImageWithAlt.vue";
import newsletter from '@/composables/newsletter.js';
import { validateEmail } from "@/helpers";
export default {
  __name: 'WpNewsletter',
  props: {
    bannerData: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    _useCssVars(_ctx => ({
      "8f87a680": cssBG.value
    }));
    const props = __props;
    const form = reactive({
      email: "",
      fax: false
    });
    const state = reactive({
      loading: false
    });
    const cssBG = computed(() => {
      return `url("${props.bannerData.background}")`;
    });
    return (_ctx, _cache) => {
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_Banner = _resolveComponent("Banner");
      return _openBlock(), _createBlock(_component_Banner, {
        justify: "center",
        align: "middle",
        class: "banner parallax"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          class: "flex-col flex-centered"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            style: {
              "max-width": "1920px",
              "width": "100%"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              md: 12
            }, {
              default: _withCtx(() => [_createVNode(ImageWithAlt, {
                src: __props.bannerData.gui,
                loading: "lazy",
                lazy: "",
                fit: "contain"
              }, null, 8, ["src"])]),
              _: 1
            }), _createVNode(_component_el_col, {
              md: 12,
              class: "flex-col",
              style: {
                "justify-content": "center"
              }
            }, {
              default: _withCtx(() => [_createVNode(_component_el_row, {
                align: "middle",
                justify: "center",
                class: "std-padding_line-sm"
              }, {
                default: _withCtx(() => [_createVNode(ImageWithAlt, {
                  src: __props.bannerData.titleImage,
                  loading: "lazy",
                  lazy: "",
                  fit: "contain"
                }, null, 8, ["src"])]),
                _: 1
              }), _createVNode(_component_el_row, {
                align: "middle",
                justify: "center",
                class: "std-padding_line-sm"
              }, {
                default: _withCtx(() => [_createElementVNode("span", {
                  innerHTML: __props.bannerData.description
                }, null, 8, _hoisted_1)]),
                _: 1
              }), _createVNode(_component_el_row, {
                align: "middle",
                justify: "center",
                class: "std-padding_line-sm"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_form, {
                  modelValue: form,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => form = $event),
                  style: {
                    "max-width": "400px",
                    "flex": "0 0 100%"
                  }
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_form_item, {
                    style: {
                      "margin-bottom": "9px"
                    }
                  }, {
                    default: _withCtx(() => [_createVNode(_component_el_input, {
                      placeholder: "Your Email Address",
                      modelValue: form.email,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.email = $event),
                      disabled: state.loading,
                      "input-style": {
                        'background-color': 'white',
                        'text-align': 'center',
                        'color': 'var(--el-color-black)'
                      },
                      onFocus: _unref(newsletter).handleEmailFieldFocusEvent
                    }, null, 8, ["modelValue", "disabled", "onFocus"])]),
                    _: 1
                  }), _createVNode(_component_el_form_item, {
                    style: {
                      "display": "none"
                    },
                    label: "Contact me by fax"
                  }, {
                    default: _withCtx(() => [_withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      name: "fax",
                      label: "Contact me by fax",
                      disabled: state.loading,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form.fax = $event)
                    }, null, 8, _hoisted_2), [[_vModelCheckbox, form.fax]])]),
                    _: 1
                  }), _createVNode(_component_el_form_item, null, {
                    default: _withCtx(() => [_createVNode(_component_el_button, {
                      style: {
                        "width": "100%"
                      },
                      type: "primary",
                      onClick: _cache[2] || (_cache[2] = $event => _unref(newsletter).handleSubmit(form, state, {})),
                      disabled: state.loading || !_unref(validateEmail)(form.email)
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode(" Sign Up ")])),
                      _: 1
                    }, 8, ["disabled"])]),
                    _: 1
                  })]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      });
    };
  }
};