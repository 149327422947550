/* eslint-disable no-prototype-builtins */
import { ref, computed, markRaw, watch } from 'vue';
import router from '@/router';
import store from '@/store';
import WpHttpClient from '@/classes/Wp';
import Product from '@/classes/Product';
import { isBot } from '@/helpers';
import { ElMessage } from 'element-plus';
import { compareRoutes, getOriginalPhrase } from "@/helpers/language"
import { captureException } from '@sentry/vue';

const FsLoading = ref(true);
const compFsLoading = computed(() => FsLoading.value);
const FsWatchers = ref([]);
let navGuard = null; // Track Navigation Guard for later removal

/**
 * Accessor for FsLoading variable
 * @returns Computed property pointing to loading variable; allows automated updates for reactive content
 */
export function useFsLoading () {
    return compFsLoading;
}

/**
 * Sets up Watcher as appropriate to handle execution of product data sensitive sections of code.
 * 
 * If FS Data is no longer loading when the function is called, fires the callback immediately & returns.
 * 
 * @param {Function} callback - Critical code execution callback
 * @param {Boolean} limitToRoute - Optional Boolean to abort watcher if route is changed, accounting for Language Sub Dir Swaps
 * @returns Function to abort the FS Watcher; Useful if callback is only needed on particular pages
 */
export function watchFastSpring (callback, limitToRoute = false) {
    if (!FsLoading.value) {
        callback();
        return null;
    }

    let stopFsWatcher = watch(FsLoading, (newVal, oldVal) => {
        if (!newVal && oldVal !== newVal) {
            callback();

            if (stopFsWatcher) stopFsWatcher();
        }
    });

    if (!limitToRoute) {
        return stopFsWatcher;
    }

    if (!navGuard) {
        navGuard = router.beforeEach((to, from) => {
            const toSlug = to.params?.slug;
            const fromSlug = from.params?.slug;
            const sameSlugs = toSlug && fromSlug && getOriginalPhrase(toSlug) === getOriginalPhrase(fromSlug);

            if (compareRoutes(to, from) && sameSlugs) {
                return;
            }

            FsWatchers.value.forEach((stopFsWatcher) => {
                stopFsWatcher();
            });

            FsWatchers.value = [];
        });

        // Watcher to clean up obsolete Navigation Guard once FsData has loaded
        const stopNavWatcher = watch(FsLoading, (newVal, oldVal) => {
            if (!newVal && oldVal !== newVal) {
                navGuard();
    
                if (stopNavWatcher) stopNavWatcher();
            }
        });
    }

    FsWatchers.value.push(stopFsWatcher);

    return stopFsWatcher;
}

export default class FastSpring {

    constructor (router, cart, ir) {
        this.router = router;
        this.cart = cart;
        this.ir = ir || null;
        this.fsStorefrontLoading = false;
        this.fsStorefrontLoaded = false;
    }

    /**
     * Gets the user's country code from the browser locale.
     * @returns {string}
     */
    static getCountryCode () {
        if (isBot()) {
            return "US";
        }

        const langParts = navigator.language.split('-');

        if (!langParts.length) {
            return 'US';
        }

        let cc = null;

        if (langParts.length > 1) {
            cc = langParts[1].toUpperCase();
        } else {
            cc = langParts[0].toUpperCase();
        }

        if (langParts.length === 1) {
            return langParts[0].toUpperCase();
        }

        return cc;
    }

    /**
     * Updates the current cachebuster value. If the value of cbValue is different from the
     * currently cached value, calls this.setFsData to re-read and re-set fsData.
     * 
     * @todo: Update to new FS Data retrieval process
     * 
     * @param {number} cbValues
     */
    static updateCbValue (cbValues) {
        const current = store.getters.getCbValues;
        let updatedVersions = {};

        for (let v in cbValues) {
            const cbPayload = cbValues[v];
            if (current[v] && (cbPayload.version !== current[v])) {
                switch (v) {
                    case 'store_json':
                        this.setFsData(cbPayload.data);
                        break;
                    case 'upgrade_substitutions':
                        this.setFsUpgradeSubstitutions(cbPayload.data);
                }
            }

            updatedVersions[v] = cbPayload.version;
        }

        store.commit('setCbValues', updatedVersions);
    }

    /**
     * Returns the current cachebuster versions
     * @returns {object}
     */
    static getCbVersions () {
        let current = store.getters.getCbValues;
        let ret = {};

        for (let key in current) {
            ret[key] = current[key];
        }

        return ret;
    }

    static async fetchLaravelFsVersion () {
        const resp = await (new WpHttpClient({
            namespace: '/antares/v1',
            path: '/check-cache',
            global: true
        })).get();

        return resp.headers && resp.headers['x-wp-fsversion'] ? resp.headers['x-wp-fsversion'] : null;
    }

    /**
     * Retrieve updated Fs Data from CMS
     * @param {*} param0 
     * @returns {Promise}
     */
    static async fetchCurrenciesFromCMS ({ cc, testIp }) {
        const resp = await (new WpHttpClient({
            namespace: '/antares/v1',
            path: '/currencies',
            query: {
                cc,
                test_ip: testIp
            },
            global: true
        })).get();

        const { prices, ip } = resp.data;
        const { currency, products } = prices;

        if (!currency) {
            return {
                currency: "USD",
                products: prices,
                ip
            }
        }

        return {
            currency,
            products,
            ip
        };
    }

    /**
     * Fetches the current client IP from the CMS
     * @param {string|null} testIp 
     * @returns {Promise}
     */
    static async fetchCurrentClientIp (testIp = null) {
        return await (new WpHttpClient({
            namespace: '/antares/v1',
            path: '/get_client_ip',
            query: {
                test_ip: testIp,
            },
            global: true,
        })).get();
    }

    /**
     * Reads data in fs_data.json and sets it in the store
     * @param {string}
     */
    static async setFsData (testIp = null) {
        FsLoading.value = true;
        let fsError = false;
        const cc = this.getCountryCode();
        const localFsVersion = store.getters.getFsVersion;

        try {
            const latestFsVersion = await this.fetchLaravelFsVersion();

            if (!testIp && localFsVersion && localFsVersion === latestFsVersion) {
                return;
            }

            const { currency = "USD", products = markRaw([]), ip = null } = await this.fetchCurrenciesFromCMS({ cc, testIp });

            // Avoid pushing an empty store JSON to Vuex
            if (currency && products) {
                store.commit('setCurrencyCode', currency);
                store.commit('setFsData', products);
                store.commit('setFsVersion', latestFsVersion);
            }

            if (ip) {
                store.commit('setCachedIp', ip);
            }
        } catch (e) {
            const stopErrorWatcher = watch(() => router.currentRoute.value?.meta?.appLoaded, () => {
                // Persistent error message; still closable via "esc", but no option in component's API to disable; will need further investigation
                ElMessage({
                    message: "An unexpected error has occurred; we apologize for the inconvenience.",
                    type: "error",
                    duration: 0, // Do not automatically close this message
                    offset: 20
                });

                if (stopErrorWatcher) {
                    stopErrorWatcher();
                }
            });

            console.error(e);
            captureException(e);
            fsError = true;
        } finally {
            FsLoading.value = fsError;
        }
    }

    /**
     * Reads data in fs_upgrade_substitutions.json and sets it in the store
     */
    static setFsUpgradeSubstitutions (payload = null) {
        if (!payload) {
            payload = require('../../fs_upgrade_substitutions.json');
        }

        store.dispatch('setFsUpgradeSubstitutions', markRaw(payload));
    }

    /**
     * Loads the FastSpring storefront
     * @return {void}
     */
    loadStorefront (cb) {
        window.fsErrorCallback = (args) => this.fsErrorCallback(args);
        window.fsBeforeRequestsCallbackFunction = (args) => this.fsBeforeRequestsCallbackFunction(args);
        window.fsAfterRequestsCallbackFunction = (args) => this.fsAfterRequestsCallbackFunction(args);
        window.fsBeforeMarkupCallbackFunction = (args) => this.fsBeforeMarkupCallbackFunction(args);
        window.fsAfterMarkupCallbackFunction = (args) => this.fsAfterMarkupCallbackFunction(args);
        window.decorateURL = (args) => this.decorateURL(args);
        window.fsPopupEventReceived = (args) => this.fsPopupEventReceived(args);
        window.fsPopupWebhookReceived = (args) => this.fsPopupWebhookReceived(args);
        window.fsOnPopupClose = (args) => this.fsOnPopupClose(args);

        var fs = document.createElement('script');

        fs.setAttribute('id', 'fsc-api');
        fs.setAttribute('src', process.env.VUE_APP_FS_SBL_URL);
        fs.setAttribute('data-storefront', process.env.VUE_APP_FS_DATA_STOREFRONT);

        fs.setAttribute('data-data-callback', 'fsDataCallbackFunction');
        fs.setAttribute('data-error-callback', 'fsErrorCallback');
        fs.setAttribute('data-before-requests-callback', 'fsBeforeRequestsCallbackFunction');
        fs.setAttribute('data-after-requests-callback', 'fsAfterRequestsCallbackFunction');
        fs.setAttribute('data-before-markup-callback', 'fsBeforeMarkupCallbackFunction');
        fs.setAttribute('data-after-markup-callback', 'fsAfterMarkupCallbackFunction');
        fs.setAttribute('data-decorate-callback', 'decorateURL');
        fs.setAttribute('data-popup-event-received', 'fsPopupEventReceived');
        fs.setAttribute('data-popup-webhook-received', 'fsPopupWebhookReceived');
        fs.setAttribute('data-popup-closed', 'fsOnPopupClose');
        fs.setAttribute('data-debug', process.env.VUE_APP_SERVER === 'dev' || process.env.VUE_APP_SERVER === 'staging' ? "true" : "false");
        // fs.setAttribute('data-continuous','true');

        if (process.env.VUE_APP_FS_DATA_KEY) {
            fs.setAttribute('data-access-key', process.env.VUE_APP_FS_DATA_KEY);
        }

        document.head.appendChild(fs);

        const onFsLoadSuccess = () => {
            this.fsStorefrontLoaded = true;
            cb();
        };

        fs.addEventListener('load', onFsLoadSuccess);
    }

    /**
     * Unloads FastSpring when not needed
     * @return {void}
     */
     unloadStorefront () {
        let popup = document.getElementById('fsc-popup-frame');

        if (popup) {
            popup.src = '';
        }

        var fsEl = document.getElementById('fsc-api');

        if (fsEl) {
            fsEl.remove();

            delete window.fsErrorCallback;
            delete window.fsBeforeRequestsCallbackFunction;
            delete window.fsAfterRequestsCallbackFunction;
            delete window.fsBeforeMarkupCallbackFunction;
            delete window.fsAfterMarkupCallbackFunction;
            delete window.decorateURL;
            delete window.fsPopupEventReceived;
            delete window.fsPopupWebhookReceived;
            delete window.fsOnPopupClose;
            delete window.fastspring;
        }

        this.fsStorefrontLoaded = false;
    }

    pushPayload (payload) {
        if (process.env.NODE_ENV !== 'development' && typeof window.fastspring?.builder === 'object') {
            window.fastspring.builder.clean();
            window.fastspring.builder.push(payload);
        }
    }

    decorateURL () {
        console.log('decorateURL');
    }

    fsDataCallbackFunction (msg) {
        if (msg) {
            console.log('fsDataCallbackFunction');
        }
    }

    fsErrorCallback (msg) {
        if (msg) {
            store.commit('setTransactionError', msg);
            console.warn(msg);
        }
    }

    fsBeforeRequestsCallbackFunction () {
        this.fsStorefrontLoading = true;
    }

    fsAfterRequestsCallbackFunction () {
        this.fsStorefrontLoading = false;
    }

    fsBeforeMarkupCallbackFunction (msg) {
        if (msg) {
            console.log('fsBeforeMarkupCallbackFunction', msg);
        }
    }

    fsAfterMarkupCallbackFunction (msg) {
        if (msg) {
            console.log('fsAfterMarkupCallbackFunction', msg);
        }
    }

    fsPopupEventReceived (data) {
        if (data?.event === 'FSC-checkoutStep4' && typeof data['fsc-eventAction'] !== 'undefined' && data['fsc-eventAction'].includes('Payment Data Entered')) {
            this.trackPaymentInfoEnteredWithGtm(data);
        }
    }

    async fsPopupWebhookReceived (data) {
		if (data?.id && data?.reference) {
            if (typeof this.cart.emptyCart === 'function') {
                this.cart.emptyCart();
            }

            this.unloadStorefront();

			if (!(data.items && Array.isArray(data.items) && data.items.length)) {
				throw new Error('data.items is empty!');
			}

            const user = store.getters.getUserInstance;

            this.trackSaleWithGtm(data);
            store.commit('setTransaction', data);

            const User = (await import('./User')).default;

            if (user instanceof User && user.id) {
                try {
                    await user.fetchUserSummary();
                } catch (e) {
                    console.error(e);
                }
            }

            if (typeof window.ire === 'function') {
                const { default: ImpactRadius } = await import('../classes/ImpactRadius');
                const ir = new ImpactRadius();
                ir.loadScript();
                ir.trackSale(data);
            }

            if (!data.tags?.suppress_redirect) {
                this.router.push({ name: 'OrderSummary' });
            }
		} else {
            throw new Error('No order data received.');
        }
    }

    fsOnPopupClose (data) {
        if (!data) {
            store.dispatch('setFsPaymentCancelled');
        }
    }

    /**
     * Tracks the 'Payment Data Entered (Payment Window)' event action with Google Tag Manager
     * @param {object} data
     */
    async trackPaymentInfoEnteredWithGtm (data) {
        let items = data.ecommerce?.checkout?.products;
        const currency = data['fsc-currency'] || "USD";
        const ecommerce = {
            currency,
            value: this.cart.subtotal,
            coupon: this.cart.coupon?.fs_coupon_id || null,
            payment_type: data['fsc-paymentMethod'] || 'card'
        };

        if (Array.isArray(items)) {
            let ltvValues = null;

            try {
                ltvValues = await import('../../ltvValues.json');
            } catch (e) {
                console.log('Missing LTV values map');
            }

            items = items.map((v, index) => {
                return {
                    item_id: v.id,
                    item_name: v.name,
                    currency,
                    index,
                    price: v.type === 'trial' ? 0 : (v.price ? parseInt(v.price) : 0),
                    quantity: v.quantity ? parseInt(v.quantity) : 1,
                    ltv: ltvValues && ltvValues[v.id] ? ltvValues[v.id].toString() : ''
                }
            });

            ecommerce.items = items;
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'add_payment_info',
            ecommerce
        });
    }

    /**
     * Tracks the sale with Google Tag Manager
     * @param {object} data 
     */
    async trackSaleWithGtm (data) {
        const fsPurchaseRecords = store.getters.getFsPurchaseRecords;
        let ltvValues = null;

        try {
            ltvValues = await import('../../ltvValues.json');
        } catch (e) {
            console.log('Missing LTV values map');
        }

        if (fsPurchaseRecords && fsPurchaseRecords[data.reference]) {
            return;
        }

        const items = data.items.map((item, index) => {
            let name = '';
            let price = '';
            let quantity = null;
            let ltv = null;
            let productObject = null;

            try {
                productObject = new Product(item.product);
            } catch (e) {
                return null;
            }

            if (productObject) {
                if (typeof item.subtotalInPayoutCurrency === 'number' && typeof item.quantity === 'number') {
                    price = productObject.isTrial ? 0 : (parseFloat(item.subtotalInPayoutCurrency) / parseInt(item.quantity));
                } else {
                    price = productObject.isTrial ? 0 : productObject.productFinalPrice;
                }

                if (typeof item.quantity === 'number') {
                    quantity = item.quantity;
                }

                name = productObject.name;
                ltv = ltvValues[productObject.path] ? ltvValues[productObject.path].toString() : '';
            } else {
                ltv = ltvValues[item.product] ? ltvValues[item.product].toString() : '';                
            }

            return {
                item_id: item.product || '',
                item_name: name,
                coupon: item.coupon || '',
                currency: data.currency,
                discount: item.discountInPayoutCurrency || 0,
                index,
                item_category: '',
                price,
                quantity,
                ltv,
            };
        }).filter(item => item);

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'purchase',
            ecommerce: {
                transaction_id: data.reference,  // Replace with transaction id
                value: typeof data.subtotalInPayoutCurrency === 'undefined' ? 0 : data.subtotalInPayoutCurrency,  // Replace with order value
                valueInPurchaseCurrency: typeof data.subtotalDisplay === 'undefined' ? '0' : data.subtotalDisplay.toString(),
                tax: typeof data.taxInPayoutCurrency === 'undefined' ? 0 : data.taxInPayoutCurrency,  // Replace with tax value
                currency: data.currency || 'USD',  // Replace with currency
                coupon: data.tags?.offer_code ? data.tags.offer_code : '',  // Replace with coupon
                payment_type: data.payment?.cardEnding ? 'credit card' : '',  // Replace with payment type (RECOMMENDED)
                items,
                ltv: items.reduce((a, b) => a + (b.ltv ? parseFloat(b.ltv) : 0), 0)
            }
        });

        store.commit('setFsPurchaseRecord', data.reference);
        this.purgeOutdatedFsPurchaseRecords(fsPurchaseRecords);
    }

    purgeOutdatedFsPurchaseRecords (fsPurchaseRecords) {
        for (let record in fsPurchaseRecords) {
            const purchaseDate = new Date(fsPurchaseRecords[record]);
            const expDate = new Date(purchaseDate.setMonth(purchaseDate.getMonth() + 1));

            if (Date.now() >= expDate) {
                store.commit('unsetFsPurchaseRecord', record);
            }
        }
    }
}