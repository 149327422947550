import { createApp, reactive, computed, watch, defineAsyncComponent } from 'vue';

import {
    init as VueInit,
    VueIntegration,
    Breadcrumbs,
    GlobalHandlers,
    BrowserTracing,
    Dedupe,
    LinkedErrors,
    vueRouterInstrumentation,
    HttpContext,
    Integrations,
    TryCatch,
    addIntegration,
} from "@sentry/vue";

import { setCacheKey, initializeConvert } from './helpers';
import App from './layouts/CoreLayout.vue';
import store from './store';
import router from './router';
import Localize from './classes/Localize';
import FastSpring from './classes/FastSpring';
import User from "@/composables/user";
import Cart from './classes/Cart';

try {
    FastSpring.setFsUpgradeSubstitutions();
} catch (e) {
    console.warn('Missing fs_upgrade_substitutions', e);
}

async function loadApp (cb = null) {
    let app = createApp(App);

    // Initialize Sentry if build is production
    if (process.env.NODE_ENV === 'production') {
        const options = {
            app,
            dsn: `https://${process.env.VUE_APP_SENTRY_KEY}@o1123503.ingest.sentry.io/6161435`,
            defaultIntegrations: [ // Initially load only the critical Integrations
                new VueIntegration(),
                new Breadcrumbs(),
                new GlobalHandlers(),
                // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/integrations/browsertracing/
                new BrowserTracing({
                    routingInstrumentation: vueRouterInstrumentation(router),
                    tracingOrigins: ["localhost", /^\//],
                }),
                new Dedupe(),
                new LinkedErrors()
            ],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: process.env.VUE_APP_SERVER === "production" ? 0.2 : 0,
            logErrors: true,
            environment: process.env.VUE_APP_SERVER || 'production',
            trackComponents: true
        };
    
        VueInit(options);

        // Defer loading the rest of the needed Integrations
    
        const lazyloadIntegrations = () => {
            addIntegration(new HttpContext());
            addIntegration(new Integrations.InboundFilters());
            addIntegration(new TryCatch());
            addIntegration(new Integrations.FunctionToString());
        }
    
        document ?
            document.addEventListener("DOMContentLoaded", lazyloadIntegrations)
            : window.addEventListener("load", lazyloadIntegrations);
    }

    // Set app cache key for cachebusting
    try {
        setCacheKey();
    } catch (e) {
        console.warn('Error setting cache key in Vuex', e);
    }

    let ip = null;

    if (process.env.VUE_APP_SERVER !== "production") {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        ip = params.get('ip');
    }

    // Enable devtools in development. Do not run this in production!
    if (process.env.NODE_ENV === 'development') {
        app.config.devtools = true;
    }

    // Fire fsData request
    FastSpring.setFsData(ip);

    const cart = reactive((new Cart()));
    const cartData = computed(() => store.getters.getCart);
    const user = reactive((User.getInstance()));
    const userData = computed(() => store.getters.getUser);
    const fs = reactive(new FastSpring(router, cart));
    
    app.provide('cart', cart);
    app.provide('user', user);
    app.provide('fs', fs);
    app.provide('localize', (new Localize()));
    app.provide('baseURL', process.env.VUE_APP_DOMAIN);

    app.component('router-link', defineAsyncComponent(() => import ('@/router/RouterLinkWrapper.vue')));
    app.component('video-bg', defineAsyncComponent(() => import ('@/components/VideoBG.vue')));
    app.component('wp-post-button', defineAsyncComponent(() => import ('@/components/WpComponents/WpElButton.vue')));

    store.commit('resetPollingState');
    store.commit('resetProductChangeRequests');
    store.commit('setUser', user);

    await import ("@/styles/index.scss");
    app.use(store).use(router).mount('#app');

    // Make the below available on non-production for testability
    if (process.env.VUE_APP_SERVER != 'production') {
        window._app = app
        window._store = store
        window._cart = cart
        window._user = user
    }

    watch (userData, () => {
        user.instantiate(userData.value);
    });

    watch (cartData, () => {
        cart.instantiate(cartData.value);
    });

    if (cb && typeof cb === 'function') {
        cb();
    }
}

/**
 * Activates GTM
 * This is only needed when loading the account app, as it is loaded by wordpress otherwise.
 * @param {function} cb
 */
function activateGtm (cb) {
    const gtmTag = process.env.VUE_APP_GTM_TAG;

	if (!gtmTag) {
        return;
    }

    (function(w,d,s,l,i)
        {
            w[l]=w[l]||[];
            w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});

            var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),
                dl=l!='dataLayer'?'&l='+l:''
            ;

            j.async=true;
            j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);

            if (typeof cb === 'function') {
                j.addEventListener('load', cb);
            }
        }
    )(window,document,'script','dataLayer', gtmTag);
}

/**
 * Checks for ad blockers and pushes an event into GA if true
 */
async function checkForAdblocker () {
    try {
        await fetch(new Request('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'), {
            method: 'HEAD',
            mode: 'no-cors'
        });
    } catch (e) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({event: 'AdBlocker' });
    }
}

loadApp(initializeConvert);

window.addEventListener('load', function() {
    activateGtm(checkForAdblocker);
});
