import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  style: {
    "font-weight": "bold"
  }
};
const _hoisted_2 = {
  style: {
    "font-weight": "bold"
  }
};
const _hoisted_3 = {
  class: "dialog-footer"
};
import { inject, ref, computed, onMounted } from 'vue';
import { validateEmail } from '../helpers';
export default {
  __name: 'ResetPasswordModal',
  props: {
    visible: Boolean
  },
  emits: ['close'],
  setup(__props, {
    emit: __emit
  }) {
    const user = inject('user');
    const emits = __emit;
    const email = ref('');
    const pwForm = ref({
      newPw: '',
      newPwConf: ''
    });
    const loading = ref(false);
    const pwResetSent = ref(false);
    const error = ref(null);
    const handlePwResetDialogClose = () => {
      emits('close');
      setTimeout(() => {
        // use settimeout so component doesn't change while fadeout animation is going
        email.value = '';
        pwForm.value.newPw = '';
        pwForm.value.newPwConf = '';
        pwResetSent.value = false;
        error.value = null;
      }, 1000);
    };
    const sendPwResetEmail = async () => {
      loading.value = true;
      try {
        const payload = {
          data: JSON.stringify({
            email: email.value
          })
        };
        const {
          default: HttpClient
        } = await import('../classes/HttpClient');
        const resp = await new HttpClient({
          path: '/laravel/password/email-web',
          verify: false,
          global: true
        }).post(payload);
        if (!(resp && resp.status && resp.status === 200)) {
          throw new Error(resp.status);
        }
        pwResetSent.value = true;
      } catch (e) {
        error.value = e.message;
        pwResetSent.value = false;
      } finally {
        loading.value = false;
      }
    };
    const emailValid = computed(() => {
      if (user && user.email) {
        return true;
      }
      return validateEmail(email.value);
    });
    onMounted(() => {
      if (user && user.email) {
        email.value = user.email;
      }
    });
    return (_ctx, _cache) => {
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createBlock(_component_el_dialog, {
        class: "antares-dialog reset-password-dialog",
        modelValue: __props.visible,
        width: "90%",
        "show-close": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": handlePwResetDialogClose
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_3, [_createVNode(_component_el_button, {
          type: !pwResetSent.value ? 'danger' : 'primary',
          plain: "",
          onClick: handlePwResetDialogClose,
          disabled: loading.value
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(!pwResetSent.value ? 'Cancel' : 'Ok'), 1)]),
          _: 1
        }, 8, ["type", "disabled"]), _createVNode(_component_el_button, {
          type: "primary",
          onClick: sendPwResetEmail,
          loading: loading.value.value,
          disabled: !emailValid.value || loading.value
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(!pwResetSent.value ? 'Send' : 'Resend'), 1)]),
          _: 1
        }, 8, ["loading", "disabled"])])]),
        default: _withCtx(() => [_createVNode(_component_el_row, {
          align: "middle",
          justify: "start",
          class: "std-padding_line-md"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("h2", null, "Reset Password", -1)])),
          _: 1
        }), _createVNode(_component_el_row, {
          align: "middle",
          justify: "center"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 24
          }, {
            default: _withCtx(() => [!(_unref(user) && _unref(user).id) ? (_openBlock(), _createBlock(_component_el_row, {
              key: 0,
              align: "middle",
              justify: "center"
            }, {
              default: _withCtx(() => [!pwResetSent.value ? (_openBlock(), _createBlock(_component_el_col, {
                key: 0,
                span: 24
              }, {
                default: _withCtx(() => [_createVNode(_component_el_row, {
                  align: "middle",
                  justify: "start",
                  class: "std-padding_line-md"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("p", null, " Please enter your email address and click on the button below to have a password reset link sent to you. ", -1)])),
                  _: 1
                }), _createVNode(_component_el_row, {
                  align: "middle",
                  justify: "start"
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_input, {
                    placeholder: "jim@example.com",
                    modelValue: email.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => email.value = $event),
                    style: {
                      "width": "200px"
                    }
                  }, null, 8, ["modelValue"])]),
                  _: 1
                })]),
                _: 1
              })) : (_openBlock(), _createBlock(_component_el_col, {
                key: 1,
                span: 24
              }, {
                default: _withCtx(() => [_createVNode(_component_el_row, {
                  align: "middle",
                  justify: "start"
                }, {
                  default: _withCtx(() => [_createElementVNode("p", null, [_cache[3] || (_cache[3] = _createTextVNode(" A password reset link has been sent to the following email address: ")), _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)), _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)), _createElementVNode("span", _hoisted_1, _toDisplayString(email.value), 1), _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)), _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)), _cache[8] || (_cache[8] = _createTextVNode(" If you didn't receive any email, please hit \"Resend\". If this still doesn't work, please ")), _cache[9] || (_cache[9] = _createElementVNode("span", {
                    style: {
                      "padding": "0 6px"
                    }
                  }, [_createElementVNode("a", {
                    href: "https://antarestech.force.com/s/contactsupport",
                    target: "_blank"
                  }, "click here")], -1)), _cache[10] || (_cache[10] = _createTextVNode(" to open a support ticket. "))])]),
                  _: 1
                })]),
                _: 1
              }))]),
              _: 1
            })) : _unref(user) && _unref(user).id ? (_openBlock(), _createBlock(_component_el_row, {
              key: 1,
              type: "flex",
              align: "middle",
              justify: "center"
            }, {
              default: _withCtx(() => [!pwResetSent.value ? (_openBlock(), _createBlock(_component_el_col, {
                key: 0,
                span: 24
              }, {
                default: _withCtx(() => [_createVNode(_component_el_row, {
                  type: "flex",
                  align: "middle",
                  justify: "start"
                }, {
                  default: _withCtx(() => [_createElementVNode("p", null, [_cache[11] || (_cache[11] = _createTextVNode(" To get started, please click on the \"Send\" button below. A password reset link will be sent to ")), _createElementVNode("strong", null, _toDisplayString(_unref(user).email), 1), _cache[12] || (_cache[12] = _createTextVNode(". ")), _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)), _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)), _cache[15] || (_cache[15] = _createTextVNode(" If you don't receive any email, please hit \"Resend\". If this still doesn't work, please ")), _cache[16] || (_cache[16] = _createElementVNode("span", {
                    style: {
                      "padding": "0 6px"
                    }
                  }, [_createElementVNode("a", {
                    href: "https://antarestech.force.com/s/contactsupport",
                    target: "_blank"
                  }, "click here")], -1)), _cache[17] || (_cache[17] = _createTextVNode(" to open a support ticket. "))])]),
                  _: 1
                })]),
                _: 1
              })) : (_openBlock(), _createBlock(_component_el_col, {
                key: 1,
                span: 24
              }, {
                default: _withCtx(() => [_createVNode(_component_el_row, {
                  type: "flex",
                  align: "middle",
                  justify: "start"
                }, {
                  default: _withCtx(() => [_createElementVNode("p", null, [_cache[18] || (_cache[18] = _createTextVNode(" A password reset link has been sent to the following email address: ")), _cache[19] || (_cache[19] = _createElementVNode("br", null, null, -1)), _cache[20] || (_cache[20] = _createElementVNode("br", null, null, -1)), _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(user).email), 1), _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)), _cache[22] || (_cache[22] = _createElementVNode("br", null, null, -1)), _cache[23] || (_cache[23] = _createTextVNode(" If you didn't receive any email, please hit \"Resend\". If this still doesn't work, please ")), _cache[24] || (_cache[24] = _createElementVNode("span", {
                    style: {
                      "padding": "0 6px"
                    }
                  }, [_createElementVNode("a", {
                    href: "https://antarestech.force.com/s/contactsupport",
                    target: "_blank"
                  }, "click here")], -1)), _cache[25] || (_cache[25] = _createTextVNode(" to open a support ticket. "))])]),
                  _: 1
                })]),
                _: 1
              }))]),
              _: 1
            })) : _createCommentVNode("", true)]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
};