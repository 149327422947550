import { renderSlot as _renderSlot, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { BPEnum, currentBP, getSize } from "@/composables/windowWatcher.js";
import { throttle } from '@/helpers';
export default {
  __name: 'WpStickyBox',
  props: {
    maxScreenSize: {
      type: String,
      required: false,
      default: "xl",
      validator(val) {
        return ['xs', 'sm', 'md', 'lg', 'xl'].includes(val);
      }
    },
    position: {
      type: String,
      required: true,
      default: "top",
      validator(val) {
        return ['top', 'bottom'].includes(val);
      }
    }
  },
  setup(__props) {
    const windowSize = getSize();
    const props = __props;
    const trigger = ref(null);
    const current = ref(0);
    const footer = ref(null);

    /**
     * 
     * Computed Properties
     * 
     */

    const allowedInBp = computed(() => {
      const currBp = BPEnum[currentBP.value];
      const maxBp = BPEnum[props.maxScreenSize];
      return currBp <= maxBp;
    });

    /**
     * Scroll threshold before sticky box should show.
     */
    const threshold = computed(() => {
      const docTop = document.documentElement.scrollTop;
      const offset = props.position === "bottom" ? windowSize.height : 70;
      if (!trigger.value) {
        return docTop - offset;
      }
      return Math.floor(trigger.value.getBoundingClientRect().top + docTop - offset);
    });

    /**
     * Footer Threshold
     * 
     * Scrolling past this hides the sticky box.
     * 
     * If no footer is found; defaults to INF to allow continued functionality
     */
    const footerThreshold = computed(() => {
      const docTop = document.documentElement.scrollTop;
      const offset = props.position === "bottom" ? windowSize.height : 70;
      if (!footer.value) {
        return Infinity;
      }
      return Math.floor(footer.value.getBoundingClientRect().top + docTop - offset);
    });

    /**
     * Determine if the sticky box should be visible.
     */
    const visible = computed(() => {
      if (!(trigger.value && allowedInBp.value)) {
        return false;
      }
      return threshold.value <= current.value && current.value <= footerThreshold.value;
    });

    /**
     * 
     * Methods
     * 
     */

    const initialize = () => {
      footer.value = document.getElementById('footer');
      window.addEventListener('scroll', handleScroll, {
        passive: true
      });
    };
    const uninitialize = () => {
      window.removeEventListener('scroll', handleScroll);
    };

    /**
     * Tracks the current scrollTop value.
     * 
     * Pre-throttled to help with removing Event Listener later on unmount
     * 
     * @param {object} e
     */
    const handleScroll = throttle(() => {
      current.value = document.documentElement.scrollTop;
    }, 80);

    /**
     * 
     * Lifecycle hooks
     * 
     */

    onMounted(() => initialize());
    onBeforeUnmount(() => uninitialize());
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        ref_key: "trigger",
        ref: trigger
      }, [_createVNode(_Transition, {
        name: "box-fade"
      }, {
        default: _withCtx(() => [_withDirectives(_createElementVNode("div", {
          class: _normalizeClass(["sticky-bar", __props.position])
        }, [_renderSlot(_ctx.$slots, "default")], 2), [[_vShow, visible.value]])]),
        _: 3
      })], 512);
    };
  }
};