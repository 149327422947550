import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.some.js";
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, TransitionGroup as _TransitionGroup, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = {
  class: "close-btn"
};
import { reactive, ref, computed, provide } from 'vue';
import { Close } from '@element-plus/icons-vue';
import ProductOptionCard from '@/components/ProductComponents/ProductOptionCard.vue';
import ImageWithAlt from '@/components/ImageWithAlt.vue';
import CarouselContainer from '@/components/CarouselBanner/CarouselContainer.vue';
import CarouselSlide from '@/components/CarouselBanner/CarouselSlide.vue';
import CarouselIndicators from '@/components/CarouselBanner/CarouselIndicators.vue';
import WaveSpinner from '@/components/WaveSpinner.vue';
import ErrorAlert from '@/components/Error/ErrorAlert.vue';
import { currentBP } from '@/composables/windowWatcher';
import { parseProductCards } from "@/composables/content.js";
import * as modalOps from "@/composables/modal.js";
import { ArrowLeftBold, ArrowRightBold } from '@element-plus/icons-vue';
export default {
  __name: 'ProductOptionsModal',
  setup(__props, {
    expose: __expose
  }) {
    const state = reactive({
      data: {},
      error: false,
      loading: false,
      visible: false,
      cache: {},
      header: null,
      subheader: null
    });
    const carousel = ref(null);

    // Computed Properties

    /**
     * Watch & pull out setSlideNum from carousel ref
     */
    const setSlide = computed(() => {
      return carousel.value?.setSlideNum ? carousel.value.setSlideNum : () => {};
    });

    /**
     * Compute number of slides present with x number of cards per slide
     */
    const numSlides = computed(() => {
      return carousel.value?.numSlides;
    });

    /**
     * return current active slide num from carousel
     */
    const activeSlide = computed(() => {
      return carousel.value?.activeSlide;
    });

    /**
     * Read through the cards to form an array of relevant Deal Banners.
     * 
     * If none of the cards have a Deal Banner set by WP, insert one for ATU if present & includes any of the other products
     */
    const dealBanners = computed(() => {
      const banners = [];
      state.data.cards.forEach(entry => banners[entry.title] = entry.deal_banner);
      if (Object.values(banners).some(val => val)) {
        return banners;
      }
      const AtuCard = state.data.cards.find(elem => elem.title === "Auto-Tune Unlimited");
      if (AtuCard && AtuCard.included_in) {
        banners["Auto-Tune Unlimited"] = "BEST VALUE";
      }
      return banners;
    });

    /**
     * Sorts cards into slides. Dictated by numPerSlide & numSlides
     */
    const slideContent = computed(() => {
      let slides = [];
      let numPerSlide = 1;
      switch (currentBP.value) {
        case "xl":
        case 'lg':
          numPerSlide = 3;
          break;
        case "md":
        case "sm":
          numPerSlide = 2;
          break;
        default:
          numPerSlide = 1;
      }
      for (let i = 0; i < state.data.cards.length; i = i + numPerSlide) {
        slides.push(state.data.cards.slice(i, i + numPerSlide));
      }
      return slides;
    });

    // Methods

    /**
     * Open the Purchase Modal with provided options. If no options object is passed in, defaults to opening Purchase Modal for ATU
     * 
     * @param {object} purchase_options { header: Optional String, subheader: Optional String, cards: String Array }
     */
    const openModal = async (purchase_options = {
      header: null,
      subheader: null,
      cards: ["unlimited"],
      exact: false
    }) => {
      const products = Object.values(purchase_options.cards);

      // Avoid opening when an empty array is passed in
      if (products.length === 0) {
        return;
      }
      modalOps.registerModal('product');
      state.visible = true;

      /**
       * Short term caching; should be preserved as long as the page is not reloaded.
       * Cache persists across page transitions.
       * 
       * Avoids multiple network calls on the same content.
       */
      if (Object.keys(state.cache).includes(purchase_options.cards.toString())) {
        state.data = state.cache[purchase_options.cards];
        // Override stored headers if new ones were provided
        state.data.header = purchase_options.header || state.data.header;
        state.data.wordmarks = purchase_options.wordmarks || state.data.wordmarks;
        state.data.subheader = purchase_options.subheader || state.data.subheader;
        return;
      }
      await parseProductCards(products, state, {}, purchase_options.exact);

      // On success, store data set in cache & override headers with passed in values, if any
      if (!state.error) {
        state.cache[purchase_options.cards.toString()] = state.data;
        state.data.header = purchase_options.header || state.data.header;
        state.data.wordmarks = purchase_options.wordmarks || state.data.wordmarks;
        state.data.subheader = purchase_options.subheader || state.data.subheader;
      }
    };

    /**
     * Close the Modal. Resets current active slide after 0.5s
     */
    const closeModal = () => {
      state.visible = false;
      modalOps.unregisterModal('product');
    };
    const clearModal = () => {
      state.error = null;
      state.data = null;
      setSlide.value(0);
    };
    __expose({
      openModal,
      closeModal
    });
    provide('callback', closeModal);
    return (_ctx, _cache) => {
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: state.visible,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => state.visible = $event),
        "append-to-body": "",
        center: "",
        fullscreen: "",
        "show-close": false,
        onClose: closeModal,
        onClosed: clearModal,
        class: "purchase-modal"
      }, {
        default: _withCtx(() => [state.loading ? (_openBlock(), _createBlock(_component_el_row, {
          key: 0,
          align: "middle",
          justify: "center",
          class: "fill-container"
        }, {
          default: _withCtx(() => [_createVNode(WaveSpinner)]),
          _: 1
        })) : !state.error && state.data?.cards && state.data.cards.length > 0 ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [state.data.header ? (_openBlock(), _createBlock(_component_el_row, {
          key: 0,
          justify: "center",
          class: "modal-title-container"
        }, {
          default: _withCtx(() => [_createElementVNode("h2", {
            class: "size-page-section-header modal-title",
            innerHTML: state.data.header
          }, null, 8, _hoisted_1)]),
          _: 1
        })) : _createCommentVNode("", true), state.data.wordmarks && state.data.wordmarks.length ? (_openBlock(), _createBlock(_component_el_row, {
          key: 1,
          justify: "center",
          align: "middle"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.data.wordmarks, (wordmark, index) => {
            return _openBlock(), _createBlock(ImageWithAlt, {
              key: index,
              src: wordmark,
              style: {
                "max-width": "800px",
                "padding": "20px",
                "box-sizing": "border-box"
              },
              fit: "contain"
            }, null, 8, ["src"]);
          }), 128))]),
          _: 1
        })) : _createCommentVNode("", true), state.data.subheader ? (_openBlock(), _createBlock(_component_el_row, {
          key: 2,
          justify: "center",
          class: "modal-subtitle-container std-padding_line-xl",
          innerHTML: state.data.subheader
        }, null, 8, ["innerHTML"])) : _createCommentVNode("", true), numSlides.value > 1 ? (_openBlock(), _createBlock(_component_el_row, {
          key: 3,
          align: "middle",
          justify: "center"
        }, {
          default: _withCtx(() => [_createVNode(CarouselIndicators, {
            setSlide: setSlide.value,
            number: numSlides.value,
            active: activeSlide.value
          }, null, 8, ["setSlide", "number", "active"])]),
          _: 1
        })) : _createCommentVNode("", true), _createVNode(_component_el_row, {
          class: "body"
        }, {
          default: _withCtx(() => [_createVNode(CarouselContainer, {
            ref_key: "carousel",
            ref: carousel,
            autoplay: false,
            disableLoop: ""
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slideContent.value, (cards, i) => {
              return _openBlock(), _createBlock(CarouselSlide, {
                key: `slide_${i}`
              }, {
                default: _withCtx(() => [_createVNode(_component_el_row, {
                  justify: "center",
                  class: "fill-container"
                }, {
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cards, (card, index) => {
                    return _openBlock(), _createBlock(_component_el_col, {
                      class: "flex-col flex-centered",
                      key: index,
                      style: {
                        "box-sizing": "border-box",
                        "padding": "20px"
                      },
                      span: 24 / cards.length
                    }, {
                      default: _withCtx(() => [_createVNode(ProductOptionCard, {
                        includes: card.included_in,
                        dealBanner: dealBanners.value[card.title],
                        productDetails: card,
                        reset: !state.visible
                      }, null, 8, ["includes", "dealBanner", "productDetails", "reset"])]),
                      _: 2
                    }, 1032, ["span"]);
                  }), 128))]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024);
            }), 128))]),
            _: 1
          }, 512), _createVNode(_TransitionGroup, {
            appear: "",
            name: "fade-in-place"
          }, {
            default: _withCtx(() => [_withDirectives(_createElementVNode("div", {
              class: "arrows",
              style: {
                "left": "15px"
              },
              onClick: _cache[0] || (_cache[0] = (...args) => carousel.value.prevSlide && carousel.value.prevSlide(...args)),
              key: "prev",
              "data-testid": "prevArrow"
            }, [_createVNode(_component_el_icon, {
              size: 30
            }, {
              default: _withCtx(() => [_createVNode(_unref(ArrowLeftBold))]),
              _: 1
            })], 512), [[_vShow, carousel.value && carousel.value.activeSlide > 0]]), _withDirectives(_createElementVNode("div", {
              class: "arrows",
              style: {
                "right": "15px"
              },
              onClick: _cache[1] || (_cache[1] = (...args) => carousel.value.nextSlide && carousel.value.nextSlide(...args)),
              key: "next",
              "data-testid": "nextArrow"
            }, [_createVNode(_component_el_icon, {
              size: 30
            }, {
              default: _withCtx(() => [_createVNode(_unref(ArrowRightBold))]),
              _: 1
            })], 512), [[_vShow, carousel.value && carousel.value.activeSlide < carousel.value.numSlides - 1]])]),
            _: 1
          })]),
          _: 1
        })], 64)) : (_openBlock(), _createBlock(_component_el_row, {
          key: 2,
          justify: "center",
          align: "middle",
          class: "fill-container"
        }, {
          default: _withCtx(() => [_createVNode(ErrorAlert, null, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode(" There was an error retrieving the product(s). Please try again later. "), _createElementVNode("br", null, null, -1), _createTextVNode(" We apologize for the inconvenience. ")])),
            _: 1
          })]),
          _: 1
        })), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
          icon: _unref(Close),
          type: "primary",
          class: "black-base",
          circle: "",
          onClick: closeModal
        }, null, 8, ["icon"])])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
};