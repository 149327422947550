import { renderSlot as _renderSlot, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue";
import { computed } from 'vue';
import { CloseBold } from '@element-plus/icons-vue';
export default {
  __name: 'Tag',
  props: {
    type: {
      type: String,
      default: '',
      validator(v) {
        return ["", "success", "info", "warning", "danger"].includes(v.toLowerCase());
      }
    },
    size: {
      type: String,
      default: "default",
      validator(v) {
        return ["small", "default", "large"].includes(v.toLowerCase());
      }
    },
    closable: {
      type: Boolean,
      default: false
    }
  },
  emits: ["close"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emits = __emit;
    const cssColorPalette = computed(() => {
      return {
        dark_2: `var(--el-color-${props.type.toLowerCase()}-dark-2)`,
        default: `var(--el-color-${props.type.toLowerCase()})`,
        light_3: `var(--el-color-${props.type.toLowerCase()}-light-3)`,
        light_5: `var(--el-color-${props.type.toLowerCase()}-light-5)`,
        light_7: `var(--el-color-${props.type.toLowerCase()}-light-7)`,
        light_8: `var(--el-color-${props.type.toLowerCase()}-light-8)`,
        light_9: `var(--el-color-${props.type.toLowerCase()}-light-9)`
      };
    });
    const fontSize = computed(() => {
      let value = "14px";
      switch (props.size) {
        case "small":
          value = "10px";
          break;
        case "large":
          value = "18px";
          break;
        default:
          value = "14px";
      }
      return value;
    });
    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");
      return _openBlock(), _createElementBlock("div", {
        class: "tag",
        style: _normalizeStyle({
          'font-size': fontSize.value,
          'border-color': cssColorPalette.value.light_8,
          'background-color': cssColorPalette.value.light_9,
          'color': cssColorPalette.value.default
        })
      }, [_renderSlot(_ctx.$slots, "default"), __props.closable ? (_openBlock(), _createBlock(_component_el_icon, {
        key: 0,
        class: "clickable",
        onClick: _cache[0] || (_cache[0] = () => emits('close'))
      }, {
        default: _withCtx(() => [_createVNode(_unref(CloseBold))]),
        _: 1
      })) : _createCommentVNode("", true)], 4);
    };
  }
};