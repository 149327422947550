import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "flex-column fullwidth flex-centered",
  "data-testid": "subRenewalDialog"
};
const _hoisted_2 = {
  key: 0,
  class: "flex-col fullwidth"
};
const _hoisted_3 = {
  class: "flex-row fullwdith",
  style: {
    "padding-bottom": "40px"
  }
};
const _hoisted_4 = {
  class: "flex-row fullwdith flex-row-start"
};
const _hoisted_5 = {
  class: "flex-col",
  style: {
    "width": "300px",
    "padding-right": "40px"
  }
};
const _hoisted_6 = {
  class: "flex-col",
  style: {
    "flex": "1"
  }
};
const _hoisted_7 = {
  class: "flex-row flex-row-start",
  style: {
    "padding-bottom": "10px"
  }
};
const _hoisted_8 = {
  style: {
    "margin": "0",
    "padding": "0"
  }
};
const _hoisted_9 = {
  key: 0,
  class: "flex-row flex-row-start",
  style: {
    "padding-bottom": "10px"
  }
};
const _hoisted_10 = {
  style: {
    "margin": "0",
    "padding": "0"
  }
};
const _hoisted_11 = {
  key: 1,
  class: "flex-row flex-row-start",
  style: {
    "padding-bottom": "10px"
  }
};
const _hoisted_12 = {
  style: {
    "margin": "0",
    "padding": "0"
  }
};
const _hoisted_13 = {
  key: 1,
  class: "flex-col fullwidth"
};
const _hoisted_14 = {
  key: 0,
  class: "flex-row fullwdith",
  style: {
    "padding-bottom": "20px"
  }
};
const _hoisted_15 = {
  class: "flex-row fullwidth flex-row-start"
};
const _hoisted_16 = {
  class: "flex-col"
};
const _hoisted_17 = {
  key: 0,
  class: "flex-row flex-row-start",
  style: {
    "padding-bottom": "10px"
  }
};
const _hoisted_18 = {
  style: {
    "margin": "0",
    "padding": "0"
  }
};
const _hoisted_19 = {
  class: "dialog-footer"
};
const _hoisted_20 = {
  class: "flex-row fullwidth flex-row-end"
};
import { getFsDataObj } from '../helpers';
import { ref, inject, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
export default {
  __name: 'ProductFreebieModal',
  setup(__props) {
    const store = useStore();
    const cart = inject('cart');
    const selectedFreebies = ref({});

    // Methods

    /**
     * Sets the default selected freebie for this group to 'selected'
     */
    const handleCheckboxChange = (value, group) => {
      selectedFreebies.value[group].selected = value;
    };

    /**
     * Handles radio button changes
     */
    const handleRadioBtnChange = (group, value) => {
      selectedFreebies.value[group].value = value;
    };

    /**
     * Closes the modal
     */
    const handleClose = () => {
      store.commit('setFreebieModalVisibilityState');
      if (selectedFreebies.value) {
        setTimeout(() => {
          selectedFreebies.value = {};
        }, 500);
      }
    };

    /**
     * Handles the confirm button click. Adds selected freebies to the cart and closes the modal.
     */
    const handleConfirmBtnClick = () => {
      filterSelectedFreebies.value.forEach(path => cart.addProduct({
        path,
        is_freebie: true
      }));
      handleClose();
    };

    /**
     * Sets default selection values
     */
    const setDefaultValues = () => {
      for (let k in groups.value) {
        selectedFreebies.value[k] = {
          value: groups.value[k].default_value,
          selected: true
        };
      }
    };

    // Computed

    /**
     * Returns a boolean indicating whether the modal should be visible
     * @returns {boolean}
     */
    const visible = computed(() => !!store.getters.getFreebieModalVisibilityState);

    /**
     * Returns an array of product freebies for the product about to be purchased, or null if non exist.
     * @returns {Array | null}
     */
    const productFreebies = computed(() => {
      if (visible.value) {
        return store.getters.getFreebieModalVisibilityState;
      }
      return null;
    });

    /**
     * Returns freebie groups
     */
    const groups = computed(() => {
      if (!productFreebies.value) {
        return null;
      }
      let ret = {};
      productFreebies.value.forEach(v => {
        ret[v.group] = ret[v.group] || {
          inputType: v.input_type
        };
        if (v.is_default_value) {
          ret[v.group].default_value = v.path;
        }
        const detailedFreebie = {
          ...v,
          ...getFsDataObj(store.getters.getFsData, v.path)
        };
        if (Array.isArray(ret[v.group].freebies)) {
          ret[v.group].freebies.push(detailedFreebie);
        } else {
          ret[v.group].freebies = [detailedFreebie];
        }
      });
      return ret;
    });
    const selectedFreebieData = computed(() => {
      let ret = {
        ...selectedFreebies.value
      };
      for (let k in selectedFreebies.value) {
        ret[k] = {
          ...productFreebies.value.find(v => v.path === selectedFreebies.value[k].value),
          ...getFsDataObj(store.getters.getFsData, selectedFreebies.value[k].value)
        };
      }
      return ret;
    });
    const filterSelectedFreebies = computed(() => {
      let ret = [];
      for (let k in selectedFreebies.value) {
        let val = selectedFreebies.value[k];
        const group = groups.value[k];
        if (group.inputType === 'checkbox') {
          ret.push(val.selected ? val.value : group.freebies.find(v => v.path !== val.value).path);
        } else {
          ret.push(val.value);
        }
      }
      return ret;
    });

    // Watchers

    watch(groups, () => {
      if (groups.value) {
        setDefaultValues();
      }
    });
    onMounted(() => {
      if (groups.value) {
        setDefaultValues();
      }
    });
    return (_ctx, _cache) => {
      const _component_el_radio_button = _resolveComponent("el-radio-button");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createBlock(_component_el_dialog, {
        "show-close": false,
        class: "antares-dialog",
        "model-value": visible.value,
        "before-close": handleClose,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "destroy-on-close": true,
        "lock-scroll": true,
        "append-to-body": true,
        modal: true,
        center: "",
        top: "20vh"
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_19, [_createElementVNode("div", _hoisted_20, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: handleConfirmBtnClick,
          "data-testid": "renewalConfirmButton"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode(" Confirm ")])),
          _: 1
        })])])]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_cache[2] || (_cache[2] = _createElementVNode("div", {
          class: "flex-row fullwidth",
          style: {
            "padding-bottom": "40px"
          }
        }, [_createElementVNode("h1", {
          style: {
            "padding": "0",
            "margin": "0"
          }
        }, "Choose your special offer")], -1)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groups.value, (v, k) => {
          return _openBlock(), _createElementBlock("div", {
            class: "flex-row fullwidth",
            key: `${k}_key`,
            style: {
              "padding-bottom": "40px"
            }
          }, [v.inputType === 'radio' && selectedFreebieData.value[k] ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_radio_group, {
            modelValue: selectedFreebies.value[k].value,
            "onUpdate:modelValue": $event => selectedFreebies.value[k].value = $event
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v.freebies, f => {
              return _openBlock(), _createBlock(_component_el_radio_button, {
                onChange: () => handleRadioBtnChange(k, f.path),
                label: f.path,
                key: `${f.path}_key`
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(f.name), 1)]),
                _: 2
              }, 1032, ["onChange", "label"]);
            }), 128))]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_image, {
            src: selectedFreebieData.value[k].thumbnail,
            fit: "contain",
            alt: "atu_stacked_gui",
            style: {
              "width": "100%",
              "padding-right": "40px"
            }
          }, {
            placeholder: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("div", {
              class: "image-slot"
            }, [_createTextVNode(" Loading"), _createElementVNode("span", {
              class: "dot"
            }, "...")], -1)])),
            error: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("div", {
              class: "image-slot"
            }, [_createElementVNode("i", {
              class: "el-icon-picture-outline"
            })], -1)])),
            _: 2
          }, 1032, ["src"])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("h3", _hoisted_8, _toDisplayString(selectedFreebieData.value[k].title), 1)]), selectedFreebieData.value[k].subscription_line_item_addon ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("p", _hoisted_10, _toDisplayString(selectedFreebieData.value[k].subscription_line_item_addon), 1)])) : _createCommentVNode("", true), selectedFreebieData.value[k].text ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("p", _hoisted_12, _toDisplayString(selectedFreebieData.value[k].text), 1)])) : _createCommentVNode("", true)])])])) : v.inputType === 'checkbox' && selectedFreebieData.value[k] ? (_openBlock(), _createElementBlock("div", _hoisted_13, [v.freebies.length === 2 && v.default_value && selectedFreebies.value[k] ? (_openBlock(), _createElementBlock("div", _hoisted_14, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v.freebies.filter(n => n.path === v.default_value), f => {
            return _openBlock(), _createBlock(_component_el_checkbox, {
              key: `${f.path}_key`,
              label: v.freebies.find(n => n.path === v.default_value).title,
              checked: selectedFreebies.value[k].selected,
              onChange: e => handleCheckboxChange(e, k, f),
              size: "small",
              "data-testid": "autoRenewCheckbox"
            }, null, 8, ["label", "checked", "onChange"]);
          }), 128))])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [selectedFreebieData.value[k].text ? (_openBlock(), _createElementBlock("div", _hoisted_17, [_createElementVNode("p", _hoisted_18, _toDisplayString(selectedFreebieData.value[k].text), 1)])) : _createCommentVNode("", true)])])])) : _createCommentVNode("", true)]);
        }), 128))])]),
        _: 1
      }, 8, ["model-value"]);
    };
  }
};