import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import { useCssVars as _useCssVars } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition } from "vue";
const _hoisted_1 = {
  notranslate: ""
};
const _hoisted_2 = {
  notranslate: ""
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  notranslate: ""
};
import { ref, computed, inject, watch, onMounted } from 'vue';
import { currentBP } from '@/composables/windowWatcher';
import { ArrowRightBold } from '@element-plus/icons-vue';
import { updateCurrentRoute } from '@/helpers/language';
export default {
  __name: 'NavMenuLang',
  props: {
    alwaysDropdown: Boolean,
    colorOverride: String
  },
  setup(__props) {
    _useCssVars(_ctx => ({
      "191f4b96": __props.colorOverride
    }));
    const localize = inject('localize');

    // Allow previous languages to remain sorted as their old implementation
    const sortOrder = ["en", "fr", "es", "de", "pt", "ja", "ko", "it"];
    const enabledLangs = ref(null);

    // Computed

    const activeLang = computed({
      get() {
        return localize.currCode.value;
      },
      set(value) {
        if (localize.hasLoaded.value) {
          updateCurrentRoute(value);
        }
      }
    });
    const Language = computed(() => {
      return enabledLangs.value?.find(entry => entry.code === activeLang.value)?.name;
    });

    // Methods

    /**
     * Change the page's language via Localize
     * 
     * @param code - string in the form of a Localize accepted langCode (ISO 639-1)
     */
    const handleLang = code => {
      activeLang.value = code;
    };
    const initialize = () => {
      if (localize.hasLoaded.value && !enabledLangs.value) {
        localize.getAvailableLanguages((err, langs) => {
          if (err) {
            console.warn("Unable to retrieve available languages", err);
            return;
          }
          enabledLangs.value = langs;
          enabledLangs.value.map(lang => {
            const words = lang.name.split(" ");
            if (words.length > 1) {
              // Possibly Depreciated; Convert country specific languages to <ISO-639-1>-<ISO 3166> format
              const ISO_639_1 = words[0].slice(0, 2).toUpperCase();
              const ISO_3166 = words[1].replace(/[()]/g, "").slice(0, 2).toUpperCase();
              lang.name = `${ISO_639_1}-${ISO_3166}`;
            } else if (lang.name.length > 3) {
              // Convert language name to 3 capitalized characters
              lang.name = lang.name.slice(0, 3).toUpperCase();
            }
          });

          /**
           * Attempt to sort languages as per previous format.
           * New languages will sink to bottom of the list unless added to sortOrder array above.
           */
          enabledLangs.value = enabledLangs.value.sort((a, b) => {
            const aInd = sortOrder.indexOf(a.code);
            const bInd = sortOrder.indexOf(b.code);
            if (aInd < 0) return 1;
            if (bInd < 0) return -1;
            return aInd - bInd;
          });
        });
      }
    };

    // Hooks & Watchers

    // Attempt to populate Enabled Languages on mounted
    onMounted(initialize);

    // Set up watcher if Localize has yet to fully load
    if (!localize.hasLoaded.value) {
      const disableWatcher = watch(() => localize.hasLoaded.value, (newVal, oldVal) => {
        if (!oldVal && newVal !== oldVal) {
          initialize();
          disableWatcher();
        }
      });
    }
    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
      const _component_el_dropdown = _resolveComponent("el-dropdown");
      const _component_el_container = _resolveComponent("el-container");
      return _openBlock(), _createBlock(_Transition, {
        name: "fade-in-place"
      }, {
        default: _withCtx(() => [enabledLangs.value && activeLang.value ? (_openBlock(), _createBlock(_component_el_container, {
          key: 0
        }, {
          default: _withCtx(() => [__props.alwaysDropdown || ['xs'].includes(_unref(currentBP)) ? (_openBlock(), _createBlock(_component_el_dropdown, {
            key: 0,
            onCommand: handleLang
          }, {
            dropdown: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(enabledLangs.value, lang => {
              return _openBlock(), _createElementBlock(_Fragment, {
                key: lang.code
              }, [activeLang.value !== lang.code ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                key: 0,
                command: lang.code
              }, {
                default: _withCtx(() => [_createElementVNode("h5", _hoisted_2, _toDisplayString(lang.name), 1)]),
                _: 2
              }, 1032, ["command"])) : _createCommentVNode("", true)], 64);
            }), 128))]),
            default: _withCtx(() => [_createVNode(_component_el_row, {
              align: "middle",
              justify: "center",
              class: "nav-overlay-lang"
            }, {
              default: _withCtx(() => [_createElementVNode("h5", _hoisted_1, _toDisplayString(Language.value), 1), _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [_createVNode(_unref(ArrowRightBold))]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })) : (_openBlock(true), _createElementBlock(_Fragment, {
            key: 1
          }, _renderList(enabledLangs.value, lang => {
            return _openBlock(), _createElementBlock("div", {
              key: lang.code,
              class: _normalizeClass(["nav-overlay-lang", {
                'active': activeLang.value === lang.code
              }]),
              onClick: () => handleLang(lang.code)
            }, [_createElementVNode("h5", _hoisted_4, _toDisplayString(lang.name), 1)], 10, _hoisted_3);
          }), 128))]),
          _: 1
        })) : _createCommentVNode("", true)]),
        _: 1
      });
    };
  }
};