import "core-js/modules/es.array.push.js";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "el-dropdown-link"
};
import { inject, computed } from 'vue';
import { useRouter } from 'vue-router';
import { UserFilled } from '@element-plus/icons-vue';
export default {
  __name: 'UserMenu',
  setup(__props) {
    const user = inject('user');
    const router = useRouter();

    /**
     * Handles the dropdown selection.
     * @param {number} cmd
     */
    const handleCommand = cmd => {
      switch (cmd) {
        case 1:
          router.push({
            name: 'Overview'
          });
          break;
        case 2:
          router.push({
            name: 'Profile'
          });
          break;
        case 3:
          router.push({
            name: 'Orders'
          });
          break;
        case 4:
          router.push({
            name: 'UserUpgrades'
          });
          break;
        // case 5:
        //     router.push({ name: 'Registration' });
        //     break;
        case 5:
          user.logout();
      }
    };

    /**
     * Returns a boolean indicating whether a red attention badge should be shown by the user's name/icon.
     * @returns {boolean}
     */
    const showBadge = computed(() => {
      if (!user?.summary) {
        return false;
      }
      return user.summary.available_upgrades && user.summary.available_upgrades >= 1;
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_badge = _resolveComponent("el-badge");
      const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
      const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
      const _component_el_dropdown = _resolveComponent("el-dropdown");
      return _openBlock(), _createBlock(_component_el_dropdown, {
        trigger: "click",
        onCommand: handleCommand,
        style: {
          "cursor": "pointer"
        },
        disabled: _unref(user).state.logoutLoading
      }, {
        dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
            command: 1
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("My Account")])),
            _: 1
          }), _createVNode(_component_el_dropdown_item, {
            command: 2
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("Account Settings")])),
            _: 1
          }), _createVNode(_component_el_dropdown_item, {
            command: 3
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("Order History")])),
            _: 1
          }), _unref(user).hasAvailableUpgrades ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
            key: 0,
            command: 4
          }, {
            default: _withCtx(() => [_createVNode(_component_el_badge, {
              value: _unref(user).summary.available_upgrades,
              class: "upgrades-badge"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode(" Available Offers ")])),
              _: 1
            }, 8, ["value"])]),
            _: 1
          })) : _createCommentVNode("", true), _createVNode(_component_el_dropdown_item, {
            command: 5
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("Logout")])),
            _: 1
          })]),
          _: 1
        })]),
        default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [showBadge.value ? (_openBlock(), _createBlock(_component_el_badge, {
          key: 0,
          type: "primary",
          "is-dot": "",
          offset: [-5, 5]
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            class: "gray-base",
            size: "large",
            type: "primary",
            plain: "",
            icon: _unref(UserFilled),
            circle: "",
            disabled: _unref(user).state.logoutLoading,
            "data-testid": "userDropdownButton"
          }, null, 8, ["icon", "disabled"])]),
          _: 1
        })) : (_openBlock(), _createBlock(_component_el_button, {
          key: 1,
          class: "gray-base",
          size: "large",
          type: "primary",
          plain: "",
          icon: _unref(UserFilled),
          circle: "",
          disabled: _unref(user).state.logoutLoading,
          "data-testid": "userDropdownButton"
        }, null, 8, ["icon", "disabled"]))])]),
        _: 1
      }, 8, ["disabled"]);
    };
  }
};