import { unref } from 'vue';
import router from '@/router';
import store from '@/store';
import { getTranslation } from "./language.js";
import langs from '@/langs.json';
import { useFsLoading } from '@/classes/FastSpring.js';
import FastSpring from '@/classes/FastSpring.js';
import Product from '@/classes/Product.js';
import { captureException } from '@sentry/vue';

const fsLoading = useFsLoading();

export function validateEmail (email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && !email.endsWith('@example.com');
}

export function validatePw (pw) {
    return pw.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/);
}

export function validatePhoneNumber (phoneNumber) {
    return phoneNumber.match(/^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g);
}

export function formatStringWithPrice (str, price) {
    if (str) {
        return str.replace('<<price>>', price);
    }

    return str;
}

/**
 * Parse video file extension to create associated mime type
 * @param {string} url 
 * @returns {string}
 */
export function parseVideoFormat (url) {
    const splitUrl = url.split('.');
    return `video/${splitUrl[splitUrl.length - 1]}`;
}

/**
 * Formats a number into currency format based on user's browser locale
 * @param {number} amt 
 * @returns {string}
 */
export function formatToCurrency (amt) {
    if (typeof amt === 'string') {
        amt = parseFloat(amt);
    }

    let locale = null;

    if (navigator.languages && Array.isArray(navigator.languages) && navigator.languages.length) {
        locale = navigator.languages[0];
    } else {
        locale = navigator.language;
    }

    const currency = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: store.getters.getCurrencyCode,
        minimumFractionDigits: 2
    });

    let currencyString = currency ? currency.format(amt) : `$${amt.toFixed(2)}`

    // Quick fix for USD; TODO: remove trailing currency for other currency types, if desired
    if (store.getters.getCurrencyCode === "USD") {
        currencyString = currencyString.replace(".00", "");
    }

    return currencyString;
}

/**
 * Formats a Subscription Product name from a fsDataObject to its core Product Name
 * @param {string} productName 
 * @returns {string}
 */
export function formatSubName (productName) {
    if( !productName) {
        return null;
    }

    return productName.split(' ').filter((item) => item !== 'Monthly' && item !== 'Annual').join(' ');
}

/**
 * Formats a WP provided date & converts into a date that can be displayed.
 * @param {string} postDate
 * @param {object} format Date toString format object, default: { month : 'long', day:'numeric', year:'numeric' }
 * @returns {string}
 */
export function formatPostDate (postDate, format = { month : 'long', day:'numeric', year:'numeric' }) {
    if( !postDate || typeof postDate !== 'string') {
        return null;
    }

    let date = postDate.split(' ')[0].split('-');
    date = `${date[1]}/${date[2]}/${date[0]}`
    return (new Date(date)).toLocaleDateString(undefined , format);
}

/**
 * Adds n months to a given date
 * Credit: Ivan https://stackoverflow.com/a/2706169/5721732
 * @param {Date} date 
 * @param {number} months 
 * @returns 
 */
export function addMonths (date, months) {
    let d = date.getDate();

    date.setMonth(date.getMonth() + +months);
    
    if (date.getDate() != d) {
        date.setDate(0);
    }

    return date;
}

/**
 * Simple wait function
 * @param {number} time 
 * @returns {Promise}
 */
export function wait (time) {
    return new Promise(resolve => setTimeout(resolve, time));   
}

/**
 * Polls an endpoint
 * @param {function} request 
 * @param {number} limit 
 */
export async function poll ({ request, limit = null, interval = 0, cb = null, endCondition = null }) {
    try {
        const resp = await request();

        if (resp && endCondition(resp)) {
            cb(resp);
            return;
        }
    
        if (limit && (interval >= limit)) {
            return;
        }
    
        await wait(process.env.VUE_APP_SERVER === 'dev' ? 2000 : 10000);
        poll({ request, limit, interval: ++interval, cb, endCondition });
    } catch (e) {
        cb(e);
    }
}

/** 
 * Searches through the fsData (store_json) array for an object whose path matches the "path" argument
 * and returns it.
 * @param {Array} fsData 
 * @param {string} path 
 * @returns {object}
 */
export function getFsDataObj (fsData, path) {
    if (!(path && typeof path === "string")) return null;

    return Object.values(fsData).find(v => v.path === path);
}

/**
 * Takes a number date and returns a formatted ordinal date
 * @param {String} date
 * @returns {object}
 */
export function nth(date) {
    let postfix = "th";
    
    let dateNum = parseInt(date);
    if (dateNum <= 3 || dateNum >= 21)
    {
        switch (dateNum % 10) {
            case 1:  
                postfix = "st";
                break;
            case 2:  
                postfix = "nd";
                break;
            case 3:  
                postfix = "rd";
                break;
        }
    }

    return date + postfix;
}

/**
 * Capitalizes the first letter of the provided string argument
 * @param {String} string 
 * @returns 
 */
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
/**
 * Throttles a callback
 * @param {function} fn 
 * @param {number} wait 
 * @returns {function}
 */
export function throttle(fn, wait) {
    var time = Date.now();
    
    return function(e) {
        if ((time + wait - Date.now()) < 0) {
            fn(e);
            time = Date.now();
        }
    }
}

export function throttleFunction (cb, limit) {
    clearTimeout(window.throttleWait);

    window.throttleWait = setTimeout(() => cb(), limit);
}

/**
 * Starts the download for the clicked product
 * @param {string} path
 * @param {string} name
 */
 export function handleDownload (path, name = null) {
    const link = document.createElement('a');
    link.href = path;

    if (name) {
        link.download = name;
    }

    link.click();
    URL.revokeObjectURL(link.href);
    link.remove();
}

/**
 * Handles setting SEO meta tags from an incoming CMS response.
 * @param {object} resp 
 */
export async function handleAsyncSeoMetaTags (resp) {
    const { currentRoute } = router;
    let route = currentRoute.value || currentRoute;

    if (route.meta?.fromCms) {
        let meta = null;
        let ogMeta = {};

        const fillOgMeta = (data) => {
            let seo_image = null;

            if (data?.photo_og_seo && Array.isArray(data.photo_og_seo) && data.photo_og_seo.length) {
                seo_image = data.photo_og_seo[0];
            } else if (data?.photo_m && Array.isArray(data.photo_m) && data.photo_m.length) {
                seo_image = data.photo_m[0];
            } else {
                seo_image = "https://antares.sfo2.cdn.digitaloceanspaces.com/imgs/AutoTune-Meta-Tag-1200x600.jpg";
            }

            return {
                "seo_meta_og_title": data?.post_title || data?.meta?.seo_meta_title || "Auto-Tune - The Best Vocal Plug-Ins Available",
                "seo_meta_og_description": data?.post_excerpt || data?.meta?.seo_meta_description || "Learn more about Auto-Tune, the music industry standard for pitch correction and vocal effects. Shop and learn about the best plug-ins for pitch correction, vocal effects, voice processing, and noise reduction. Auto-Tune Pro, Auto-Tune Artist, Auto-Tune EFX+, Auto-Tune Access, Harmony Engine, Mic Mod and more.",
                "seo_meta_og_image": seo_image
            }
        }
        
        if (Array.isArray(resp.data) && resp.data.length === 1) {
            meta = resp.data[0].meta;
            ogMeta = fillOgMeta(resp.data[0]);
        } else if (Array.isArray(resp.data) && resp.data.length > 1) {
            // match slug if possible
            const localSlug = currentRoute.value.params?.slug;
            const match = resp.data.find(v => v.slug && v.slug.split('__')[1] === localSlug);
            meta = match?.meta || null;

            if (match) ogMeta = fillOgMeta(match);
        }

        if (meta?.seo_meta_title || meta?.seo_meta_description) {
            const { seo_meta_title, seo_meta_description } = meta;
            setSeoMetaTags({ seo_meta_title, seo_meta_description, ...ogMeta });
        }
    }
}

/**
 * Sets the page title and meta description values
 * @param {Array | string} seo_meta_title
 * @param {Array | string} seo_meta_description 
 */
export function setSeoMetaTags({ seo_meta_title, seo_meta_description, seo_meta_og_title, seo_meta_og_description, seo_meta_og_image }) {    
    if (Array.isArray(seo_meta_title)) {
        document.title = seo_meta_title[0];
    } else if (typeof seo_meta_title === 'string') {
        document.title = seo_meta_title;
    }

    if (Array.isArray(seo_meta_description)) {
        document.querySelector('meta[name="description"]').setAttribute("content", seo_meta_description[0]);
    } else if (typeof seo_meta_description === 'string') {
        document.querySelector('meta[name="description"]').setAttribute("content", seo_meta_description);
    }

    if (Array.isArray(seo_meta_og_title)) {
        document.querySelector('meta[property="og:title"]').setAttribute("content", seo_meta_og_title[0]);
    } else if (typeof seo_meta_og_title === 'string') {
        document.querySelector('meta[property="og:title"]').setAttribute("content", seo_meta_og_title);
    }

    if (Array.isArray(seo_meta_og_description)) {
        document.querySelector('meta[property="og:description"]').setAttribute("content", seo_meta_og_description[0]);
    } else if (typeof seo_meta_og_description === 'string') {
        document.querySelector('meta[property="og:description"]').setAttribute("content", seo_meta_og_description);
    }

    if (Array.isArray(seo_meta_og_image)) {
        document.querySelector('meta[property="og:image"]').setAttribute("content", seo_meta_og_image[0]);
    } else if (typeof seo_meta_og_image === 'string') {
        document.querySelector('meta[property="og:image"]').setAttribute("content", seo_meta_og_image);
    }

    const baseUrl = process.env.VUE_APP_SERVER === "dev" ? "https://www.antarestech.com" : (process.env.VUE_APP_DOMAIN || 'https://www.antarestech.com');

    let url = baseUrl + (router.currentRoute.value?.path !== "/" ? router.currentRoute.value?.path : "");

    document.querySelector('meta[property="og:url"]')?.setAttribute("content", url);
}

/**
 * Sets the SEO product schema for a given product page.
 * 
 * Product: https://schema.org/Product
 * 
 * Offer: https://schema.org/Offer - Single Product Offer (Perpetuals, AT Essentials, Trials)
 * 
 * AggregateOffer: https://schema.org/AggregateOffer - Multiple Product Offerings (i.e. Unlimited & Producer)
 * 
 * Example used by Rank: https://dantaylor.online/blog/schema-for-saas-subscription-products/
 * 
 * @param {object} product
 * @param {RouteLocationRaw} route - optional route object to use; defaults to the current route
 */
export function setSeoProductSchema(
    product,
    route = unref(router.currentRoute)
) {
    const ProductNotFoundError = "No valid product data found for SEO Product Schema";
    removeSeoProductSchema();
    const currencyCode = store.getters.getCurrencyCode;
    
    if (!(product?.meta?.hero_data)) {
        captureException(new Error(ProductNotFoundError));
        return;
    }

    const products = [];
    const { hero_data } = product.meta;
    const parsedHeroData = JSON.parse(hero_data[0]);
    const { product_offerings } = parsedHeroData;
    const { header, summary, gui } = parsedHeroData;

    if (product_offerings.find(offer => offer.type === "app")) {
        // Skip Mobile Apps
        return;
    }

    let targetOffer = null;

    if (product_offerings.length === 1) {
        targetOffer = product_offerings[0].offerings;
    } else {
        targetOffer = product_offerings.find(offer => offer.type === "perpetual")?.offerings;
    }

    if (!targetOffer) {
        captureException(new Error(ProductNotFoundError));
        return;
    }

    targetOffer.forEach((offer) => {
        if (!offer.path.includes("-14d-free")) products.push(offer.path);
    });

    if (!products.length) {
        captureException(new Error(ProductNotFoundError));
        return;
    }

    // Retrieve fsData for product(s); filter out empty values & sort by price
    const productObjects = products.map(product => {
        try {
            return new Product(product);
        } catch (e) {
            return null;
        }
    }).filter(product => {
        return !!product;
    }).sort((a, b) => {
        return a.productFinalPrice - b.productFinalPrice
    });

    if (!productObjects.length) {
        captureException(new Error(ProductNotFoundError));
        return;
    }

    // Core offer definition
    const offers = {
        "offeredBy" : {
            "@type" : "Organization",
            "name" : "Antares Audio Technologies"
        },
        "url" : process.env.VUE_APP_DOMAIN + (route?.path || productObjects[0].url),
        "availability" : "https://schema.org/InStock",
        "priceCurrency": currencyCode,
        "itemCondition" : "https://schema.org/NewCondition"
    };

    // // Determine type of offer
    if (productObjects.length > 1) {
        // Multiple Products listed; create an Aggregate offer
        offers["@type"] = "AggregateOffer";
        offers["lowPrice"] = productObjects[0].productFinalPrice;
        offers["highPrice"] = productObjects[productObjects.length - 1].productFinalPrice;
        offers["offerCount"] = productObjects.length;
        offers["priceSpecification"] = [];

        productObjects.forEach(product => {
            const offer = {
                "@type": "UnitPriceSpecification",
                "price": product.productFinalPrice,
                "priceCurrency": currencyCode,
                "name": product.name,
            };

            if (["subscription", "trial"].includes(product.type)) {
                // Reference Quantity needed for Subscriptions & Trials; designates interval
                offer["referenceQuantity"] = {
                    "@type" : "QuantitativeValue",
                    "value" : "1",
                    "unitCode": product.isAnnualSubscription ? "ANN" : "MON"
                }
            }

            offers["priceSpecification"].push(offer);
        });
    } else {
        // Single product listed; create Standard offer
        offers["@type"] = "Offer";
        offers["price"] = productObjects[0].productFinalPrice || '';
    }

    // Build final schema
    const schema = {
        "@context": "https://schema.org/", 
        "@type": "Product",
        "name": header || '',
        "image": gui || '',
        "description": summary || '',
        "brand": {
            "@type": "Brand",
            "name": "AUTO-TUNE"
        },
        offers
    };

    var script = document.createElement('script');
    script.type ='application/ld+json';
    script.setAttribute('id', 'seo-product-schema');
    script.text = JSON.stringify(schema, null, 2);

    document.head.appendChild(script);
}

/**
 * Removes the SEO product schema.
 */
export function removeSeoProductSchema() {
    var scriptEl = document.getElementById('seo-product-schema');

    if (scriptEl) {
        scriptEl.remove();
    }
}

export function resetSeo () {
    setSeoMetaTags ({
        seo_meta_title: "Auto-Tune - The Best Vocal Plug-Ins Available",
        seo_meta_description: "Learn more about Auto-Tune, the music industry standard for pitch correction and vocal effects. Shop and learn about the best plug-ins for pitch correction, vocal effects, voice processing, and noise reduction. Auto-Tune Pro, Auto-Tune Artist, Auto-Tune EFX+, Auto-Tune Access, Harmony Engine, Mic Mod and more.",
        seo_meta_og_title: "Auto-Tune - The Best Vocal Plug-Ins Available",
        seo_meta_og_description: "Learn more about Auto-Tune, the music industry standard for pitch correction and vocal effects. Shop and learn about the best plug-ins for pitch correction, vocal effects, voice processing, and noise reduction. Auto-Tune Pro, Auto-Tune Artist, Auto-Tune EFX+, Auto-Tune Access, Harmony Engine, Mic Mod and more.",
        seo_meta_og_image: "https://antares.sfo2.cdn.digitaloceanspaces.com/imgs/AutoTune-Meta-Tag-1200x600.jpg"
    });
}

/**
 * Wraps one DOM element around another
 * @param {object} el 
 * @param {object} wrapper 
 */
export function wrap (el, wrapper) {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
}

/**
 * Sets the application cache key based on the script tag's version number.
 */
export async function setCacheKey () {
    if (process.env.VUE_APP_SERVER === 'dev') {
        const response = await fetch('/testCacheKey.json?' + Date.now()); 
        const data = await response.json();
        store.commit('setCacheKey', data.testCacheKey);
        
        return;
    }

    let scripts = document.getElementsByTagName('script');
    let appScript = [...scripts].find(v => v.getAttribute('src') !== null && v.getAttribute('src').includes('app'));

    if (!appScript) {
        throw new Error('Cannot find app script');
    }

    const key = appScript.getAttribute('src').split('.')[1];

    if (typeof key !== 'string') {
        throw new Error('Cache key value is not of type string');
    }

    store.commit('setCacheKey', key);
}

/**
 * Checks the incoming cachebuster version number from the headers against the number stored in existing.
 * If the numbers differ, a full-page reload is triggered.
 * @param {object} resp 
 */
export function checkCacheKey (resp) {
    const incoming = resp.headers && resp.headers['x-wp-jscachekey'] ? resp.headers['x-wp-jscachekey'] : null;
    const incomingFsVersion = resp.headers && resp.headers['x-wp-fsversion'] ? resp.headers['x-wp-fsversion'] : null;

    if (process.env.VUE_APP_SERVER === 'dev' || !(incoming || incomingFsVersion)) {
        return;
    }
    
    const existing = store.getters.getCacheKey;
    const existingFsVersion = store.getters.getFsVersion;

    const cacheKeyDiff = incoming && incoming !== existing;

    // Avoid reloading if FS data is still being retrieved
    const fsVersionDiff = !fsLoading.value && incomingFsVersion && incomingFsVersion !== existingFsVersion;

    throttleCachebustingReloads(cacheKeyDiff || fsVersionDiff);
}

/**
 * Throttles cachebusting reloads
 * @param {boolean} shouldReload 
 */
export function throttleCachebustingReloads (shouldReload, postReloadData = null) {
    const { count, last } = store.getters.getCachebustingReloadCount;
    const timeout = process.env.VUE_APP_SERVER === 'dev' ? 10000 : 600000;

    if ((Date.now() - last) >= timeout) {
        store.commit('setCachebustingReloadCount', {});
    }

    if (shouldReload && count < 1) {
        store.commit('setCachebustingReloadCount', { count: (count + 1), last: Date.now() });

        if (postReloadData) {
            store.commit('setPostCachebustingReloadData', postReloadData);
        }

        location.reload();
    } else if (!shouldReload) {
        store.commit('setCachebustingReloadCount', {});
    }
}

/**
 * Detects bots from user agent headers
 */
export function isBot () {
    const robots = new RegExp([
        /bot/,/spider/,/crawl/,                            // GENERAL TERMS
        /APIs-Google/,/AdsBot/,/Googlebot/,                // GOOGLE ROBOTS
        /mediapartners/,/Google Favicon/,
        /FeedFetcher/,/Google-Read-Aloud/,
        /DuplexWeb-Google/,/googleweblight/,
        /bing/,/yandex/,/baidu/,/duckduck/,/yahoo/,        // OTHER ENGINES
        /ecosia/,/ia_archiver/,
        /facebook/,/instagram/,/pinterest/,/reddit/,       // SOCIAL MEDIA
        /slack/,/twitter/,/whatsapp/,/youtube/,
        /semrush/,                                         // OTHER
    ].map((r) => r.source).join("|"),"i");

    return [
        navigator.webdriver,
        window.callPhantom,
        window._phantom,
        window.phantom,
        window.__nightmare,
        document.__selenium_unwrapped,
        document.__webdriver_evaluate,
        document.__driver_evaluate,
        robots.test(navigator.userAgent)
    ].some(v => v);
}

/**
 * Writes the canonical URL in a link tag and appends it to the document head. This tells search engines
 * which URL is the canonical URL (https://www.antarestech.com and not https://www.antarestech.com/).
 * See https://www.semrush.com/blog/canonical-url-guide for more information.
 * 
 * @param {string} path
 */
export function setCanonicalUrl (path) {
    let canonicalPath = path;

    if (canonicalPath.endsWith('/')) {
        canonicalPath = canonicalPath.slice(0, -1);
    }

    const existingTags = document.querySelectorAll('[rel="canonical"]');
    [...existingTags].forEach(t => t.remove());

    const tag = document.createElement('link');
    tag.setAttribute('rel', 'canonical');
    tag.setAttribute('href', (process.env.VUE_APP_DOMAIN || 'https://www.antarestech.com') + canonicalPath);
    document.head.appendChild(tag);
}

/**
 * Writes the alternative Hreflang URLs in link tags and appends them to the document head. This tells search engines
 * about the alternative URLs for the different languages we natively support. These Hreflang tags must include a self reference to the current cannonical URL as well.
 * See https://www.semrush.com/blog/hreflang-attribute-101/ for more information.
 */
export function setHrefLangTags () {
    const existingTags = document.querySelectorAll('[hreflang]');
    [...existingTags].forEach(t => t.remove());

    const writeHrefLang = (path, lang) => {
        let langPath = path;

        if (langPath.endsWith('/')) {
            langPath = langPath.slice(0, -1);
        }

        const tag = document.createElement('link');

        tag.setAttribute('rel', 'alternative');
        tag.setAttribute('href', (process.env.VUE_APP_DOMAIN || 'https://www.antarestech.com') + langPath);
        tag.setAttribute('hreflang', lang);

        document.head.appendChild(tag);
    }
    
    const route = router.currentRoute.value;

    if (!route) {
        console.error("Unable to resolve the current route");
        return;
    }

    if (!route.name) {
        return;
    }

    if (route.name === "PageNotFound") {
        return;
    }

    const rootName = route.name.split("-lang_")[0];
    
    // Gather data necessary to translate Slug for each route
    const enSlug = route.params?.slug ? getTranslation(route.params?.slug, "en", route.meta?.lang) : "";

    langs.forEach( lang => {
        let name = rootName;
        let params = { ...route.params };
        params.slug = params.slug || "";

        if (lang !== 'en') {
            name += `-lang_${lang}`;
        }

        if (name === route.name) {
            const slugs = route.meta?.sitemap?.slugs;

            if (slugs?.length && !slugs?.includes(params.slug)) {
                return;
            }

            writeHrefLang(route.path, lang);
            return;
        }

        params.slug = getTranslation(enSlug, lang, route.meta?.lang);

        // Resolve language specific path and record the Hreflang
        let altPath = null;

        try {
            const altRoute = router.resolve({ name, params });

            if (!altRoute) {
                return;
            }

            const slugs = altRoute.meta?.sitemap?.slugs;

            if (slugs?.length && !slugs?.includes(params.slug)) {
                return;
            }

            altPath = altRoute.path;
        } catch (e) {
            console.error(e);
            return;
        }

        if (!altPath) {
            console.error(`Encountered an issue resolving "${name}"`);
            return;
        }

        writeHrefLang(altPath, lang);
    });
}

/**
 * Copies text to the clipboard
 * @param {string} text 
 */
export function copyToClipboard (text, cb = null) {
    navigator.clipboard.writeText(text);

    if (typeof cb === 'function') {
        cb();
    }
}

export function PageNotFoundRedirect () {
    const { currentRoute } = router;
    const route = unref(currentRoute);
    const { query, hash } = route;

    const pathMatch = route.path.split("/").filter((part) => part);

    const destination = {
        name: "PageNotFound",
        params: {
            pathMatch
        },
        query,
        hash
    }

    router.replace(destination);
}

/**
 * Initializes Convert stuff
 */
export function initializeConvert () {
    window._conv_q = window._conv_q || [];
    window._conv_q.push(["run","true"]);
}

/**
 * Updates Convert experiment stuff
 */
export function updateConvert () {
    if (typeof(window.convert) != 'undefined') {
        window._conv_q = window._conv_q || [];
        window._conv_q.push(['recheck_goals']);

        const exp = window.convert.currentData.experiments;

        for (let expID in exp) {
          window._conv_q.push(["executeExperiment", expID]);
        }
    }
}

/**
 * Call a function on the FastSpring class that returns the user's current IP address
 * from the CMS server, then verifies it against the cached IP address.
 */
export async function checkCurrentIp () {
    try {
        const resp = await FastSpring.fetchCurrentClientIp();

        if (resp.status !== 200) {
            throw new Error('Unable to retrieve client ip: ' + resp.status);
        }

        verifyCachedIpAgainstCurrentIp(resp.data);
    } catch (e) {
        console.warn(e);
    } 
}

/**
 * Verifies the current IP against the cached IP. If they differ, triggers a *throttled* page reload.
 * @param {string} currentIp 
 */
export function verifyCachedIpAgainstCurrentIp (currentIp) {
    const cachedIp = store.getters.getCachedIp;
    store.commit('setCachedIp', currentIp);

    throttleCachebustingReloads(cachedIp && currentIp !== cachedIp);
}